import React from 'react';
import { Form, Input, Button, message } from 'antd';
import styles from './ContactForm.module.scss';
import { USER_LANDING } from '../../constants/texts';

const validationRules = language => {
  return {
    name: [
      {
        required: true,
        message: USER_LANDING.CONTACT_FORM.ERROR_NAME[language],
      },
    ],
    email: [
      {
        required: true,
        message: USER_LANDING.CONTACT_FORM.ERROR_EMPTY_EMAIL[language],
      },
      {
        type: 'email',
        message: USER_LANDING.CONTACT_FORM.ERROR_WRONG_EMAIL[language],
      },
    ],
    phone: [
      {
        required: true,
        min: 10,
        max: 13,
        message: USER_LANDING.CONTACT_FORM.ERROR_PHONE[language],
      },
    ],
  };
};

export default function ContactForm(props) {
  const [form] = Form.useForm();
  const { color, language, fontColor } = props;

  const onFinish = async values => {
    const { submitContactForm, campaignId } = props;
    try {
      await submitContactForm({ ...values, campaignId });
      message.success(USER_LANDING.CONTACT_FORM.SUCCESS[language]);
      form.resetFields();
    } catch (e) {
      message.error(USER_LANDING.CONTACT_FORM.ERROR[language]);
    }
  };

  const onFinishFailed = () => {};

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      size="large"
      className={styles.contactForm}
    >
      <Form.Item
        type="text"
        name="name"
        rules={validationRules(language).name}
        validateTrigger="onBlur"
      >
        <Input
          className={styles.input}
          placeholder={USER_LANDING.CONTACT_FORM.NAME[language]}
        />
      </Form.Item>
      <Form.Item
        type="text"
        name="email"
        rules={validationRules(language).email}
        validateTrigger="onBlur"
      >
        <Input
          className={styles.input}
          placeholder={USER_LANDING.CONTACT_FORM.EMAIL[language]}
        />
      </Form.Item>
      <Form.Item
        name="phone"
        type="text"
        rules={validationRules(language).phone}
        validateTrigger="onBlur"
      >
        <Input
          type="tel"
          className={styles.input}
          placeholder={USER_LANDING.CONTACT_FORM.PHONE[language]}
        />
      </Form.Item>
      <Form.Item className={styles.button}>
        <Button
          className={styles.buttonElement}
          htmlType="submit"
          type="primary"
          style={{ backgroundColor: color, color: fontColor }}
        >
          {USER_LANDING.CONTACT_FORM.SUBMIT[language]}
        </Button>
      </Form.Item>
    </Form>
  );
}
