import { put, takeEvery } from 'redux-saga/effects';
import { change, arrayInsert } from 'redux-form';
import {
  UPLOAD_FILE_SUCCESS,
  CREATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_SUCCESS,
} from './actions';
import { ROUTES } from '../../constants/routes';
import {
  CREATE_CAMPAIGN_DETAILS,
  CREATE_CERTIFICATE_TIERS,
} from '../forms/names';

export function* uploadSuccess() {
  yield takeEvery(UPLOAD_FILE_SUCCESS, function* (action) {
    const { data } = action;
    const { field, file } = data.file;
    if (field.includes('images')) {
      const position = field.split('-').pop();
      return yield put(
        arrayInsert(CREATE_CERTIFICATE_TIERS, 'images', position, file),
      );
    }
    yield put(change(CREATE_CAMPAIGN_DETAILS, field, file));
  });
}

export function* saveCampaignSuccess(history) {
  yield takeEvery(
    [CREATE_CAMPAIGN_SUCCESS, UPDATE_CAMPAIGN_SUCCESS],
    function* (action) {
      yield history.push(ROUTES.CAMPAIGNS);
    },
  );
}
