import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Descriptions, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import classes from './BillingSettings.module.scss';
import { ROUTES } from '../../constants/routes';

const spinStyle = {
  width: '100vw',
  height: '100vh',
  maxHeight: 'initial',
  zIndex: 2,
};

export default class PaymentSettingsPage extends Component {
  componentDidMount() {
    const { user } = this.props;

    this.props.getCampaigns(user.id, 0);
  }

  render() {
    const {
      loading,
      user: { liqpayPublicKey },
      isCampaignsExists,
    } = this.props;

    return (
      <div className={classes.container}>
        <Spin spinning={loading} size="large" style={spinStyle}>
          <div className={classes.column}>
            <FontAwesomeIcon className={classes.icon} icon={faWallet} />
            <h3 className={classes.title}>Платіжні дані</h3>
          </div>
          {liqpayPublicKey && (
            <Descriptions className={classes.formWrapper} bordered>
              <Descriptions.Item label="Публічний ключ Liqpay">
                {liqpayPublicKey}
              </Descriptions.Item>
            </Descriptions>
          )}
          {!isCampaignsExists && !liqpayPublicKey && (
            <>
              <p className={classes.text}>
                Перш ніж підключити платіжну систему, вам потрібно створити
                першу кампанію. Після створення використовуйте посилання на
                сторінку кампанії для налаштування платежів.
              </p>
              <Button>
                <Link to={ROUTES.CREATE_CAMPAIGN}>Створити кампанію</Link>
              </Button>
            </>
          )}
          {!liqpayPublicKey && isCampaignsExists && (
            <>
              <p className={classes.text}>
                У вас відсутній профіль в платіжній системі
              </p>
              <Button disabled={!isCampaignsExists}>
                <Link to={ROUTES.LIQPAY_ACCOUNT_CREATE}>
                  Створити Liqpay профіль
                </Link>
              </Button>
            </>
          )}
        </Spin>
      </div>
    );
  }
}
