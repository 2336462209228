export const COOKIE_TERMS_USAGES = 'isCookiesTermsUsagesAccepted';
export const AUTH_TOKEN = 'token';
export const PREVIEW = 'preview';
export const REFERRAL_CODE = 'referral';

export const getToken = () => {
  return window.localStorage.getItem(AUTH_TOKEN) || null;
};

export const setToken = token => {
  return window.localStorage.setItem(AUTH_TOKEN, token);
};

export const removeToken = () => {
  return window.localStorage.removeItem(AUTH_TOKEN);
};

export const setPreview = preview => {
  return window.localStorage.setItem(PREVIEW, JSON.stringify(preview));
};

export const getPreview = () => {
  return JSON.parse(window.localStorage.getItem(PREVIEW));
};

export const getCookieTerms = () => {
  return JSON.parse(localStorage.getItem(COOKIE_TERMS_USAGES));
};

export const setCookieTerms = isAccepted => {
  return localStorage.setItem(COOKIE_TERMS_USAGES, JSON.stringify(isAccepted));
};

export const getReferralCode = () => {
  return JSON.parse(localStorage.getItem(REFERRAL_CODE));
};

export const setReferralCode = code => {
  return localStorage.setItem(REFERRAL_CODE, JSON.stringify(code));
};

export const removeReferralCode = () => {
  return localStorage.removeItem(REFERRAL_CODE);
};
