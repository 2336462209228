import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  campaignData,
  certificateData,
  certificateTiers,
} from '../../redux/landing/selectors';
import { getLandingData, getPaymentData } from '../../redux/landing/actions';
import NewCertificateBuyPage from './NewCertificateBuyPage';

const selector = createStructuredSelector({
  certificateTiers,
  campaignData,
  certificateData,
});

const actions = {
  getLandingData,
  getPaymentData,
};

export default connect(selector, actions)(NewCertificateBuyPage);
