export const prefix = 'users';

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`;
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`;
export const LIST_FAILURE = `${prefix}/LIST_FAILURE`;

export const listUsers = () => ({
  types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: api => api.users.list(),
});

export const SHOW_REQUEST = `${prefix}/SHOW_REQUEST`;
export const SHOW_SUCCESS = `${prefix}/SHOW_SUCCESS`;
export const SHOW_FAILURE = `${prefix}/SHOW_FAILURE`;

export const showUser = id => ({
  types: [SHOW_REQUEST, SHOW_SUCCESS, SHOW_FAILURE],
  promise: api => api.users.show(id),
  id,
});

export const HIDE = `${prefix}/HIDE`;

export const hideUser = () => ({
  type: HIDE,
});

export const CREATE_REQUEST = `${prefix}/CREATE_REQUEST`;
export const CREATE_SUCCESS = `${prefix}/CREATE_SUCCESS`;
export const CREATE_FAILURE = `${prefix}/CREATE_FAILURE`;

export const createUser = data => ({
  types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  promise: api => api.users.create({ data }),
});

export const UPDATE_REQUEST = `${prefix}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${prefix}/UPDATE_FAILURE`;

export const updateUser = data => ({
  types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  promise: api => api.users.update({ data }),
});

export const DELETE_REQUEST = `${prefix}/DELETE_REQUEST`;
export const DELETE_SUCCESS = `${prefix}/DELETE_SUCCESS`;
export const DELETE_FAILURE = `${prefix}/DELETE_FAILURE`;

export const deleteUser = id => ({
  types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
  promise: api => api.users.delete({ id }),
});

export const HOMEPAGE_CONTACT_REQUEST = `${prefix}/HOMEPAGE_CONTACT_REQUEST`;
export const HOMEPAGE_CONTACT_SUCCESS = `${prefix}/HOMEPAGE_CONTACT_SUCCESS`;
export const HOMEPAGE_CONTACT_FAILURE = `${prefix}/HOMEPAGE_CONTACT_FAILURE`;

export const homepageContact = data => ({
  types: [
    HOMEPAGE_CONTACT_REQUEST,
    HOMEPAGE_CONTACT_SUCCESS,
    HOMEPAGE_CONTACT_FAILURE,
  ],
  promise: api => api.users.homepageContact({ data }),
});

export const CREATE_BY_CAMPAIGN_ADMIN_REQUEST = `${prefix}/CREATE_BY_CAMPAIGN_ADMIN_REQUEST`;
export const CREATE_BY_CAMPAIGN_ADMIN_SUCCESS = `${prefix}/CREATE_BY_CAMPAIGN_ADMIN_SUCCESS`;
export const CREATE_BY_CAMPAIGN_ADMIN_FAILURE = `${prefix}/CREATE_BY_CAMPAIGN_ADMIN_FAILURE`;
export const createUserByCampaignAdmin = data => ({
  types: [
    CREATE_BY_CAMPAIGN_ADMIN_REQUEST,
    CREATE_BY_CAMPAIGN_ADMIN_SUCCESS,
    CREATE_BY_CAMPAIGN_ADMIN_FAILURE,
  ],
  promise: api => api.users.createByCampaignAdmin(data),
});

export const UPDATE_BY_CAMPAIGN_ADMIN_REQUEST = `${prefix}/UPDATE_BY_CAMPAIGN_ADMIN_REQUEST`;
export const UPDATE_BY_CAMPAIGN_ADMIN_SUCCESS = `${prefix}/UPDATE_BY_CAMPAIGN_ADMIN_SUCCESS`;
export const UPDATE_BY_CAMPAIGN_ADMIN_FAILURE = `${prefix}/UPDATE_BY_CAMPAIGN_ADMIN_FAILURE`;

export const updateUserByCampaignAdmin = data => ({
  types: [
    UPDATE_BY_CAMPAIGN_ADMIN_REQUEST,
    UPDATE_BY_CAMPAIGN_ADMIN_SUCCESS,
    UPDATE_BY_CAMPAIGN_ADMIN_FAILURE,
  ],
  promise: api => api.users.updateByCampaignAdmin(data),
});
