export const LINK_LABELS = {
  HOME: 'Головна',
  CAMPAIGNS: 'Кампанії',
  CERTIFICATES: 'Сертифікати',
  HELP: 'Допомога',
  DASHBOARD: 'Панель керування',
  PROFILE: 'Мій профіль',
  PROFILE_SETTINGS: 'Налаштування профілю',
  BILLING_SETTINGS: 'Налаштування платежів',
  USER_ADMINISTRATION_SETTINGS: 'Адміністрування',
  ABOUT_US: 'Про нас',
  CONTACT_US: 'Контакти',
};

export const EXPIRE_VALUES = {
  INFINITY: 'INFINITY',
  MONTHS_1: 'MONTHS_1',
  MONTHS_3: 'MONTHS_3',
  MONTHS_6: 'MONTHS_6',
  MONTHS_12: 'MONTHS_12',
  MONTHS_18: 'MONTHS_18',
  MONTHS_24: 'MONTHS_24',
  MONTHS_36: 'MONTHS_36',
};
export const EXPIRE_LABELS = {
  INFINITY: 'Необмежений',
  MONTHS_1: '1 місяць',
  MONTHS_3: '3 місяць',
  MONTHS_6: '6 місяців',
  MONTHS_12: '12 місяців',
  MONTHS_18: '18 місяців',
  MONTHS_24: '24 місяців',
  MONTHS_36: '36 місяців',
};
export const EXPIRATION_OPTIONS = [
  {
    label: EXPIRE_LABELS.INFINITY,
    value: EXPIRE_VALUES.INFINITY,
  },
  {
    label: EXPIRE_LABELS.MONTHS_1,
    value: EXPIRE_VALUES.MONTHS_1,
  },
  {
    label: EXPIRE_LABELS.MONTHS_3,
    value: EXPIRE_VALUES.MONTHS_3,
  },
  {
    label: EXPIRE_LABELS.MONTHS_6,
    value: EXPIRE_VALUES.MONTHS_6,
  },
  {
    label: EXPIRE_LABELS.MONTHS_12,
    value: EXPIRE_VALUES.MONTHS_12,
  },
  {
    label: EXPIRE_LABELS.MONTHS_18,
    value: EXPIRE_VALUES.MONTHS_18,
  },
  {
    label: EXPIRE_LABELS.MONTHS_24,
    value: EXPIRE_VALUES.MONTHS_24,
  },
  {
    label: EXPIRE_LABELS.MONTHS_36,
    value: EXPIRE_VALUES.MONTHS_36,
  },
];
export const LANGUAGE_VALUES = {
  UK: 'UK',
  EN: 'EN',
  RU: 'RU',
};
export const LANGUAGE_LABELS = {
  UK: 'Українська',
  EN: 'Англійська',
  RU: 'Російська',
};
export const LANGUAGE_OPTIONS = [
  {
    label: LANGUAGE_LABELS.UK,
    value: LANGUAGE_VALUES.UK,
  },
  {
    label: LANGUAGE_LABELS.RU,
    value: LANGUAGE_VALUES.RU,
  },
  {
    label: LANGUAGE_LABELS.EN,
    value: LANGUAGE_VALUES.EN,
  },
];

export const CERTIFICATE_TYPES = {
  GIFT: 'GIFT',
  SELF: 'SELF',
};

export const CERTIFICATE_TYPES_OPTIONS = [
  {
    label: 'У подарунок',
    value: CERTIFICATE_TYPES.GIFT,
  },
  {
    label: 'Для себе',
    value: CERTIFICATE_TYPES.SELF,
  },
];

export const ELEMENTS_IDS = {
  CERTIFICATES: 'certificates-list',
  FEEDBACK: 'feedback',
  BUY_FORM: 'buyForm',
  PAYMENT_FORM: 'liqpay_checkout',
};
