import { createStructuredSelector } from 'reselect';
import {
  campaignData,
  certificateData,
  certificateTiers,
} from '../../../redux/landing/selectors';
import { getLandingData, getPaymentData } from '../../../redux/landing/actions';
import { connect } from 'react-redux';
import Content from './Content';

const selector = createStructuredSelector({
  certificateTiers,
  campaignData,
  certificateData,
});

const actions = {
  getLandingData,
  getPaymentData,
};

export { Content };

export default connect(selector, actions)(Content);
