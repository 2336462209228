import React, { Component } from 'react';
import { Form, Button, Input } from 'antd';

import styles from './PasswordRecoveryForm.module.scss';

const validationRules = {
  email: [
    {
      required: true,
      message: 'Будь ласка, введіть ваш email',
    },
    {
      type: 'email',
      message: 'Будь ласка, введіть правильний email',
    },
  ],
};

export default class PasswordRecoveryForm extends Component {
  onFinish = values => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  onFinishFailed = () => {};

  render() {
    return (
      <Form
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        className={styles.form}
        size="large"
      >
        <Form.Item
          name="email"
          type="text"
          label="Електронна пошта"
          className={styles.input}
          rules={validationRules.email}
          validateTrigger="onBlur"
        >
          <Input />
        </Form.Item>
        <Form.Item className={styles.button}>
          <Button htmlType="submit" type="primary">
            Відновити пароль
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
