import React from 'react';
import { Layout } from 'antd';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { USER_LANDING } from '../../constants/texts';
import { getFontColor } from '../../utils';
import { ELEMENTS_IDS } from '../../constants/labels';
import styles from './NewLandingFooter.module.scss';

const { Footer } = Layout;

class NewLandingFooter extends React.Component {
  handleScrollCertificateClick = () => {
    const {
      goToPageAndScroll,
      campaignData: { identifier },
    } = this.props;

    goToPageAndScroll(
      ROUTES.USER_LANDING.replace(':id', identifier),
      ELEMENTS_IDS.CERTIFICATES,
    );
  };

  handleScrollFeedbackClick = () => {
    const {
      goToPageAndScroll,
      campaignData: { identifier },
    } = this.props;

    goToPageAndScroll(
      ROUTES.USER_LANDING.replace(':id', identifier),
      ELEMENTS_IDS.FEEDBACK,
      300,
    );
  };

  render() {
    const {
      campaignData: {
        primaryColor,
        instagram,
        facebook,
        telegram,
        address,
        email,
        phone,
        language,
        footerLogo,
        isSocialShown,
      },
    } = this.props;
    const { isFontWhite, fontColor } = getFontColor(primaryColor);

    return (
      <Footer
        className={classnames(styles.footer, {
          [styles.footerWhite]: isFontWhite,
        })}
        style={{ backgroundColor: primaryColor }}
      >
        <div>
          <div className={styles.logo}>
            <img src={footerLogo} alt="Logo" />
          </div>

          <div className={styles.navigation}>
            <div
              className={styles.topLink}
              style={{ '--color-var': fontColor }}
              onClick={this.handleScrollCertificateClick}
            >
              {USER_LANDING.NAV.CERTIFICATES[language]}
            </div>
            <span
              className={styles.topLink}
              style={{ '--color-var': fontColor }}
              onClick={this.handleScrollFeedbackClick}
            >
              {USER_LANDING.NAV.FEEDBACK[language]}
            </span>
          </div>
          <div className={styles.contacts}>
            <div className={styles.contactBlock}>
              <p className={styles.blockHeader}>
                {USER_LANDING.CONTACTS.CONTACT_DATA[language]}:
              </p>
              <span>{address}</span>
              <NavLink href={`tel:${phone}`} text={phone} color={fontColor} />
              <NavLink
                href={`mailto:${email}`}
                text={email}
                color={fontColor}
              />
            </div>
            {isSocialShown && (
              <div className={styles.contactBlock}>
                <p className={styles.blockHeader}>
                  {USER_LANDING.CONTACTS.SOCIALS[language]}:
                </p>
                {instagram && (
                  <NavLink
                    href={instagram}
                    text="Instagram"
                    color={fontColor}
                  />
                )}
                {facebook && (
                  <NavLink href={facebook} text="Facebook" color={fontColor} />
                )}
                {telegram && (
                  <NavLink href={telegram} text="Telegram" color={fontColor} />
                )}
              </div>
            )}

            <div className={styles.policyBlock}>
              <p className={styles.blockHeader}>
                {USER_LANDING.CONTACTS.POLICY[language]}:
              </p>
              <Link
                className={styles.link}
                style={{ '--color-var': fontColor }}
                to={ROUTES.TERMS}
              >
                {USER_LANDING.FOOTER_POLICY[language]}
              </Link>
              <Link
                className={styles.link}
                style={{ '--color-var': fontColor }}
                to={ROUTES.AGREEMENT}
              >
                {USER_LANDING.FOOTER_OFFER[language]}
              </Link>
            </div>
          </div>
          <div className={styles.policy}>
            <span className={styles.powered}>
              Powered by{' '}
              <Link
                rel="noopener noreferrer"
                target="_blank"
                className={styles.link}
                style={{ '--color-var': fontColor }}
                to={ROUTES.HOME}
              >
                Upfront
              </Link>
            </span>
          </div>
        </div>
      </Footer>
    );
  }
}

function NavLink({ href, text, color }) {
  return (
    <a
      className={styles.link}
      style={{ '--color-var': color }}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
    >
      {text}
    </a>
  );
}

export default NewLandingFooter;
