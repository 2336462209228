import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import styles from './InfoBlock.module.scss';
import plane from '../../../assets/images/plane.svg';
import wallet from '../../../assets/images/platform_1.svg';
import { ROUTES } from '../../../constants/routes';
import { getFontColor } from '../../../utils';
import HolidayGarland from '../../HolidayGarland';
import garland1 from '../../../assets/images/garlandMain.png';

export const ICON_TYPES = {
  WALLET: 'WALLET',
  PLANE: 'PLANE',
};

function getIcon(type = ICON_TYPES.PLANE) {
  switch (type) {
    case ICON_TYPES.WALLET: {
      return wallet;
    }

    default:
      return plane;
  }
}

const InfoBlock = ({
  logo,
  heading,
  description,
  isIconAdded,
  campaignId,
  type,
  backgroundColor,
  wrapperClassName = '',
  translation,
}) => {
  const { isFontWhite } = getFontColor(backgroundColor);

  return (
    <section
      className={classnames(styles.infoBlock, wrapperClassName)}
      style={{ backgroundColor }}
    >
      {Boolean(translation.length) && (
        <div className={styles.languages}>
          {translation.map(t => (
            <a
              key={t.language}
              className={classnames(styles.language, {
                [styles.white]: isFontWhite,
                [styles.active]: window.location.pathname.includes(
                  `campaign/${t.identifier}`,
                ),
              })}
              href={ROUTES.USER_LANDING.replace(':id', t.identifier)}
            >
              {t.language}
            </a>
          ))}
        </div>
      )}
      <HolidayGarland src={garland1} width={80} top="0vw" right="-1vw" />
      <Link to={ROUTES.USER_LANDING.replace(':id', campaignId)}>
        <img className={styles.logo} src={logo} alt="Лого кампанії" />
      </Link>
      <h1
        className={classnames(styles.sectionTitle, {
          [styles.white]: isFontWhite,
        })}
      >
        {heading}
      </h1>
      {description && (
        <div
          className={classnames(styles.description, {
            [styles.alabaster]: isFontWhite,
          })}
        >
          {description}
        </div>
      )}
      {isIconAdded && (
        <img className={styles.plane} src={getIcon(type)} alt="Інфо іконка" />
      )}
    </section>
  );
};

export default InfoBlock;
