export const prefix = 'administration';

const STATUS = {
  ACTIVE: 'ACTIVE',
  NOT_ACTIVE: 'NOT_ACTIVE',
};

export const GET_USERS_REQUEST = `${prefix}/GET_USERS_REQUEST`;
export const GET_USERS_SUCCESS = `${prefix}/GET_USERS_SUCCESS`;
export const GET_USERS_FAILURE = `${prefix}/GET_USERS_FAILURE`;

export const getList = (ownerId, page) => ({
  types: [GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE],
  promise: api => api.administration.get(ownerId, page),
});

export const CHANGE_PAGE = `${prefix}/CHANGE_PAGE`;
export const changePage = page => ({ type: CHANGE_PAGE, payload: { page } });

export const UPDATE_PERMISSION_REQUEST = `${prefix}/UPDATE_PERMISSION_REQUEST`;
export const UPDATE_PERMISSION_SUCCESS = `${prefix}/UPDATE_PERMISSION_SUCCESS`;
export const UPDATE_PERMISSION_FAILURE = `${prefix}/UPDATE_PERMISSION_FAILURE`;

export const updatePermission = params => ({
  types: [
    UPDATE_PERMISSION_REQUEST,
    UPDATE_PERMISSION_SUCCESS,
    UPDATE_PERMISSION_FAILURE,
  ],
  promise: api => api.administration.update(params),
});

export const updateStatus = (_id, isActive) =>
  updatePermission({
    _id,
    status: isActive ? STATUS.NOT_ACTIVE : STATUS.ACTIVE,
  });
