import Base from './base';

export default class Campaign extends Base {
  get({ page, userId, search }) {
    return this.apiClient.get(`/certificates`, { page, userId, search });
  }
  use(id, data, adminId) {
    return this.apiClient.put(`/certificates/use`, { id, data, adminId });
  }
  deactivate(id) {
    return this.apiClient.put(`/certificates/deactivate`, { id });
  }
  exportClients(userId) {
    return this.apiClient.get('/certificates/export/clients', { userId });
  }
  exportCertificates(userId, dateFrom, dateTo) {
    return this.apiClient.get('/certificates/export/certificates', {
      userId,
      dateFrom,
      dateTo,
    });
  }
  getBalance(id) {
    return this.apiClient.get(`/certificates/check/balance`, { id });
  }
  resendCertificate({
    buyerEmail,
    buyerFirstName,
    buyerLastName,
    recipientEmail,
    certificateId,
  }) {
    return this.apiClient.post('/certificates/resend', {
      buyerFirstName,
      buyerLastName,
      buyerEmail,
      recipientEmail,
      certificateId,
    });
  }
  dashboardCreateCertificate(data) {
    return this.apiClient.post('/certificates/create', data);
  }
}
