import { change, untouch, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ResendModal from './ResendModal';
import {
  selectedCertificate,
  resendCertificateFormValues,
} from '../../../redux/certificate/selectors';
import { RESEND_CERTIFICATES } from '../../../redux/forms/names';

const form = reduxForm({
  form: RESEND_CERTIFICATES,
})(ResendModal);

const selectors = createStructuredSelector({
  initialValues: selectedCertificate,
  resendValues: resendCertificateFormValues,
});
const actions = {
  changeField: change,
  untouchField: untouch,
};

export default connect(selectors, actions)(form);
