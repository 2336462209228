export const prefix = 'certificate';

export const GET_CERTIFICATES_REQUEST = `${prefix}/GET_CERTIFICATES_REQUEST`;
export const GET_CERTIFICATES_SUCCESS = `${prefix}/GET_CERTIFICATES_SUCCESS`;
export const GET_CERTIFICATES_FAILURE = `${prefix}/GET_CERTIFICATES_FAILURE`;
export const getCertificates = (userId, page = 1, search) => ({
  types: [
    GET_CERTIFICATES_REQUEST,
    GET_CERTIFICATES_SUCCESS,
    GET_CERTIFICATES_FAILURE,
  ],
  promise: api => api.certificate.get({ page, userId, search }),
});

export const CHANGE_PAGE = `${prefix}/CHANGE_PAGE`;
export const changePage = page => ({ type: CHANGE_PAGE, payload: { page } });

export const CHANGE_SEARCH = `${prefix}/CHANGE_SEARCH`;
export const changeSearch = search => ({
  type: CHANGE_SEARCH,
  payload: { search },
});

export const USE_CERTIFICATE_REQUEST = `${prefix}/USE_CERTIFICATE_REQUEST`;
export const USE_CERTIFICATE_SUCCESS = `${prefix}/USE_CERTIFICATE_SUCCESS`;
export const USE_CERTIFICATE_FAILURE = `${prefix}/USE_CERTIFICATE_FAILURE`;
export const use = (id, data, adminId) => ({
  types: [
    USE_CERTIFICATE_REQUEST,
    USE_CERTIFICATE_SUCCESS,
    USE_CERTIFICATE_FAILURE,
  ],
  promise: api => api.certificate.use(id, data, adminId),
});

export const DEACTIVATE_CERTIFICATE_REQUEST = `${prefix}/DEACTIVATE_CERTIFICATE_REQUEST`;
export const DEACTIVATE_CERTIFICATE_SUCCESS = `${prefix}/DEACTIVATE_CERTIFICATE_SUCCESS`;
export const DEACTIVATE_CERTIFICATE_FAILURE = `${prefix}/DEACTIVATE_CERTIFICATE_FAILURE`;
export const deactivate = id => ({
  types: [
    DEACTIVATE_CERTIFICATE_REQUEST,
    DEACTIVATE_CERTIFICATE_SUCCESS,
    DEACTIVATE_CERTIFICATE_FAILURE,
  ],
  promise: api => api.certificate.deactivate(id),
});

export const EXPORT_CLIENTS_REQUEST = `${prefix}/EXPORT_CLIENTS_REQUEST`;
export const EXPORT_CLIENTS_SUCCESS = `${prefix}/EXPORT_CLIENTS_SUCCESS`;
export const EXPORT_CLIENTS_FAILURE = `${prefix}/EXPORT_CLIENTS_FAILURE`;
export const exportClients = userId => ({
  types: [
    DEACTIVATE_CERTIFICATE_REQUEST,
    DEACTIVATE_CERTIFICATE_SUCCESS,
    DEACTIVATE_CERTIFICATE_FAILURE,
  ],
  promise: api => api.certificate.exportClients(userId),
});
export const EXPORT_CERTIFICATES_REQUEST = `${prefix}/EXPORT_CERTIFICATES_REQUEST`;
export const EXPORT_CERTIFICATES_SUCCESS = `${prefix}/EXPORT_CERTIFICATES_SUCCESS`;
export const EXPORT_CERTIFICATES_FAILURE = `${prefix}/EXPORT_CERTIFICATES_FAILURE`;

export const exportCertificates = (userId, dateFrom, dateTo) => ({
  types: [
    EXPORT_CERTIFICATES_REQUEST,
    EXPORT_CERTIFICATES_SUCCESS,
    EXPORT_CERTIFICATES_FAILURE,
  ],
  promise: api => api.certificate.exportCertificates(userId, dateFrom, dateTo),
});

export const GET_BALANCE_REQUEST = `${prefix}/GET_BALANCE_REQUEST`;
export const GET_BALANCE_SUCCESS = `${prefix}/GET_BALANCE_SUCCESS`;
export const GET_BALANCE_FAILURE = `${prefix}/GET_BALANCE_FAILURE`;
export const getBalance = id => ({
  types: [GET_BALANCE_REQUEST, GET_BALANCE_SUCCESS, GET_BALANCE_FAILURE],
  promise: api => api.certificate.getBalance(id),
});
export const SET_SELECTED_CERTIFICATE_ID = `${prefix}/SET_SELECTED_CERTIFICATE_ID`;
export const CLEAR_SELECTED_CERTIFICATE_ID = `${prefix}/CLEAR_SELECTED_CERTIFICATE_ID`;
export const setSelectedCertificateId = id => ({
  type: SET_SELECTED_CERTIFICATE_ID,
  payload: id,
});
export const clearSelectedCertificateId = () => ({
  type: CLEAR_SELECTED_CERTIFICATE_ID,
});
export const RESEND_CERTIFICATE_REQUEST = `${prefix}/RESEND_CERTIFICATE_REQUEST`;
export const RESEND_CERTIFICATE_SUCCESS = `${prefix}/RESEND_CERTIFICATE_SUCCESS`;
export const RESEND_CERTIFICATE_FAILURE = `${prefix}/RESEND_CERTIFICATE_FAILURE`;
export const resendCertificate = data => {
  return {
    types: [
      RESEND_CERTIFICATE_REQUEST,
      RESEND_CERTIFICATE_SUCCESS,
      RESEND_CERTIFICATE_FAILURE,
    ],
    promise: api => api.certificate.resendCertificate(data),
  };
};

export const DASHBOARD_CREATE_CERTIFICATES_REQUEST = `${prefix}/DASHBOARD_CREATE_CERTIFICATES_REQUEST`;
export const DASHBOARD_CREATE_CERTIFICATES_SUCCESS = `${prefix}/DASHBOARD_CREATE_CERTIFICATES_SUCCESS`;
export const DASHBOARD_CREATE_CERTIFICATES_FAILURE = `${prefix}/DASHBOARD_CREATE_CERTIFICATES_FAILURE`;

export const createCertificate = data => {
  return {
    types: [
      DASHBOARD_CREATE_CERTIFICATES_REQUEST,
      DASHBOARD_CREATE_CERTIFICATES_SUCCESS,
      DASHBOARD_CREATE_CERTIFICATES_FAILURE,
    ],
    promise: api => api.certificate.dashboardCreateCertificate(data),
  };
};
