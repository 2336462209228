import React from 'react';
import classnames from 'classnames';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import styles from './BrandFooter.module.scss';
import { ROUTES } from '../../constants/routes';
import logoFooter from '../../assets/images/logo_footer.svg';
import { FacebookIcon, InstagramIcon, TelegramIcon } from './SocialIcons';
import content from '../../pages/HomePage/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcMastercard } from '@fortawesome/free-brands-svg-icons';
const { Footer } = Layout;
const { footer = {} } = content;

const HomeFooter = () => (
  <Footer className={styles.footer}>
    <div className={styles.info}>
      <Link to={ROUTES.HOME} className={styles.logo}>
        <img src={logoFooter} alt="UpFront" />
      </Link>
      <div>
        <div>{footer.address}</div>
        <div>
          <a href={`tel:${footer.hrefTel}`}>{footer.tel}</a>
        </div>
        <div>
          <a href={`mailto:${footer.email}`}>{footer.email}</a>
        </div>
        <div className={styles.payments}>
          <FontAwesomeIcon
            color="#ffffff"
            className={styles.paymentIcon}
            icon={faCcVisa}
          />
          <FontAwesomeIcon
            color="#ffffff"
            className={styles.paymentIcon}
            icon={faCcMastercard}
          />
        </div>
      </div>
    </div>
    <div className={styles.terms}>
      <div className={styles.termsContent}>
        <Link to={ROUTES.TERMS}>{footer.policy}</Link>
        <Link to={ROUTES.AGREEMENT}>{footer.agrement}</Link>
        <Link to={ROUTES.FAQ}>{footer.faq}</Link>
      </div>
    </div>
    <div className={styles.social}>
      {footer.telegram && (
        <a href={footer.telegram} target="_blank" rel="noopener noreferrer">
          <TelegramIcon
            className={classnames(styles.socialIcon, styles.telegram)}
          />
        </a>
      )}
      {footer.facebook && (
        <a href={footer.facebook} target="_blank" rel="noopener noreferrer">
          <FacebookIcon
            className={classnames(styles.socialIcon, styles.facebook)}
          />
        </a>
      )}
      {footer.instagram && (
        <a href={footer.instagram} target="_blank" rel="noopener noreferrer">
          <InstagramIcon
            className={classnames(styles.socialIcon, styles.instagram)}
          />
        </a>
      )}
    </div>
  </Footer>
);

export default HomeFooter;
