import React from 'react';
import singleIcon from '../../assets/images/gift-1.svg';

export { singleIcon };

export default function Icon({ color }) {
  return (
    <svg
      width="235"
      height="234"
      viewBox="0 0 235 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.0512 166.141C57.8809 168.971 61.1373 171.276 64.6692 172.993L51.7072 185.955C47.0209 190.641 47.0209 198.239 51.7072 202.926L85.1474 236.366C89.8337 241.052 97.4317 241.052 102.118 236.366L107.009 231.475L165.404 289.87C170.09 294.556 177.688 294.556 182.375 289.87L289.383 182.861C294.07 178.175 294.07 170.577 289.383 165.891L230.988 107.496L235.879 102.605C240.565 97.9184 240.565 90.3204 235.879 85.6341L202.439 52.1939C197.752 47.5076 190.154 47.5076 185.468 52.1939L172.506 65.1559C170.789 61.624 168.484 58.3676 165.654 55.5379C158.97 48.853 149.903 45.0975 140.449 45.0975C130.995 45.0975 121.929 48.8531 115.244 55.538C99.9952 70.7864 100.723 90.4955 104.242 104.729C90.0087 101.21 70.2997 100.482 55.0512 115.73C48.3663 122.415 44.6108 131.482 44.6108 140.936C44.6108 150.39 48.3663 159.456 55.0512 166.141ZM72.0218 132.701C69.8377 134.885 68.6108 137.847 68.6108 140.936C68.6108 144.025 69.8377 146.987 72.0218 149.171C74.2058 151.355 77.1679 152.582 80.2566 152.582C83.3358 152.582 86.2893 151.362 88.4714 149.191L106.911 130.751C104.963 129.984 102.889 129.258 100.737 128.634C89.1787 125.278 79.0072 125.716 72.0218 132.701ZM148.704 88.9582L130.264 107.398C129.498 105.45 128.772 103.375 128.147 101.224C124.791 89.6655 125.229 79.4939 132.214 72.5085C134.398 70.3245 137.36 69.0975 140.449 69.0975C143.538 69.0975 146.5 70.3245 148.684 72.5085C150.868 74.6925 152.095 77.6547 152.095 80.7434C152.095 83.8226 150.875 86.7761 148.704 88.9582ZM144.044 127.56L160.513 144.029L210.423 94.1194L193.953 77.6497L165.687 105.916C165.676 105.927 165.665 105.938 165.654 105.949L144.044 127.56ZM77.163 194.44L127.073 144.53L143.543 161L93.6327 210.91L77.163 194.44ZM177.484 161L227.394 210.91L263.928 174.376L214.018 124.466L177.484 161ZM160.513 177.971L210.423 227.88L173.889 264.414L123.979 214.504L160.513 177.971Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.0512 166.141C57.8809 168.971 61.1373 171.276 64.6692 172.993L51.7072 185.955C47.0209 190.641 47.0209 198.239 51.7072 202.926L85.1474 236.366C89.8337 241.052 97.4317 241.052 102.118 236.366L107.009 231.475L165.404 289.87C170.09 294.556 177.688 294.556 182.375 289.87L289.383 182.861C294.07 178.175 294.07 170.577 289.383 165.891L230.988 107.496L235.879 102.605C240.565 97.9184 240.565 90.3204 235.879 85.6341L202.439 52.1939C197.752 47.5076 190.154 47.5076 185.468 52.1939L172.506 65.1559C170.789 61.624 168.484 58.3676 165.654 55.5379C158.97 48.853 149.903 45.0975 140.449 45.0975C130.995 45.0975 121.929 48.8531 115.244 55.538C99.9952 70.7864 100.723 90.4955 104.242 104.729C90.0087 101.21 70.2997 100.482 55.0512 115.73C48.3663 122.415 44.6108 131.482 44.6108 140.936C44.6108 150.39 48.3663 159.456 55.0512 166.141ZM72.0218 132.701C69.8377 134.885 68.6108 137.847 68.6108 140.936C68.6108 144.025 69.8377 146.987 72.0218 149.171C74.2058 151.355 77.1679 152.582 80.2566 152.582C83.3358 152.582 86.2893 151.362 88.4714 149.191L106.911 130.751C104.963 129.984 102.889 129.258 100.737 128.634C89.1787 125.278 79.0072 125.716 72.0218 132.701ZM148.704 88.9582L130.264 107.398C129.498 105.45 128.772 103.375 128.147 101.224C124.791 89.6655 125.229 79.4939 132.214 72.5085C134.398 70.3245 137.36 69.0975 140.449 69.0975C143.538 69.0975 146.5 70.3245 148.684 72.5085C150.868 74.6925 152.095 77.6547 152.095 80.7434C152.095 83.8226 150.875 86.7761 148.704 88.9582ZM144.044 127.56L160.513 144.029L210.423 94.1194L193.953 77.6497L165.687 105.916C165.676 105.927 165.665 105.938 165.654 105.949L144.044 127.56ZM77.163 194.44L127.073 144.53L143.543 161L93.6327 210.91L77.163 194.44ZM177.484 161L227.394 210.91L263.928 174.376L214.018 124.466L177.484 161ZM160.513 177.971L210.423 227.88L173.889 264.414L123.979 214.504L160.513 177.971Z"
          fill={color}
          fillOpacity="0.25"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="-0.289286"
          y1="68.683"
          x2="159.429"
          y2="-29.7485"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C7A0F6" />
          <stop offset="1" stopColor={color} />
        </linearGradient>
        <clipPath id="clip0">
          <rect
            y="161"
            width="227"
            height="227"
            transform="rotate(-45 0 161)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
