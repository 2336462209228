import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import {
  faUserCircle,
  faSignInAlt,
  faQuestionCircle,
  faWallet,
  faBusinessTime,
  faGift,
  faComments,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFontColor } from '../../utils';
import { ROUTES } from '../../constants/routes';
import classes from './Hamburger.module.scss';

class Hamburger extends React.Component {
  state = { isOpened: false };

  handleMenuClick = () => {
    this.setState({ isOpened: !this.state.isOpened });
  };

  handleMenuItemClick = element => () => {
    let elementToScrollTo;

    if (typeof element === 'string') {
      const div = document.getElementById(element);
      const landingHeader = 250;
      elementToScrollTo =
        div.getBoundingClientRect().top + window.pageYOffset - landingHeader;
    } else {
      const homeHeaderHeight = 70;
      elementToScrollTo =
        element.current.getBoundingClientRect().top +
        window.scrollY -
        homeHeaderHeight;
    }

    window.scroll({
      top: elementToScrollTo,
      behavior: 'smooth',
    });

    this.handleMenuClick();
  };

  handleScrollCertificateClick = () => {
    this.props.handleScrollCertificateClick();
    this.handleMenuClick();
  };

  handleScrollFeedbackClick = () => {
    this.props.handleScrollFeedbackClick();
    this.handleMenuClick();
  };

  getAuthItems = () => {
    const { isAuthenticated } = this.props;

    if (isAuthenticated) {
      return (
        <li>
          <Link to={ROUTES.CAMPAIGNS}>
            <FontAwesomeIcon className={classes.icon} icon={faUserCircle} />
            Особистий кабінет
          </Link>
        </li>
      );
    }

    return (
      <>
        <li>
          <Link to={ROUTES.LOGIN}>
            <FontAwesomeIcon className={classes.icon} icon={faUserCircle} />
            Вхід
          </Link>
        </li>
        <li>
          <Link to={ROUTES.SIGNUP}>
            <FontAwesomeIcon className={classes.icon} icon={faSignInAlt} />
            Реєстрація
          </Link>
        </li>
      </>
    );
  };

  homePageContent = () => {
    const { howItWorks, opportunities, pricing } = this.props;
    const { handleMenuItemClick, getAuthItems } = this;
    return (
      <>
        <ul className={classes.sidebarMenuInner}>
          {getAuthItems()}
          <li onClick={handleMenuItemClick(howItWorks)}>
            <FontAwesomeIcon className={classes.icon} icon={faQuestionCircle} />
            Як це працює?
          </li>
          <li onClick={handleMenuItemClick(pricing)}>
            <FontAwesomeIcon className={classes.icon} icon={faWallet} />
            Тарифи
          </li>
          <li onClick={handleMenuItemClick(opportunities)}>
            <FontAwesomeIcon className={classes.icon} icon={faBusinessTime} />
            Можливості
          </li>
        </ul>
      </>
    );
  };

  getContent = () => {
    const { isHomePage } = this.props;
    if (isHomePage) {
      return this.homePageContent();
    }

    return this.newLandingContent();
  };

  newLandingContent = () => {
    return (
      <>
        <ul
          className={classnames(
            classes.sidebarMenuInner,
            classes.sidebarMenuBlack,
          )}
        >
          <li onClick={this.handleScrollCertificateClick}>
            <FontAwesomeIcon className={classes.icon} icon={faGift} />
            Сертифікати
          </li>
          <li onClick={this.handleScrollFeedbackClick}>
            <FontAwesomeIcon className={classes.icon} icon={faComments} />
            Зворотній зв&apos;язок
          </li>
        </ul>
      </>
    );
  };

  render() {
    const { isOpened } = this.state;
    const { right, sidebarBackground, isHomePage } = this.props;
    const { isFontWhite } = getFontColor(sidebarBackground);

    return (
      <div
        className={classnames(classes.hamburgerContainer, {
          [classes.whiteFonts]: isFontWhite && !isHomePage,
        })}
      >
        <div className={classes.hamburgerMain} style={{ right: `${right}vw` }}>
          <input
            onChange={this.handleMenuClick}
            type="checkbox"
            checked={isOpened}
            id="openSidebarMenu"
          />
          <label
            htmlFor="openSidebarMenu"
            className={classes.sidebarIconToggle}
          >
            <div
              className={classnames(
                classes.spinner,
                classes.diagonal,
                classes.part1,
              )}
            />
            <div className={classnames(classes.spinner, classes.horizontal)} />
            <div
              className={classnames(
                classes.spinner,
                classes.diagonal,
                classes.part2,
              )}
            />
          </label>
          <div
            className={classes.sidebarMenu}
            style={{
              right: `-${100 + Number(right)}vw`, //calculate initial position of sidebarPage as it moves with hamburgerMain
              background: sidebarBackground,
            }}
          >
            {this.getContent()}
          </div>
        </div>
      </div>
    );
  }
}

export default Hamburger;
