export function getFormValues(form) {
  if (!form || !form.values) {
    return {};
  }

  return form.values;
}

export function validateForm(excludedFields = []) {
  return form => {
    if (!form || !form.syncErrors) {
      return false;
    }

    const errors = Object.keys(form.syncErrors).filter(
      key => !excludedFields.includes(key),
    );

    return Boolean(errors.length);
  };
}
