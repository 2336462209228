import { connect } from 'react-redux';
import { reduxForm, getFormValues, change } from 'redux-form';
import { createStructuredSelector, createSelector } from 'reselect';
import Details from './Details';
import {
  campaignInitialValues,
  footerCustomData,
  formCampaigns,
} from '../../../redux/campaign/selectors';
import { getShortCampaigns } from '../../../redux/campaign/actions';
import { userId } from '../../../redux/profile/selectors';
import { CREATE_CAMPAIGN_DETAILS } from '../../../redux/forms/names';

const formValuesSelector = createSelector(
  getFormValues(CREATE_CAMPAIGN_DETAILS),
  values => values || {},
);

const selectCampaigns = createSelector(formCampaigns, list => {
  return [{ value: null, label: 'Немає перекладу' }, ...list];
});

const selector = createStructuredSelector({
  userId,
  footer: footerCustomData,
  campaigns: selectCampaigns,
  formValues: formValuesSelector,
  initialValues: campaignInitialValues,
});
const actions = {
  getCampaigns: getShortCampaigns,
  changeField: (field, value) => change(CREATE_CAMPAIGN_DETAILS, field, value),
};
const form = reduxForm({
  form: CREATE_CAMPAIGN_DETAILS,
})(Details);

export default connect(selector, actions)(form);
