import 'rc-color-picker/assets/index.css';
import React from 'react';
import classnames from 'classnames';
import Picker from 'rc-color-picker';
import * as classes from './ColorPicker.module.scss';

const ColorPicker = ({ input, className = '' }) => {
  const handleChange = value => {
    const { onChange } = input;

    onChange(value.color);
  };

  return (
    <div className={classnames(classes.wrapper, className)}>
      <Picker color={input.value} defaultColor="#FFF" onChange={handleChange} />
      {input.value ? (
        <span className={classes.notification}>({input.value})</span>
      ) : (
        <span className={classes.notification}>(виберіть колір)</span>
      )}
    </div>
  );
};

export default ColorPicker;
