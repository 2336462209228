import { takeEvery } from 'redux-saga/effects';
import { PAYMENT_DATA_SUCCESS, SHOW_SUCCESS_PAYMENT_SCREEN } from './actions';
import { ROUTES } from '../../constants/routes';

export function* paymentIntentionSuccess(history) {
  yield takeEvery(PAYMENT_DATA_SUCCESS, function* (action) {
    if (action.data) {
      const { identifier, certificateTierId } = action.data;
      yield history.push(
        ROUTES.PAYMENT_CERTIFICATE.replace(':id', identifier).replace(
          ':certificateId',
          certificateTierId,
        ),
      );
    }
  });
}

export function* paymentBuySuccess(history) {
  yield takeEvery(SHOW_SUCCESS_PAYMENT_SCREEN, function* (action) {
    if (action.payload) {
      const { campaignId, certificateId } = action.payload;

      yield history.push(
        ROUTES.SUCCESS_CERTIFICATE.replace(':id', campaignId).replace(
          ':certificateId',
          certificateId,
        ),
      );
    }
  });
}
