import React from 'react';
import { Modal } from 'antd';
import { Form, Field } from 'redux-form';
import { TextField } from 'redux-form-antd';
import { CERTIFICATE_TYPES } from '../../../constants/labels';
import { required, email, notEmpty } from '../../../redux/validators';

class ResendModal extends React.Component {
  render() {
    const { isOpen, onCancel, onSubmit, resendValues } = this.props;
    const {
      id,
      firstName,
      lastName,
      recipient,
      certificateType,
    } = resendValues;

    const dataToSend = {
      buyerFirstName: firstName,
      buyerLastName: lastName,
      certificateId: id,
      recipientEmail: recipient,
      buyerEmail: resendValues.email,
    };

    return (
      <Modal
        title="Переслати сертифікат"
        centered
        visible={isOpen}
        okText="Підтвердити"
        cancelText="Відміна"
        onCancel={onCancel}
        destroyOnClose
        onOk={onSubmit(dataToSend)}
      >
        <Form onSubmit={onSubmit(dataToSend)}>
          <Field
            name="firstName"
            type="text"
            placeholder="Ім'я"
            component={TextField}
            validate={[required, notEmpty]}
          />
          <Field
            name="lastName"
            type="text"
            placeholder="Прізвише"
            component={TextField}
            validate={[required, notEmpty]}
          />
          <Field
            name="email"
            type="text"
            placeholder="Електронна адреса"
            component={TextField}
            validate={[required, email, notEmpty]}
          />
          {certificateType === CERTIFICATE_TYPES.GIFT && (
            <Field
              name="recipient"
              type="text"
              placeholder="Електронна адреса одержувача"
              component={TextField}
              validate={[required, email, notEmpty]}
            />
          )}
        </Form>
      </Modal>
    );
  }
}
export default ResendModal;
