const ROUTES = {
  HOME: '/',
  TERMS: '/terms',
  AGREEMENT: '/agreement',
  USER_LANDING: '/campaign/:id',
  USER_LANDING_PREVIEW: '/campaign-preview/:id',
  BUY_CERTIFICATE: '/campaign/:id/buy-certificate/:certificateId',
  PAYMENT_CERTIFICATE: '/campaign/:id/buy-certificate/:certificateId/payment',
  SUCCESS_CERTIFICATE: '/campaign/:id/buy-certificate/:certificateId/success',
  DASHBOARD: '/dashboard',
  ADMIN: '/admin',
  ADMIN_USERS: '/admin/users',
  ADMIN_REFERRAL: '/admin/referral',
  CAMPAIGNS: '/campaigns',
  CERTIFICATES: '/certificates',
  CERTIFICATES_USE: '/certificates/use/:code',
  CERTIFICATES_VIEW: '/certificates/view/:token',
  CREATE_CAMPAIGN: '/create-campaign',
  EDIT_CAMPAIGN: '/edit-campaign/:id',
  LOGIN: '/login',
  SIGNUP: '/signup',
  SIGNUP_REFERRAL: '/signup/:referral',
  CONFIRM_EMAIL: '/confirmEmail/:hash',
  PROFILE: '/profile-settings',
  RESET_PASSWORD: '/reset-password/:hash',
  PASSWORD_RECOVERY: '/password-recovery',
  BILLING_SETTINGS: '/billing-settings',
  USER_ADMINISTRATION_SETTINGS: '/users-settings',
  LIQPAY_ACCOUNT_CREATE: '/billing-settings/create',
  CHECK_BALANCE: '/campaign/:id/check-balance/:certificateId',
  FAQ: '/faq',
  HELP: '/help',
};

export { ROUTES };
