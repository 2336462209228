import React from 'react';
import classnames from 'classnames';
import { getFontColor } from '../../utils';
import BuyForm from './BuyForm';
import BuyPageContent from './Content';
import classes from './NewCertificateBuyPage.module.scss';

class NewCertificateBuyPage extends React.Component {
  componentDidMount() {
    const { campaignData, getLandingData } = this.props;

    if (!campaignData) {
      getLandingData();
    }
  }

  render() {
    const {
      getPaymentData,
      certificateData: { price, id },
      campaignData: { primaryColor, backgroundColor, language },
    } = this.props;

    return (
      <div
        style={{ backgroundColor }}
        className={classnames(classes.pageContainer, {
          [classes.whiteFonts]: getFontColor(backgroundColor).isFontWhite,
        })}
      >
        <BuyPageContent />
        <BuyForm
          price={price}
          certificateId={id}
          language={language}
          color={primaryColor}
          backgroundColor={backgroundColor}
          primaryColor={primaryColor}
          onSubmit={getPaymentData}
        />
      </div>
    );
  }
}

export default NewCertificateBuyPage;
