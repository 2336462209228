import { USER_LANDING } from '../constants/texts';
import { formatDate } from './stringFormatters';

export const scrollToElementId = (id, topOffset = 50) => {
  const element = document.getElementById(id);

  if (!element) {
    return;
  }

  const elementTop = element.getBoundingClientRect().top;
  const scrollTo = elementTop + window.pageYOffset - topOffset;

  window.scroll({
    top: scrollTo,
    behavior: 'smooth',
  });
};

export const getCommencementDate = ({ certificateData, campaignData }) => {
  const { commencementDate, isDateSameAsBuyDate } = certificateData;
  const { language } = campaignData;

  if (commencementDate) {
    return (
      commencementDate &&
      USER_LANDING.PAYMENT.ACTIVATE_DATE[language].replace(
        'ACTIVATE',
        formatDate(commencementDate),
      )
    );
  }
  if (isDateSameAsBuyDate) {
    return USER_LANDING.PAYMENT.ACTIVATE_DATE[language].replace(
      'ACTIVATE',
      formatDate(new Date()),
    );
  }

  return null;
};

export const getExpireDate = ({ certificateData, campaignData }) => {
  const { expireDate } = certificateData;
  const { language } = campaignData;

  if (!expireDate) {
    return null;
  }

  return USER_LANDING.PAYMENT.EXPIRE_DATE[language].replace(
    'EXPIRE',
    USER_LANDING.PAYMENT[expireDate][language],
  );
};
