import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { ROUTES } from '../../../constants/routes';
import * as classes from './Filter.module.scss';

const Filter = () => (
  <div className={classes.container}>
    <span className={classes.header}>Кампанії</span>
    <Link to={ROUTES.CREATE_CAMPAIGN}>
      <Button>Створити кампанію</Button>
    </Link>
  </div>
);

export default Filter;
