import moment from 'moment';
import { USER_LANDING } from '../constants/texts';

export const createRangeString = target => {
  const separator = target.max ? ' - ' : '+';
  return `${target.min}${separator}${target.max || ''}`;
};

export const prepateDate = dateString => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }

  return `${dt}/${month}/${year}`;
};

export const CURRENCY_OPTIONS = {
  minimumFractionDigits: 2,
  style: 'currency',
};
const locale = 'en-US';

export const currencyString = (
  num,
  currency = 'USD',
  options = CURRENCY_OPTIONS,
) => {
  return `${num.toLocaleString(locale, {
    ...options,
    currency,
  })}`;
};

const transformPrice = (price, language = 'UK') => {
  const value = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return `${value} ${USER_LANDING.CURRENCY[language]}.`;
};

export const formatDate = date => moment(date).format('DD/MM/YYYY');
export const formatPrice = (price, language) => {
  const value = Number(price / 100).toFixed(0);

  return transformPrice(value, language);
};
export const formatPriceWithoutDecimals = price => {
  const value = Number(price).toFixed(0);

  return transformPrice(value);
};
export const priceWithDecimal = price => {
  const formattedPrice = (price / 100).toFixed(2);
  if (formattedPrice.slice(-2) === '00') {
    return price / 100;
  }
  return formattedPrice;
};
export const formatDateWithDots = date => moment(date).format('DD.MM.YY');
