import React from 'react';
import styles from './Agreement.module.scss';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

export default function Agreement() {
  return (
    <div className={styles.agreement}>
      <h1>Публічна оферта Upfront</h1>
      <p>
        <b>Опубліковано: 27.05.2020</b>
      </p>
      <p>Привіт!</p>
      <p>
        Дякуємо, що ви користуєтесь нашим вебсайтом{' '}
        <a
          href="https://upfront.com.ua"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://upfront.com.ua
        </a>{' '}
        («Вебсайт»)!
      </p>
      <p>
        Ця <b>Публічна оферта</b> (також <b>«Умови використання»</b> або{' '}
        <b>«Умови»</b>) містить умови юридично обов’язкового договору приєднання
        (<b>«Договір»</b>) між фізичною особою-підприємцем Чижем Романом
        Вікторовичем, що діє під комерційним найменуванням{' '}
        <b>Upfront («Upfront»)</b> та <b>Користувачем</b> (<b>«Користувач»</b>,
        а також <b>«ви»</b>).
      </p>
      <p>
        Порядок приймання умов Публічної оферти та укладення Договору між вами
        та Upfront визначений нижче. Будь ласка, уважно прочитайте ці Умови
        перед використанням Вебсайту. Використання Вебсайту без приймання умов
        Публічної офери не дозволяється.
      </p>
      <p>
        Умови та порядок обробки персональних даних Користувачів Вебсайту
        визначені у{' '}
        <Link to={ROUTES.TERMS} target="_blank" rel="noopener noreferrer">
          Політиці конфіденційності
        </Link>
        , що є невід’ємною частиною цих Умов.
      </p>
      <p>
        <b>Зверніть увагу</b>, що Сертифікати пропонуються Користувачам до
        продажу Корпоративними клієнтами Upfront. Послуги, які надає Upfront,
        мають проміжний характер і лише уможливлюють створення, розміщення та
        доставку Сертифікатів. Upfront не є продавцем Сертифікатів та не несе
        відповідальності за їхню чинність, можливість їх обміну на Продукцію
        Корпоративного клієнта чи за якість чи належність такої Продукції.
        Корпоративний клієнт самостійно несе відповідальність за сплату податків
        та ведення бухгалтерського обліку, а також дотримання інших визначених
        законом обов‘язків, які виникаються у зв’язку із продажем та погашенням
        Сертифікатів.
      </p>
      <p>
        Upfront не здійснює моніторинг інформації, опублікованої нашими
        Корпоративними клієнтами, та не несе відповідальності за таку
        інформацію.
      </p>
      <p>
        Якщо ви вважаєте, що будь-яка інформація чи матеріали на Вебсайті
        порушують ваші права чи застосовне законодавство, або якщо у вас
        виникнуть якісь запитання щодо використання Вебсайту, чи у вас є якісь
        ідеї щодо того, як можна покращити наш сервіс, будь ласка, надішліть нам
        листа на електронну пошту{' '}
        <a href="mailto:send@upfront.com.ua">send@upfront.com.ua</a>.
      </p>
      <h2>1. Визначення термінів</h2>
      <p>
        Нашими <b>Корпоративним клієнтами</b> є резиденти України, юридичні
        особи та індивідуальні підприємці, які прийняли умови цієї Публічної
        оферти та за допомогою функціоналу нашого Вебсайту розмістили та
        пропонують до придбання Сертифікати на отримання Продукції
        Корпоративного клієнта. Корпоративними клієнтами можуть бути
        представники будь-якого бізнесу, наприклад, ресторани, пекарні,
        кінотеатри, басейни, спортзали, концертні зали, музичні та інші
        фестивалі тощо. Корпоративні клієнти вважаються Користувачами для цілей
        цих Умов використання, якщо інше прямо не зазначено у них.
      </p>
      <p>
        <b>Сертифікатом</b> є інформація в електронній формі, у тому числі
        вміщена у файлі будь-якого формату, що може бути прочитаний за допомогою
        комп’ютерних програм та відтворений на Пристроях, пред’явлення якої
        згідно із Умовами сертифіката надає Пред’явникові Сертифіката право на
        отримання Продукції Корпоративного клієнта Upfront. Сертифікат
        ідтверджує здійснення Покупцем Сертифіката авансового платежу за
        Продукцію Корпоративного клієнта.
      </p>
      <p>
        <b>Продукція Корпоративного клієнта</b> може охоплювати будь-які товари,
        послуги та/або роботи, самостійно або у удь-якій комбінації, які надає
        Корпоративний клієнт та які визначені у Сертифікаті.
      </p>
      <p>
        <b>Умови Сертифіката</b> – номінал та строк дії Сертифіката, умови його
        пред’явлення, умови погашення, а також інші умови, що регулють
        використання Сертифіката.
      </p>
      <p>
        <b>Покупець Сертифіката</b> – Користувач, що придбав Сертифікат,
        пропонований до продажу на Вебсайті.
      </p>
      <p>
        <b>Пред’явник Сертифіката</b> – особа, яка володіє Сертифікатом та
        показує його Корпоративному клієнту Upfront, який здійснив продаж такого
        Сертифіката, з метою перевірки чинності такого Сертифіката та отримання
        визначеної у Сертифікаті Продукції на умовах, визначених Сертифікатом.
      </p>
      <p>
        <b>Профіль Корпоративного клієнта</b> (також <b>«Профіль»</b>) –
        особистий кабінет Корпоративного клієнта на Вебсайті, до якого він
        отримує доступ після успішної реєстрації та підтвердження адреси
        електронної пошти.
      </p>
      <h2>2. Порядок укладення Договору між Користувачем та Upfront</h2>
      <p>
        Ця Публічна оферта вважається прийнятою вами після виконання таких дій:
      </p>
      <ol type="a">
        <li>
          <h3>Укладення Договору із Корпоративним клієнтом</h3>
          <p>
            Для прийняття умов цієї Публічної оферти Корпоративний клієнт
            повинен зареєструватися на Вебсайті. Реєстрація може бути здійснена
            в один із таких способів:
          </p>
          <ol>
            <li>
              <p>
                Корпоративний клієнт може самостійно зареєструватися на
                Вебсайті, натиснувши кнопку «Реєстрація», що розміщена на
                головній сторінці Вебсайту, та заповнивши всі обов’язкові поля у
                реєстраційній формі. Фізична-особа підприємець, яка реєструється
                як Корпоративний клієнт від власного імені або фізична особа від
                імені юридичної особи, яку вона представляє, обов’язково повинна
                вказати: (1) своє ім’я та прізвище; (2) адресу електронної
                пошти; (3) номер телефону; (4) назву компанії, яку вона
                представляє, комерційне найменування, під яким вона веде
                діяльність, або, за відсутності таких, – ім’я, прізвище та
                по-батькові фізичної особи-підприємця. Крім цього, при
                реєстрації особа обов’язково повинна створити пароль доступу до
                акаунту.
                <br />
                <br />
                або
              </p>
            </li>
            <li>
              <p>
                З метою реєстрації Корпоративний клієнт може звернутися до
                адміністратора Вебсайту, контактні дані якого розміщені на
                Вебсайті. Адміністратор Вебсайту самостійно створить Профіль
                Корпоративного клієнта відповідно до даних, що були надані
                Кінцевим користувачем. Після цього Корпоративний клієнт отримає
                інструкції щодо доступу до Профілю та автоматично згенерований
                пароль доступу до нього на вказану ним адресу електронної пошти.
              </p>
            </li>
          </ol>
          <p>
            Після реєстрації Корпоративний клієнт повинен прийняти умови
            Публічної оферти згідно із інструкціями, розміщеними у його Профілі.
            Прийняття умов Публічної оферти засвідчується за допомогою
            електронного підпису одноразовим ідентифікатором, а саме переходом
            за автоматично згенерованим посиланням у вигляді алфавітно-цифрової
            комбінації, надісланої вам на електронну пошту.
          </p>
          <p>
            Якщо у процесі реєстрації Корпоративним клієнтом була допущена
            помилка у його ідентифікаційних даних, він може її виправити до
            моменту прийняття Публічної оферти. У подальшому зміна даних може
            відбуватися лише за умови належної ідентифікації особи.
          </p>
        </li>
        <li>
          <h3>Укладення Договору з іншими Користувачами Upfront</h3>
          <p>
            Користувачі можуть використовувати певний обмежений функціонал
            Вебсайту, а також купувати Сертифікати без проходження процедури
            реєстрації.
          </p>
          <p>
            Користуючись Вебсайтом та здійснюючи оплату за Сертифікат(и) ви
            приймаєте умови цієї Публічної оферти.
          </p>
        </li>
      </ol>
      <h2>3. Послуги Upfront</h2>
      <p>
        Upfront надає інформаційні електронні послуги із проектування,
        створення, розміщення та доставки індивідуалізованих електронних
        Сертифікатів, пред’явлення яких дозволяє отримати продукцію наших
        Корпоративних клієнтів, рекламні послуги, а також будь-які інші супутні
        послуги, що пропонуються на Вебсайті.
      </p>
      <ol type="a">
        <li>
          <h3>Послуги для Покупців Сертифікатів</h3>
          <p>
            Основними послугами, які Upfront надає Покупцям Сертифікатів, є
            інформаційні послуги: ми дбаємо про те, щоб Покупці могли отримати
            усі необхідні відомості про емітентів Сертифікатів, якими є наші
            Корпоративні клієнти, про випущені ними Сертифікати. Ми також
            уможливлюємо купівлю таких Сертифікатів та доставляємо їх у
            електронні поштові скриньки Покупців. Крім цього, ми надаємо
            технічну та іншу підтримку Користувачам нашого Вебсайту, і можемо
            комунікувати з Користувачами з приводу будь-яких питань, що
            стосуються використання Вебсайту та наших послуг. Перелічені послуги
            не передбачають додаткової оплати зі сторони Покупців Сертифікатів.
          </p>
        </li>
        <li>
          <h3>Послуги для Корпоративних клієнтів</h3>
          <p>Upfront надає такі послуги своїм Корпоративним клієнтам:</p>
          <ul>
            <li>
              послуги із надання функціоналу Вебсайту для створення,
              проектування та дизайну індивідуалізованої вебсторінки
              Корпоративного клієнта на Вебсайті, у тому числі розміщення на
              такій вебсторінці інформації про Корпоративного клієнта, його
              торгових марок та брендованих елементів дизайну;
            </li>
            <li>
              послуги зі створення та підтримки активного покликання на
              вебсторінку Корпоративного клієнта на Вебсайті;
            </li>
            <li>
              послуги зі створення та розміщення на Вебсторінці Корпоративного
              клієнта Сертифікатів, що у майбутньому дозволять Покупцям
              Сертифікатів отримати Продукцію Корпоративного клієнта;
            </li>
            <li>
              послуги із ведення аналітики, обліку та управління випущеними
              Сертифікатами, у тому числі, послуги з їхньої активації,
              деактивації, часткового та повного погашення;
            </li>
            <li>
              будь-які інші супутні послуги, що можуть пропонуватися Upfront, у
              тому числі рекламні послуги.
            </li>
          </ul>
          <p>
            Корпоративний клієнт отримує доступ до таких послуг через свій
            Профіль на Вебсайті.
          </p>
        </li>
      </ol>
      <h2>4. Сертифікат та його використання</h2>
      <p>
        Корпоративний клієнт визначає Умови Сертифікатів та здійснює їхній обмін
        на Продукцію самостійно. Upfront не несе відповідальності за
        достовірність такої інформації, а також не відповідає за можливість
        обміну такого Сертифіката на Продукцію Корпоративного клієнта.
      </p>
      <p>
        Кожен Сертифікат є індивідуалізованим за допомогою унікального номера,
        вказаного у ньому. Сертифікат також містить QR-код для його активації.
      </p>
      <p>
        Покупець Сертифіката несе відповідальність за схоронність такого
        Сертифіката та належні заходи захисту. Покупець Сертифіката може на
        власний розсуд передати його іншій особі. Корпоративний клієнт або
        Користувач-Покупець Сертифіката може звернутися до Upfront стосовно
        відновлення Сертифіката. Такі звернення аналізуються у кожному
        конкретному випадку, і Upfront залишає за собою право відмовити у
        задоволенні звернення на власний розсуд.
      </p>
      <p>
        Upfront не надає можливості повернення або обміну Сертифікатів на
        грошові кошти. Сертифікат не підлягає поверненню або обміну на грошові
        кошти, якщо інше не вказано в Умовах такого Сертифікату, що визначаються
        Корпоративним клієнтом на його власний розсуд.
      </p>
      <p>
        Якщо інше не зазначено в Умовах Сертифіката, право на отримання
        Продукції взамін на Сертифікат має Пред’явник Сертифіката.
      </p>
      <p>
        Жодна компенсація не надається за втрачені, загублені чи викрадені
        Сертифікати, а також за Сертифікати, строк дії яких закінчився.
      </p>
      <p>
        Сертифікат може бути обміняний на Продукцію Корпоративного клієнта лише
        протягом строку його дії та відповідно до Умов такого Сертифіката, що
        визначаються Корпоративним клієнтом на його власний розсуд.
      </p>
      <p>
        Усі претензії та скарги щодо чинності чи належності Сертифікатів та
        Продукції Корпоративного клієнта повинні надсилатися Корпоративному
        клієнтові, оскільки Upfront не є стороною цих договірних відносин.
      </p>
      <h2>5. Права та обов’язки Корпоративного клієнта </h2>
      <p>
        Корпоративний клієнт погоджується, що його вебсторінка на Вебсайті може
        містити також інформацію про те, що вона була створена за допомогою
        сервісу Upfront.
      </p>
      <p>
        Корпоративний клієнт зобов’язаний розмістити на своїй вебсторінці
        контактний номер телефону та адресу електронної пошти, за якими Покупці
        сертифікатів та інші заінтересовані особи можуть зв’язатися із
        Корпоративним клієнтом. Такі дані можуть відрізнятися від тих, які були
        вказані Корпоративним клієнтом при реєстрації Профілю. Крім цього,
        Корпоративний клієнт зобов’язаний розмістити на вебсторінці усю
        необхідну інформацію, що може вимагатися Законом України «Про захист
        прав споживачів», Законом України «Про електронну комерцію» та іншим
        застосовним законодавством.
      </p>
      <p>
        Корпоративний клієнт повинен обов’язково визначити та опублікувати на
        своїй вебсторінці всі істотні Умови Сертифікатів, зокрема, строк їхньої
        дії, номінал, можливість обміну на Продукцію з вищою вартістю, ніж
        номінал Сертифіката, можливість часткового погашення, опис Продукції,
        яка може бути отримана взамін на Сертифікат, можливість повернення
        Сертифікату, можливість його обміну тощо тощо. Номінал Сертифікату, що
        пропонується Клієнтом на своїй вебсторінці, не може перевищувати
        15,000.00 грн.
      </p>
      <p>
        Корпоративний клієнт самостійно несе відповідальність за чинність
        проданих Сертифікатів, можливість їхнього обміну на Продукцію, якість та
        належність Продукції, а також за вирішення будь-яких спорів щодо
        Сертифіката чи Продукції із Покупцем Сертифіката.
      </p>
      <p>
        Корпоративний клієнт зобов’язаний здійснювати оплату за надані послуги
        Upfront відповідно до умов цієї Публічної оферти або інших домовленостей
        Upfront та Корпоративного клієнта.
      </p>
      <p>
        Корпоративний клієнт повинен дотримуватися застосовного законодавства
        під час використання Вебсайту.
      </p>
      <p>
        Корпоративному клієнтові заборонено продавати Сертифікати на отримання
        будь-якої незаконної Продукції чи Продукції, права на розповсюдження
        якої у нього немає, зокрема, забороненої Продукції платіжної системи (
        <a
          href="https://docs.google.com/document/d/1Ymo-DhxEahoya2ybTwFMC5SLpaU3MV3yy5_rb62Yl5c/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          перелік
        </a>
        ). Корпоративний клієнт не має права публікувати на Вебсайті будь-яку
        інформацію чи матеріали, що порушують права третіх осіб або є заборонені
        законом. У випадку порушення Корпоративним клієнтом цього правила та
        накладення будь-яких штрафних санкцій на Upfront у зв’язку із цим,
        Корпоративний клієнт зобов’язується відшкодувати Upfront розмір
        сплачених штрафних санкції на запит Upfront.
      </p>
      <p>
        Корпоративний клієнт у будь-який час може припинити використовувати
        послуги Upfront, а також припинити надання доступу або ж відновити
        доступ Користувачів до створеної ним вебсторінки на Вебсайті.
      </p>
      <h2>6. Ціна та порядок оплати послуг Upfront </h2>
      <p>
        За надання послуг Корпоративному клієнтові Upfront утримує комісію з
        кожного проданого Сертифіката. Розмір комісії (становить 5%), додаткові
        умови та порядок оплати можуть публікуватися на Вебсайті або у профілі
        Корпоративного клієнта.
      </p>
      <p>
        Upfront може узгоджувати із Корпоративним клієнтом інші умови оплати,
        ніж це визначено на Вебсайті або у цій Публічній оферті, шляхом обміну
        електронними повідомленнями за допомогою{' '}
        <a href="mailto:send@upfront.com.ua">електронної пошти</a>. Для
        таких цілей Корпоративний клієнт використовує адресу електронної пошти,
        вказану при реєстрації Профіля.
      </p>
      <p>
        Копоративний клієнт погоджується, що проведення розрахунків за
        Сертифікати та утримання комісії Upfront відбувається з використанням
        платіжної системи Liqpay (АТ КБ «Приватбанк»). Корпоративний клієнт
        гарантує, що виплати на користь не є виплатами доходів фізичних осіб і
        Upfront не є податковим агентом щодо виплати податку на доходи фізичних
        осіб та інших платежів та зборів, що підлягають утриманню із виплат на
        користь Корпоративного клієнта.
      </p>
      <p>
        {' '}
        Корпоративний клієнт зобов’язаний дотримуватися{' '}
        <a
          href="https://docs.google.com/document/d/1v9frzHob8F9TAF7suOC9j7bjXDck9qm-sFu0n01Jy4Q/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          Умов та Правил надання послуг в системі Liqpay
        </a>
        . У випадку порушення Корпоративним клієнтом таких правил та накладення
        будь-яких штрафних санкцій на Upfront у зв’язку із цим, Корпоративний
        клієнт зобов’язується відшкодувати Upfront розмір сплачених штрафних
        санкції на запит Upfront.
      </p>
      <p>
        Платіжна система може стягувати додаткову комісію за проведення платежу.
        Корпоративний клієнт самостійно відповідає за сплату комісії платіжної
        системи, і вона сплачується додатково до комісії Upfront відповідно до
        правил платіжної системи.
      </p>
      <p>
        Корпоративний клієнт доручає Upfront розділити вартість кожного
        проданого Сертифіката, відповідно до умов, встановлених Договором,
        шляхом надання відповідного розпорядження платіжній системі, для
        зарахування платіжною системою відповідних сум, згідно з умовами оплати,
        встановленими у цій Публічній Оферті, на користь Корпоративного клієнта
        та Upfront.
      </p>
      <h2>7. Права інтелектуальної власності</h2>
      <p>
        Вебсайт та його компоненти, у тому числі логотипи та елементи дизайну,
        торгові марки, тексти, графіка, дані, статті, інформація, фото,
        зображення, ілюстрації тощо, належать Upfront й охороняються
        законодавством у сфері захисту прав інтелектуальної власності.
      </p>
      <p>
        Upfront надає Користувачам невиключну відкличну безоплатну ліцензію без
        права передання третім особам на доступ та використання Вебсайту у межах
        функціоналу, доступного в онлайн-режимі, що уможливлює надання послуг,
        визначених цими Умовами. Така ліцензія діє на території всього світу
        протягом строку чинності цих Договору між Upfront та Користувачем.
      </p>
      <p>
        Використання в комерційних цілях будь-яких матеріалів або компонентів
        Вебсайту в інший спосіб, ніж це передбачено цими Умовами, без письмового
        дозволу Upfront суворо заборонене.
      </p>
      <p>
        Торгові марки, брендовані елементи дизайну, тексти та зображення
        Корпоративних клієнтів, розміщені ними на вебсторінках, згенерованих за
        допомогою Вейбсайту Upfront, належать Корпоративним клієнтам. Upfront не
        отримує жодних прав щодо таких об’єктів інтелектуальної власності
        Корпоративних клієнтів, окрім невиключної відкличної безоплатної
        ліцензії (дозволу) на використання таких об’єктів з метою надання
        послуг, визначених цією Публічою офертою.
      </p>
      <h2>8. Використання Вебсайту</h2>
      <p>
        Вебсайт можуть використовувати тільки ті особи, що володіють необхідною
        для цього правоздатністю та дієздатністю. Забороняється використання
        цього Вебсайту особами, які не досягти повноліття, якщо вони не отримали
        дозволу на це своїх батьків чи опікунів.
      </p>
      <p>
        Приймаючи умови цієї Публічної оферти у порядку, описаному вище,
        Користувач підтверджує та гарантує, що він/вона володіє достатньою
        правоздатністю та дієздатністю для прийняття цих Умов, а також, де
        застосовно, що необхідна згода батьків чи опікунів була надана.
        Користувач також підтверджує, що у разі здійснення оплати через платіжну
        систему, посилання на яку розміщене на Вебсайті, він/вона не буде
        порушувати законодавство про запобігання легалізації (відмивання)
        доходів, одержаних злочинним шляхом.
      </p>
      <p>
        Upfront зберігає за собою право припинити роботу Вебсайту чи змінити
        його на наш розсуд у будь-який час, зокрема, але не виключно, ми можемо
        призупинити доступ до цього Вебсайту, додати на нього рекламу чи змінити
        обсяг Послуг чи доступний функціонал.
      </p>
      <p>
        Користувачам заборонено використовувати пошукових роботів («павуків») на
        будь-яких сторінках Вебсайту для збору даних, чи будь-якої частини
        контенту (вручну чи за допомогою автоматизованих засобів), а також
        вчиняти дії, що націлені на порушення нормального функціонування нашого
        Вебсайту, як за допомогою програмного забезпечення, так і без нього.
      </p>
      <p>
        Користувач зобов’язується не здійснювати жодних незаконних дій з
        використанням Вебсайту або у його межах.
      </p>
      <h2>9. Застереження щодо відповідальності та обмеження</h2>
      <p>
        Ми надаємо інформаційні послуги через Вебсайт відповідно до принципів
        «як є» та «як можливо». Це означає, що ми е можемо гарантувати (і ми не
        гарантуємо), що послуги будуть безперебійними, вчасними та не
        міститимуть помилок. Ми не можемо гарантувати, що Вебсайт належно
        працюватиме на усіх пристроях та за усіх особливих умов. Проте, ми
        докладемо усіх розумних зусиль, щоб забезпечити надання якісних послуг.
      </p>
      <p>
        Наш Вебсайт може містити посилання на інші вебсайти та ресурси, надані
        третіми сторонами (зокрема, платіжну систему). Ми не несемо жодної
        відповідальності за будь-який контент вебсайтів та ресурсів третіх
        сторін, ми також не контролюємо ці вебсайти та ресурси. Ви
        використовуєте такі вебсайти та ресурси третіх сторін на ваш власний
        ризик.
      </p>
      <p>
        В обсязі, дозволеному законом, у жодному випадку Upfront, наші
        працівники, підрядники, партнери чи агенти не несуть відповідальності за
        будь-які неточності чи упущення та будь-які спеціальні, непрямі та інші
        збитки, упущену вигоду, що спричинена чи будь-яким чином пов’язана із
        використанням чи неможливістю використання нашого Вебсайту чи
        опублікованої на ньому інформації.
      </p>
      <h2>10. Зміни</h2>
      <p>
        Ми можемо змінювати, доповнювати чи в інший спосіб модифікувати ці Умови
        час від часу у випадках, коли це вимагається змінами до законодавства,
        коли це потрібно для кращого захисту прав Користувачів, Upfront чи
        третіх осіб, коли ми розширюватимемо обсяг послуг або коли це зумовлено
        економічною необхідністю чи будь-якими змінами у нашій операційній
        діяльності.
      </p>
      <p>
        Будь ласка, періодично перевіряйте цю сторінку щоб упевнитися, що ви
        задоволені будь-якими змінами. Додаткові повідомлення про будь-які зміни
        з’являться, коли ви зайдете на Вебсайт після того, як зміни були
        внесені, або ж будуть надіслані вам на електронну пошту.
      </p>
      <p>
        Якщо ви продовжуєте використовувати Вебсайт після таких змін, ми
        вважатимемо, що ви прийняли їх, крім випадків, коли Upfront має
        обов’язок відповідно до застосовного закону отримати вашу явну згоду на
        такі зміни.
      </p>
      <h2>11. Застосовне право та вирішення спорів </h2>
      <p>
        Ці Умови та інші взаємовідносини між вами та Upfront регулюються правом
        України.
      </p>
      <p>
        Усі спори та розбіжності, що можуть виникнути щодо цих Умов, повинні
        вирішуватися шляхом переговорів. Для цілей вирішення таких спорів,
        ефективним та обов’язковим методом комунікації є кореспонденція з
        уповноваженими особами Upfront на адресу{' '}
        <a href="mailto:send@upfront.com.ua">send@upfront.com.ua</a>.
      </p>
      <p>
        Якщо спір не може бути вирішеним шляхом переговорів протягом 60 днів,
        він повинен бути переданий та остаточно вирішений відповідним судом за
        правом України.
      </p>
      <h2>12. Припинення дії</h2>
      <p>
        Цей Договір діє до того моменту, коли ви або Upfront на власний розсуд
        відмовиться від співпраці за Договором. Як Користувач, так і Upfront
        можуть відмовитися від укладеного Договору в односторонньому порядку у
        будь-який момент. Серед іншого, Upfront може відмовитися від цього
        Договору і припинити доступ Корпоративного клієнта до Вебсайту без
        будь-якого попереднього повідомлення про це у випадках, коли
        Корпоративний клієнт порушує свої зобов’язання перед Покупцями
        Сертифікатів, не дотримується вимог застосовного законодавства, або
        вчиняє дії, які призводять до порушень у функціонуванні Вебсайту чи
        негативно впливають на ділову репутацію Upfront.
      </p>
      <p>
        У разі відмови від цього Договору Upfront надішле повідомлення про це на
        адресу електронної пошти, вказану при реєстрації або купівлі
        Сертифіката.
      </p>
      <p>
        Користувач, який бажає відмовитися від цього Договору, повинен написати
        відповідний запит на адресу електронної пошти Upfront, вказану нижче.
      </p>
      <p>
        Припинення Договору не звільняє нікого із сторін від виконання
        обов’язків, що виникли до такого припинення.
      </p>
      <br />
      <h3>Upfront</h3>
      <p>
        ФОП Чиж Роман Вікторович <br />
        Адреса: 02088, м. Київ, вул. Садова, 8, буд. 18 <br />
        Дата та номер запису в ЄДРПОУ: №2 072 000 0000 039827 від 23.06.2017{' '}
        <br />
        <a href="mailto:send@upfront.com.ua">send@upfront.com.ua</a>
      </p>
    </div>
  );
}
