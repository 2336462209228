import { createSelector } from 'reselect';
import { matchPath } from 'react-router-dom';
import { prefix } from './actions';
import { ROUTES } from '../../constants/routes';

const root = state => state[prefix];
export const pathname = createSelector(root, state => state.location.pathname);

export const location = createSelector(root, state => state.location);

const getMatchByPath = route => pathname => {
  const match = matchPath(pathname, {
    path: route,
    exact: true,
    strict: false,
  });

  return Boolean(match);
};

export const isRootLanding = createSelector(
  pathname,
  getMatchByPath(ROUTES.USER_LANDING),
);

export const isBuyLanding = createSelector(
  pathname,
  getMatchByPath(ROUTES.BUY_CERTIFICATE),
);

export const isPaymentLanding = createSelector(
  pathname,
  getMatchByPath(ROUTES.PAYMENT_CERTIFICATE),
);

export const isSuccessLanding = createSelector(
  pathname,
  getMatchByPath(ROUTES.SUCCESS_CERTIFICATE),
);

export const isBalanceLanding = createSelector(
  pathname,
  getMatchByPath(ROUTES.CHECK_BALANCE),
);

export const isHomePage = createSelector(
  pathname,
  pathname => pathname === '/',
);

export const isLandingPage = createSelector(pathname, pathname =>
  pathname.includes('/campaign/'),
);

export const isCorporatePage = createSelector(pathname, pathname =>
  pathname.includes(ROUTES.CORPORATE),
);

export const campaignId = createSelector(
  pathname,
  pathname => pathname.split('/')[2],
);

export const certificateId = createSelector(
  pathname,
  pathname => pathname.split('/')[4],
);

export const isPreviewPage = createSelector(pathname, pathname =>
  pathname.includes('preview'),
);

export const showHomeFooter = createSelector(pathname, pathname =>
  ['terms', 'agreement'].some(item => pathname.includes(item)),
);

export const isFetchInitialCertificates = createSelector(
  pathname,
  pathname => !pathname.includes('/certificates/use'),
);
