import React from 'react';
import classnames from 'classnames';
import * as classes from './BrandInfo.module.scss';
import { USER_LANDING } from '../../constants/texts';
import { getFontColor } from '../../utils';
import Color from 'color';

class BrandInfo extends React.Component {
  state = { isOpen: false };

  handleOpenClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { isOpen } = this.state;
    const { about, language, backgroundColor } = this.props;

    if (!about) {
      return null;
    }

    const { isFontWhite } = getFontColor(backgroundColor);

    return (
      <div
        className={classes.wrapper}
        style={{ backgroundColor: Color(backgroundColor).lighten(0.2) }}
      >
        <div className={classes.row}>
          <span
            onClick={this.handleOpenClick}
            className={classnames({ [classes.white]: isFontWhite })}
          >
            {isOpen
              ? USER_LANDING.ABOUT_HIDE[language]
              : USER_LANDING.ABOUT[language]}
          </span>
        </div>
        <div
          className={classnames(classes.about, {
            [classes.visible]: isOpen,
            [classes.white]: isFontWhite,
          })}
        >
          <span className={classes.aboutContainer}>{about}</span>
        </div>
      </div>
    );
  }
}

export default BrandInfo;
