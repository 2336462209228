import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { initialize } from 'redux-form';

import { user, loading } from '../../redux/profile/selectors';
import { updateProfile, loadProfile } from '../../redux/profile/actions';
import { EDIT_PROFILE } from '../../redux/forms/names';

import ProfilePage from './ProfilePage';

const initializeForm = data => initialize(EDIT_PROFILE, data);

const selector = createStructuredSelector({
  user,
  loading,
});

const actions = {
  updateProfile,
  loadProfile,
  initializeForm,
};

export { ProfilePage };

export default connect(selector, actions)(ProfilePage);
