import { connect } from 'react-redux';
import { change, reduxForm, untouch, reset } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import {
  createCertificateDisabled,
  createCertificateValues,
  formCertificateTiers,
  isGiftCreateCertificate,
} from '../../../redux/certificate/selectors';
import { CREATE_CERTIFICATE } from '../../../redux/forms/names';
import { formCampaigns } from '../../../redux/campaign/selectors';
import { userId } from '../../../redux/profile/selectors';
import { getShortCampaigns } from '../../../redux/campaign/actions';
import { createCertificate } from '../../../redux/certificate/actions';
import CreateCertificateModal from './CreateCertificateModal';

const form = reduxForm({
  form: CREATE_CERTIFICATE,
  destroyOnUnmount: true,
})(CreateCertificateModal);

const selectors = createStructuredSelector({
  userId,
  campaigns: formCampaigns,
  isGift: isGiftCreateCertificate,
  certificates: formCertificateTiers,
  disabled: createCertificateDisabled,
  createValues: createCertificateValues,
});

const actions = {
  getShortCampaigns,
  createCertificate,
  changeField: change,
  untouchField: untouch,
  resetForm: () => reset(CREATE_CERTIFICATE),
};

export default connect(selectors, actions)(form);
