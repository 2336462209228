import React from 'react';
import { PhoneOutlined } from '@ant-design/icons';
import classes from '../HomePage.module.scss';
import { PHONE_NUMBER, PHONE_NUMBER_TEL } from '../../../constants/texts';

class Navbar extends React.Component {
  handleMenuItemClick = element => () => {
    const elementToScrollTo =
      element.current.getBoundingClientRect().top + window.scrollY;

    window.scroll({
      top: elementToScrollTo,
      behavior: 'smooth',
    });
  };

  render() {
    const { howItWorks, contact, opportunities, pricing } = this.props;

    return (
      <div className={classes.navContainer}>
        <div className={classes.contactNumber}>
          <a href={`tel:${PHONE_NUMBER_TEL}`}>
            <span>
              <PhoneOutlined />
            </span>
            {PHONE_NUMBER}
          </a>
        </div>
        <ul className={classes.navUl}>
          <li
            className={classes.navEl}
            onClick={this.handleMenuItemClick(howItWorks)}
          >
            Як це працює?
          </li>
          <li
            className={classes.navEl}
            onClick={this.handleMenuItemClick(opportunities)}
          >
            Можливості
          </li>
          <li
            className={classes.navEl}
            onClick={this.handleMenuItemClick(pricing)}
          >
            Тарифи
          </li>
          <li
            className={classes.navEl}
            onClick={this.handleMenuItemClick(contact)}
          >
            <span>
              <PhoneOutlined />
            </span>
            {PHONE_NUMBER}
          </li>
        </ul>
      </div>
    );
  }
}

export default Navbar;
