export const INSTRUCTIONS = {
  PAYMENTS: {
    link:
      'https://docs.google.com/document/d/1gqLthkUpD5xoCXJ5Ko1PkS4L0lQAQ0ifJwGvmwJgEl8/edit',
    label: 'Підключення платіжної системи',
  },
  GOOGLE_MERCHANT: {
    link:
      'https://docs.google.com/document/d/1H1t-3QcQQbDkXQLPOgG2Z7gE4Fa9fkpTol-q4XrcAec/edit?usp=sharing',
    label: 'Підключення Google Merchant Center',
  },
  GOOGLE_TAG_MANAGER: {
    link:
      'https://docs.google.com/document/d/1KJGaJDBKE24QqCOkMb82P-gs-QZUq1AJ2UJ7FiXrJNM/edit?usp=sharing',
    label: 'Підключення Google Tag Manager',
  },
  CERTIFICATES: {
    link:
      'https://docs.google.com/document/d/1KY0JyN2hW4P59LZrS5tPkUpeDgz_vzMgGT7FUEzGvlg/edit?usp=sharing',
    label: 'Управління сертифікатами',
  },
  USERS: {
    link:
      'https://docs.google.com/document/d/1YOjXcubOgdHxP8sJLXFKnqrl5zOtU06j9f9xO91p2Uc/edit?usp=sharing',
    label: 'Додавання і керування користувачами',
  },
};
