import React, { Component } from 'react';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import { Form, Field } from 'redux-form';
import { TextField, TextAreaField } from 'redux-form-antd';
import { Button, Tooltip, Modal, Input } from 'antd';
import { CopyOutlined, EyeOutlined, LinkOutlined } from '@ant-design/icons';
import * as classes from './PageSettings.module.scss';
import {
  minLength,
  maxLength,
  required,
  phone,
  email,
  link,
} from '../../../redux/validators';
import { copyToClipboard, getFullUrl } from '../../../utils';
import CheckboxField from '../CertificateTiers/Checkbox';
import Toggle from './Toggle';
import { ROUTES } from '../../../constants/routes';
import { PAGE_SETTINGS } from '../../../redux/forms/names';

const minTextarea = minLength(10);
const minField = minLength(3);
const maxField = maxLength(25);
const maxAbout = maxLength(1200);
const minSeoLength = minLength(5);
const maxSeoLength = maxLength(200);
const maxDescription = maxLength(500);

class PageSettings extends Component {
  state = {
    open: false,
    slug: null,
    error: null,
  };
  debouncedCheckSlug = debounce(this.props.checkSlug, 1000);

  componentDidUpdate(prevProps) {
    const { pageSettingsValues } = this.props;

    if (prevProps.pageSettingsValues.slug !== pageSettingsValues.slug) {
      this.setState({ slug: pageSettingsValues.slug });
    }
  }

  handlePreviewClick = () => {
    const { previewCampaign, setPreview } = this.props;

    setPreview(previewCampaign);
  };

  handleCopyUrlClick = () => {
    const { url } = this.props;

    copyToClipboard(url);
  };

  handleSlugSettingsClick = () => {
    const { open } = this.state;
    const { slug, resetSlugError } = this.props;

    this.setState({ open: !open });

    if (open) {
      resetSlugError();
      this.setState({ slug });
    }
  };

  handleSlugSubmit = () => {
    const { changeField } = this.props;

    this.handleSlugSettingsClick();
    changeField(PAGE_SETTINGS, 'slug', this.state.slug);
  };

  handleSlugCancel = () => {
    const { changeField, currentCampaign } = this.props;

    this.handleSlugSettingsClick();
    changeField(PAGE_SETTINGS, 'slug', currentCampaign.slug || '');
  };

  handleSlugChange = e => {
    const { value } = e.target;
    const { currentCampaign, resetSlugError } = this.props;

    this.setState({ slug: value });

    if (currentCampaign.id === value || currentCampaign.slug === value) {
      resetSlugError();
    }

    const error = this.validateSlug(currentCampaign, value);

    this.setState({ error });

    if (!error) {
      this.debouncedCheckSlug(currentCampaign.id, value);
    }
  };

  getMainUrlPart = () => {
    const { url } = this.props;

    if (!url) {
      return null;
    }

    return getFullUrl(ROUTES.USER_LANDING.replace(':id', ''));
  };

  validateSlug = (campaign, value) => {
    const availableSymbols =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    const isValueHasForbiddenSymbols = String(value)
      .split('')
      .some(symbol => !availableSymbols.includes(symbol));

    if (String(value).trim().length < 3) {
      return 'Мінімально 3 символів';
    }

    if (String(value).trim().length > 50) {
      return 'Максимально 50 символів';
    }

    return String(value).trim().length < 3 || isValueHasForbiddenSymbols
      ? 'URL адреса має невірний формат'
      : null;
  };

  render() {
    const { open, slug, error } = this.state;
    const { previewUrl, isPreviewDisabled, slugError } = this.props;

    return (
      <Form className={classes.container} preserve={false}>
        <div className={classes.block}>
          <span className={classes.title}>Сторінка</span>
          <div className={classes.row}>
            <div className={classes.social}>
              <div className={classes.links}>
                <div
                  className={classnames(classes.preview, {
                    [classes.disabled]: isPreviewDisabled,
                  })}
                >
                  <Tooltip placement="top" title="Натисніть, щоб скопівати">
                    <span
                      className={classes.span}
                      onClick={this.handleCopyUrlClick}
                    >
                      <CopyOutlined className={classes.icon} />
                      Посилання
                    </span>
                  </Tooltip>
                  <div onClick={this.handlePreviewClick}>
                    <Link to={previewUrl} target="_blank">
                      <Button disabled={isPreviewDisabled}>
                        <EyeOutlined className={classes.icon} />
                        Попередній перегляд
                      </Button>
                    </Link>
                  </div>
                </div>
                <Field
                  placeholder="Instagram"
                  type="text"
                  name="instagram"
                  validate={[link]}
                  component={TextField}
                />
                <Field
                  placeholder="Facebook"
                  type="text"
                  name="facebook"
                  validate={[link]}
                  component={TextField}
                />
                <Field
                  placeholder="Telegram"
                  type="text"
                  name="telegram"
                  validate={[link]}
                  component={TextField}
                />
              </div>
            </div>
            <div className={classes.social}>
              <div className={classes.links}>
                {slug && (
                  <Button
                    className={classes.urlButton}
                    onClick={this.handleSlugSettingsClick}
                  >
                    <LinkOutlined className={classes.icon} />
                    Налаштування url
                  </Button>
                )}
                <div className={classes.hidden}>
                  <Field
                    placeholder="Url адреса"
                    type="text"
                    name="slug"
                    component={TextField}
                  />
                </div>
                <Field
                  placeholder="Адреса"
                  type="text"
                  name="address"
                  component={TextField}
                />
                <Field
                  placeholder="Номер телефону *"
                  type="text"
                  name="phone"
                  validate={[required, phone]}
                  component={TextField}
                />
                <Field
                  placeholder="Електронна пошта *"
                  type="text"
                  name="email"
                  validate={[required, email]}
                  component={TextField}
                />
              </div>
            </div>
          </div>
          <span className={classes.span}>Інформація про бренд</span>
          <div className={classes.row}>
            <div className={classes.social}>
              <div className={classes.links}>
                <Field
                  placeholder="Назва компанії"
                  type="text"
                  name="brandName"
                  component={TextField}
                />
              </div>
            </div>
            <div className={classes.social}>
              <div className={classes.links}>
                <Field
                  placeholder="Веб-сторінка компанії"
                  type="text"
                  name="brandUrl"
                  validate={[link]}
                  component={TextField}
                />
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <Field
              placeholder="Про компанію *"
              type="text"
              name="about"
              rows={4}
              className={classes.textarea}
              validate={[required, minTextarea, maxAbout]}
              component={TextAreaField}
            />
          </div>
          <span className={classes.span}>Форма оплати</span>
          <div className={classes.row}>
            <div className={classes.social}>
              <span className={classes.notification}>
                (Це додаткове поле для форми оплати)
              </span>
              <div className={classes.links}>
                <Field
                  placeholder="Назва поля"
                  type="text"
                  name="fieldLabel"
                  validate={[minField, maxField]}
                  component={TextField}
                />
                <Field
                  hasFeedback={false}
                  name="isFieldRequired"
                  id="isFieldRequired"
                  component={CheckboxField}
                  label="Поле обов'язкове"
                />
              </div>
            </div>
          </div>
          <span className={classes.title}>SEO</span>
          <div>
            <span className={classes.span}>Google Tag Manager</span>
            <div className={classes.row}>
              <div className={classes.links}>
                <Field
                  placeholder="Вставте код Google Tag Manager, який для початкового тегу <head> сторінки"
                  type="text"
                  name="tagManagerHead"
                  rows={3}
                  className={classes.textarea}
                  validate={[minTextarea]}
                  component={TextAreaField}
                />
                <Field
                  placeholder="Вставте код Google Tag Manager, який для початкового тегу <body> сторінки"
                  type="text"
                  name="tagManagerBody"
                  rows={3}
                  className={classes.textarea}
                  validate={[minTextarea]}
                  component={TextAreaField}
                />
              </div>
            </div>
            <span className={classes.span}>Google Merchant Center</span>
            <div className={classes.row}>
              <div className={classes.links}>
                <Field
                  placeholder="Вставте код Google Merchant Center, для ідентифікації вашої сторінки"
                  type="text"
                  name="googleMerchantCenter"
                  rows={3}
                  className={classes.textarea}
                  validate={[minTextarea]}
                  component={TextAreaField}
                />
              </div>
            </div>
            <span className={classes.span}>Тег Title</span>
            <div className={classes.row}>
              <div className={classes.links}>
                <Field
                  placeholder="Вставте текст для початкового тегу <title> сторінки "
                  type="text"
                  name="seoTitle"
                  rows={3}
                  className={classes.textarea}
                  validate={[minSeoLength, maxSeoLength]}
                  component={TextAreaField}
                />
              </div>
            </div>
          </div>
          <span className={classes.span}>Тег Description</span>
          <div className={classes.row}>
            <div className={classes.links}>
              <Field
                placeholder="Вставте текст для початкового тегу <description> сторінки "
                type="text"
                name="seoDescription"
                rows={3}
                className={classes.textarea}
                validate={[minSeoLength, maxDescription]}
                component={TextAreaField}
              />
            </div>
          </div>
          <div className={classes.row}>
            <Field
              type="checkbox"
              name="newDesign"
              id="newDesign"
              component={Toggle}
              label="Новий дизайн"
            />
          </div>
        </div>
        <Modal
          destroyOnClose={true}
          title="Налаштувати url адресу сторінки"
          visible={open}
          onOk={this.handleSlugSubmit}
          onCancel={this.handleSlugSettingsClick}
          okText="Підтвердити"
          cancelText="Скасувати"
          okButtonProps={{ disabled: Boolean(slugError || error) }}
        >
          <div className={classes.urlWrapper}>
            <span className={classes.urlPlaceholder}>
              {this.getMainUrlPart()}
            </span>
            <Input
              className={classes.urlInput}
              value={slug}
              onChange={this.handleSlugChange}
            />
            {(slugError || error) && (
              <span className={classes.error}>{slugError || error}</span>
            )}
          </div>
        </Modal>
      </Form>
    );
  }
}

export default PageSettings;
