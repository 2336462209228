import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import AdminCampaignPage from './AdminCampaignPage';

import {
  loading,
  isCreateCampaignDisabled,
  campaignToSave,
  submitName,
  currentCampaign,
} from '../../redux/campaign/selectors';
import { create, update, getCampaigns } from '../../redux/campaign/actions';
import { userId } from '../../redux/profile/selectors';

const selector = createStructuredSelector({
  loading,
  values: campaignToSave,
  disabled: isCreateCampaignDisabled,
  submitName,
  currentCampaign,
  userId,
});
const actions = {
  create,
  update,
  init: getCampaigns,
};

export default connect(selector, actions)(AdminCampaignPage);
