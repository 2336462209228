import React, { Component } from 'react';
import { Form, Button, Input, Switch } from 'antd';
import styles from './EditForm.module.scss';

const validationRules = {
  firstName: [{ required: true, message: "Будь ласка, введіть ваше ім'я" }],
  lastName: [{ required: true, message: 'Будь ласка, введіть ваше прізвище' }],
  email: [
    { required: true, message: 'Будь ласка, введіть вашу електронну пошту' },
    { type: 'email', message: 'Будь ласка, введіть правильний email' },
  ],
  phone: [
    { required: true, message: 'Будь ласка, введіть ваш телефон' },
    {
      min: 10,
      max: 13,
      message: 'Будь ласка, введіть правильний телефон',
    },
  ],
  company: [{ required: true, message: 'Будь ласка, введіть назву компанії' }],
};

class EditForm extends Component {
  onFinish = values => {
    const { onSubmit, initialValues = {} } = this.props;
    const { switch: isPaymentActive, ...rest } = values;

    onSubmit({
      ...rest,
      isPaymentBlocked: !isPaymentActive,
      id: initialValues.id,
    });
  };

  onFinishFailed = () => {};

  render() {
    const { loading, initialValues } = this.props;

    return (
      <Form
        initialValues={initialValues}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
      >
        <Form.Item
          type="text"
          name="firstName"
          label="Ім'я"
          className={styles.input}
          rules={validationRules.firstName}
          validateTrigger={['onBlur', 'onChange']}
        >
          <Input />
        </Form.Item>
        <Form.Item
          type="text"
          name="lastName"
          label="Прізвище"
          className={styles.input}
          rules={validationRules.lastName}
          validateTrigger={['onBlur', 'onChange']}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          type="text"
          label="Електронна пошта"
          className={styles.input}
          rules={validationRules.email}
          validateTrigger={['onBlur', 'onChange']}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          type="text"
          label="Телефон"
          className={styles.input}
          rules={validationRules.phone}
          validateTrigger={['onBlur', 'onChange']}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="company"
          type="text"
          label="Назва компанії"
          className={styles.input}
          rules={validationRules.company}
          validateTrigger={['onBlur', 'onChange']}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="switch"
          label="Статус платежів"
          valuePropName="checked"
          className={styles.input}
        >
          <Switch
            checkedChildren="Призупинити"
            unCheckedChildren="Активовувати"
          />
        </Form.Item>
        <Form.Item className={styles.button}>
          <Button disabled={loading} htmlType="submit" type="primary">
            Зберегти зміни
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default EditForm;
