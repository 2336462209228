import React from 'react';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { INSTRUCTIONS } from '../../constants/instructions';
import { PHONE_NUMBER, PHONE_NUMBER_TEL } from '../../constants/texts';
import classes from './HelpPage.module.scss';

class HelpPage extends React.Component {
  render() {
    // const { requestChat } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.column}>
          <FontAwesomeIcon className={classes.icon} icon={faQuestionCircle} />
          <h3 className={classes.title}>Допомога</h3>
        </div>
        <p className={classes.text}>
          Ми завжди відповідаємо протягом 24 годин після отримання вашого листа.
        </p>
        <p className={classes.text}>
          Ви також можете нам зателефонувати:
          <a href={`tel: ${PHONE_NUMBER_TEL}`}>{PHONE_NUMBER}</a>
        </p>
        {/*<div className={classes.row}>*/}
        {/*  <Button onClick={requestChat} type="primary">*/}
        {/*    Живий чат*/}
        {/*  </Button>*/}
        {/*</div>*/}
        <div>
          <h3 className={classes.title}>Інструкції</h3>
          {Object.values(INSTRUCTIONS).map(({ label, link }) => (
            <div key={link} className={classes.list}>
              <a href={link} rel="noopener noreferrer" target="_blank">
                {label}
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default HelpPage;
