import Base from './base';

export default class Users extends Base {
  list(query) {
    return this.apiClient.get('users', query);
  }

  show(id) {
    return this.apiClient.get(`users/${id}`);
  }

  create(data) {
    return this.apiClient.post(`admin/user`, data);
  }

  update(data) {
    return this.apiClient.put(`admin/user`, data);
  }

  delete(id) {
    return this.apiClient.delete(`admin/user`, id);
  }

  liqpayAccountCreate() {
    return this.apiClient.get(`profile/payment-create`);
  }

  homepageContact(data) {
    return this.apiClient.post(`homepage/contact`, data);
  }

  updateProfile(data) {
    return this.apiClient.put(`users/`, data);
  }

  createByCampaignAdmin(data) {
    return this.apiClient.post(`campaigns/user`, data);
  }

  updateByCampaignAdmin(data) {
    return this.apiClient.put(`campaigns/user`, data);
  }
}
