import React, { Component } from 'react';
import { Form, Button, Input, Checkbox } from 'antd';
import { USER_LANDING } from '../../../constants/newLandingTexts';
import { CERTIFICATE_TYPES, ELEMENTS_IDS } from '../../../constants/labels';
import { validationRules } from '../../../helpers/verifying';
import { priceWithDecimal } from '../../../helpers/stringFormatters';
import Quantity from '../Quantity';
import Select from '../Select';
import { getFontColor } from '../../../utils';
import styles from '../NewCertificateBuyPage.module.scss';

const { TextArea } = Input;

export default class BuyForm extends Component {
  formRef = React.createRef();
  state = {
    quantity: 1,
    certificateType: CERTIFICATE_TYPES.SELF,
    acceptTerms: false,
  };

  changeCertificateType = value => {
    this.setState({ certificateType: value });
  };

  // changeAcceptTermsType = () => {
  //   this.setState({ acceptTerms: !this.state.acceptTerms });
  //   this.formRef.current.setFieldsValue({
  //     acceptTerms: !this.state.acceptTerms,
  //   });
  // };

  addQuantity = () => {
    this.setState(prev => ({ quantity: prev.quantity + 1 }));
  };

  subtractQuantity = () => {
    if (this.state.quantity <= 1) {
      return;
    }

    this.setState(prev => ({ quantity: prev.quantity - 1 }));
  };

  onFinish = values => {
    values.quantity = this.state.quantity;
    const { onSubmit, certificateId } = this.props;
    onSubmit({ ...values, certificateId });
  };

  getInitialValues = () => {
    const { quantity } = this.state;

    return {
      quantity,
      certificateType: CERTIFICATE_TYPES.SELF,
      acceptTerms: false,
    };
  };

  getPrice = () => {
    const { quantity } = this.state;
    const { price } = this.props;

    return `${priceWithDecimal(quantity * price)} ₴`;
  };

  render() {
    const { certificateType, quantity } = this.state;
    const { color, language, backgroundColor, primaryColor } = this.props;
    const buttonColor = getFontColor(primaryColor).fontColor;
    const fontColor = getFontColor(backgroundColor).fontColor;

    return (
      <Form
        id={ELEMENTS_IDS.BUY_FORM}
        ref={this.formRef}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        size="large"
        className={styles.buyForm}
        initialValues={this.getInitialValues()}
      >
        <h3 className={styles.formTitle}>
          {USER_LANDING.BUY_FORM.ORDER_FORM[language]}
        </h3>
        <Form.Item
          name="firstName"
          rules={validationRules(language).firstName}
          validateTrigger="onBlur"
        >
          <Input
            style={{ '--color': color }}
            className={styles.input}
            placeholder={USER_LANDING.CONTACT_FORM.NAME[language]}
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={validationRules(language).lastName}
          validateTrigger="onBlur"
        >
          <Input
            style={{ '--color': color }}
            className={styles.input}
            placeholder={USER_LANDING.CONTACT_FORM.LAST_NAME[language]}
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={validationRules(language).email}
          validateTrigger="onBlur"
        >
          <Input
            style={{ '--color': color }}
            className={styles.input}
            placeholder={USER_LANDING.CONTACT_FORM.EMAIL[language]}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={validationRules(language).phone}
          validateTrigger="onBlur"
        >
          <Input
            style={{ '--color': color }}
            className={styles.input}
            placeholder={USER_LANDING.CONTACT_FORM.PHONE[language]}
          />
        </Form.Item>
        <div className={styles.certificateOptions}>
          <div className={styles.certificateType}>
            <span className={styles.optionsLabel}>
              {USER_LANDING.CONTACT_FORM.RECIPIENT_LABEL[language]}
            </span>
            <Form.Item name="certificateType" validateTrigger="onBlur" required>
              <Select
                color={fontColor}
                backgroundColor={backgroundColor}
                primaryColor={primaryColor}
                language={language}
                value={certificateType}
                onChange={this.changeCertificateType}
              />
            </Form.Item>
          </div>
          <div className={styles.certificateQuantity}>
            <span className={styles.quantityLabel}>
              {USER_LANDING.CONTACT_FORM.COUNT[language]}
            </span>
            <Form.Item name="quantity" validateTrigger="onBlur" required>
              <Quantity
                quantity={quantity}
                add={this.addQuantity}
                subtract={this.subtractQuantity}
              />
            </Form.Item>
          </div>
        </div>
        {certificateType === CERTIFICATE_TYPES.GIFT && (
          <Form.Item
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.certificateType !== currentValues.certificateType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('certificateType') === CERTIFICATE_TYPES.GIFT && (
                <div>
                  <Form.Item
                    name="recipientEmail"
                    rules={validationRules(language).recipientEmail}
                    validateTrigger="onBlur"
                  >
                    <Input
                      style={{ '--color': color }}
                      className={styles.input}
                      placeholder={
                        USER_LANDING.CONTACT_FORM.RECIPIENT_EMAIL[language]
                      }
                    />
                  </Form.Item>
                  <Form.Item name="message">
                    <TextArea
                      style={{ '--color': color }}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      className={styles.input}
                      placeholder={USER_LANDING.CONTACT_FORM.MESSAGE[language]}
                    />
                  </Form.Item>
                </div>
              )
            }
          </Form.Item>
        )}

        <div className={styles.formFooter}>
          <Form.Item
            name="acceptTerms"
            valuePropName="checked"
            rules={validationRules(language).acceptTerms}
            validateTrigger={['onSubmit', 'onBlur', 'onChange']}
            className={styles.rules}
          >
            <Checkbox
              className={styles.agreement}
              style={{
                '--background-color': color,
                '--border-color': color,
              }}
            >
              <p
                className={styles.rule}
                dangerouslySetInnerHTML={{
                  __html: USER_LANDING.CONTACT_FORM.TERMS(color)[language],
                }}
              />
            </Checkbox>
          </Form.Item>
          <div className={styles.total} style={{ color }}>
            <span className={styles.price}>
              {USER_LANDING.BUY_FORM.TOTAL[language]}:
            </span>
            <p>{this.getPrice()}</p>
          </div>
        </div>

        <Form.Item>
          <Button
            className={styles.button}
            htmlType="submit"
            style={{ backgroundColor: color, color: buttonColor }}
          >
            {USER_LANDING.BUY_FORM.SEND[language]}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
