import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  certificateData,
  campaignData,
  paymentIntentionData,
} from '../../redux/landing/selectors';
import {
  successPaymentData,
  paymentDataSave,
  showSuccessPaymentScreen,
} from '../../redux/landing/actions';

import NewPaymentCertificatePage from './NewPaymentCertificatePage';

const selector = createStructuredSelector({
  campaignData,
  certificateData,
  paymentIntentionData,
});

const actions = {
  showSuccessPaymentScreen,
  successPaymentData,
  paymentDataSave,
};

export { NewPaymentCertificatePage };

export default connect(selector, actions)(NewPaymentCertificatePage);
