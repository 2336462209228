import React, { Component } from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import styles from './BalancePage.module.scss';
import { ROUTES } from '../../constants/routes';
import InfoBlock, { ICON_TYPES } from '../../components/Landing/InfoBlock';
import { USER_LANDING } from '../../constants/texts';
import { formatPrice } from '../../helpers/stringFormatters';
import { getFontColor } from '../../utils';

class BalancePage extends Component {
  componentDidMount() {
    const {
      match: {
        params: { certificateId },
      },
      getBalance,
    } = this.props;

    if (certificateId) {
      getBalance(certificateId);
    }
  }

  render() {
    const {
      balance,
      history,
      loading,
      campaignData: {
        brandLogo,
        language,
        identifier,
        name,
        backgroundColor,
        primaryColor,
      },
    } = this.props;

    if (!loading && !balance) {
      history.push(ROUTES.HOME);

      return null;
    }

    const { isFontWhite } = getFontColor(backgroundColor);

    return (
      <div className={styles.page} style={{ backgroundColor }}>
        <InfoBlock
          backgroundColor={backgroundColor}
          campaignId={identifier}
          logo={brandLogo}
          heading={name}
          type={ICON_TYPES.WALLET}
          isIconAdded={false}
          translation={[]}
        />
        <section className={styles.block}>
          <h4
            className={classnames(styles.title, {
              [styles.white]: isFontWhite,
            })}
          >
            {USER_LANDING.CERTIFICATE_BALANCE[language]} {balance.code}
          </h4>

          <div className={styles.row}>
            <span style={{ color: primaryColor }} className={styles.amount}>
              {formatPrice(balance.amount, language)}
            </span>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(BalancePage);
