import React, { Component } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Table as AntTable, Pagination, Tooltip, Modal, Button } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  CopyOutlined,
} from '@ant-design/icons';

import * as classes from './Table.module.scss';
import { ROUTES } from '../../../constants/routes';
import { getCampaignUrl } from '../../../utils';

const STATUSES = {
  ACTIVE: 'ACTIVE',
  NOT_ACTIVE: 'NOT_ACTIVE',
};
const CONFIRMATION_TYPES = {
  DELETE: 'DELETE',
  PUBLISH: 'PUBLISH',
  UNPUBLISH: 'UNPUBLISH',
  NO_PAYMENT_ACCOUNT: 'NO_PAYMENT_ACCOUNT',
  FIRST_COMPANY: 'FIRST_COMPANY',
};

class Table extends Component {
  state = {
    isOpen: false,
    selectedCampaign: {},
    type: null,
  };
  componentDidMount() {
    const { userId, getCampaigns, clearSelectedCampaign } = this.props;
    clearSelectedCampaign();
    getCampaigns(userId, 1);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      isFirstCampaignModalShown,
      isPaymentCreated,
      campaigns,
    } = this.props;

    if (
      !isPaymentCreated &&
      isFirstCampaignModalShown &&
      campaigns.length === 1 &&
      prevProps.campaigns.length === 0
    ) {
      this.handleModalClick({}, CONFIRMATION_TYPES.FIRST_COMPANY)();
    }
  }

  handlePageClick = page => {
    const { userId, changePage, getCampaigns } = this.props;

    changePage(page);
    getCampaigns(userId, page);
  };

  getContentByType = type => {
    const { selectedCampaign } = this.state;

    switch (type) {
      case CONFIRMATION_TYPES.DELETE: {
        return {
          action: this.handleCampaignDelete,
          header: 'Видалення кампанії',
          text: `Ви впевнені, що хочете видалити ${selectedCampaign.name} кампанію?`,
          isFooterHidden: false,
        };
      }

      case CONFIRMATION_TYPES.PUBLISH: {
        return {
          action: this.handleCampaignPublish,
          header: 'Публікація кампанію',
          text: `Ви впевнені, що хочете опублікувати ${selectedCampaign.name} кампанію?`,
          isFooterHidden: false,
        };
      }

      case CONFIRMATION_TYPES.UNPUBLISH: {
        return {
          action: this.handleCampaignUnpublish,
          header: 'Сховати кампанію',
          text: `Ви впевнені, що хочете сховати ${selectedCampaign.name} кампанію?`,
          isFooterHidden: false,
        };
      }

      case CONFIRMATION_TYPES.FIRST_COMPANY: {
        return {
          action: this.handleModalClick(),
          header: 'Вітаємо!',
          text: (
            <span>
              Ви створили першу кампанію! Наступним кроком має бути створення
              профілю в платіжній системі. Перейдіть в розділ{' '}
              <Link to={ROUTES.BILLING_SETTINGS}>Платіжних налаштувань</Link>
            </span>
          ),
          isFooterHidden: true,
        };
      }

      case CONFIRMATION_TYPES.NO_PAYMENT_ACCOUNT: {
        return {
          action: this.handleModalClick(),
          header: 'Створіть платіжний профіль',
          isFooterHidden: true,
          text: (
            <div className={classes.createPaymentModal}>
              <p>
                Для публікації кампаній необхідно створити профіль в платіжній
                системі
              </p>
              <Button type="primary">
                <Link to={ROUTES.LIQPAY_ACCOUNT_CREATE}>
                  Створити Liqpay профіль
                </Link>
              </Button>
            </div>
          ),
        };
      }

      default:
        return {};
    }
  };

  handleCampaignPublish = async () => {
    const { selectedCampaign } = this.state;
    const { changeStatus } = this.props;

    await changeStatus(selectedCampaign.id, STATUSES.ACTIVE);
    this.handleModalClick()();
  };

  handleCampaignUnpublish = async () => {
    const { selectedCampaign } = this.state;
    const { changeStatus } = this.props;

    await changeStatus(selectedCampaign.id, STATUSES.NOT_ACTIVE);
    this.handleModalClick()();
  };

  handleCampaignDelete = async () => {
    const { selectedCampaign } = this.state;
    const { page, userId, deleteCampaign, getCampaigns } = this.props;

    await deleteCampaign(selectedCampaign.id);
    getCampaigns(userId, page);
    this.handleModalClick()();
  };

  handleCampaignEdit = id => () => {
    const { history } = this.props;

    history.push(ROUTES.EDIT_CAMPAIGN.replace(':id', id));
  };

  handleCampaignDuplicate = id => () => {
    const { history, selectCampaign } = this.props;
    selectCampaign(id);
    history.push(ROUTES.CREATE_CAMPAIGN);
  };
  handleModalClick = (campaign = {}, type) => () => {
    const { isPaymentCreated } = this.props;
    if (type === CONFIRMATION_TYPES.PUBLISH && !isPaymentCreated) {
      return this.setState({
        isOpen: !this.state.isOpen,
        selectedCampaign: campaign,
        type: CONFIRMATION_TYPES.NO_PAYMENT_ACCOUNT,
      });
    }

    this.setState({
      isOpen: !this.state.isOpen,
      selectedCampaign: campaign,
      type,
    });
  };

  render() {
    const { isOpen, type } = this.state;
    const { campaigns, page, total, userId } = this.props;

    return (
      <div className={classes.container}>
        <AntTable
          rowKey="uid"
          className={classnames({
            [classes.withData]: Boolean(campaigns.length),
          })}
          locale={{
            emptyText: (
              <Button className={classes.withoutData}>
                <Link to={ROUTES.CREATE_CAMPAIGN}>
                  Почати продаж сертифікатів!
                </Link>
              </Button>
            ),
          }}
          columns={[
            {
              title: 'Назва кампанії',
              dataIndex: 'name',
              key: 'name',
              render: (text, record) => (
                <span
                  className={classes.link}
                  onClick={this.handleCampaignEdit(record.id)}
                >
                  {text}
                </span>
              ),
            },
            {
              title: 'Статус',
              dataIndex: 'status',
              key: 'status',
            },
            {
              title: 'Адреса',
              dataIndex: 'url',
              key: 'url',
              render: (text, record) => (
                <a
                  className={classes.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={getCampaignUrl(record.identifier)}
                >
                  {getCampaignUrl(record.identifier)}
                </a>
              ),
            },
            {
              title: 'Аналітика',
              dataIndex: 'detections',
              key: 'detections',
              render: detections => (
                <span>
                  {detections.map(item => (
                    <span className={classes.detection} key={item}>
                      {item}
                    </span>
                  ))}
                </span>
              ),
            },
            {
              title: 'Дата створення',
              dataIndex: 'createdAt',
              key: 'createdAt',
            },
            {
              title: null,
              key: 'action',
              render: (text, record) => (
                <div className={classes.buttons}>
                  {record.type === STATUSES.ACTIVE && (
                    <Tooltip
                      placement="topRight"
                      title={`Сховати ${record.name} кампанію`}
                    >
                      <Button
                        className={classes.secondary}
                        onClick={this.handleModalClick(
                          record,
                          CONFIRMATION_TYPES.UNPUBLISH,
                        )}
                      >
                        <EyeInvisibleOutlined />
                      </Button>
                    </Tooltip>
                  )}
                  {record.type === STATUSES.NOT_ACTIVE && (
                    <Tooltip
                      placement="topRight"
                      title={`Опублікувати ${record.name} кампанію`}
                    >
                      <Button
                        className={classes.secondary}
                        onClick={this.handleModalClick(
                          record,
                          CONFIRMATION_TYPES.PUBLISH,
                        )}
                      >
                        <EyeOutlined />
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip
                    placement="topRight"
                    title={`Редагувати ${record.name} кампанію`}
                  >
                    <Button
                      className={classes.edit}
                      onClick={this.handleCampaignEdit(record.id)}
                    >
                      <EditOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    placement="topRight"
                    title={`Копіювати ${record.name} кампанію`}
                  >
                    <Button
                      className={classes.duplicate}
                      onClick={this.handleCampaignDuplicate(record.id)}
                    >
                      <CopyOutlined />
                    </Button>
                  </Tooltip>
                  {userId === record.userId && (
                    <Tooltip
                      placement="topRight"
                      title={`Видалити ${record.name} кампанію`}
                    >
                      <Button
                        className={classes.remove}
                        onClick={this.handleModalClick(
                          record,
                          CONFIRMATION_TYPES.DELETE,
                        )}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              ),
            },
          ]}
          size="small"
          dataSource={campaigns}
          pagination={false}
        />
        <Pagination
          className={classes.pagination}
          onChange={this.handlePageClick}
          pageSize={10}
          defaultCurrent={1}
          total={total}
          current={page}
        />
        <Modal
          destroyOnClose
          title={this.getContentByType(type).header}
          centered
          okText="Підтвердити"
          cancelText="Скасувати"
          visible={isOpen}
          onOk={this.getContentByType(type).action}
          onCancel={this.handleModalClick()}
          okButtonProps={{
            style: {
              display: this.getContentByType(type).isFooterHidden
                ? 'none'
                : 'inline-block',
            },
          }}
          cancelButtonProps={{
            style: {
              display: this.getContentByType(type).isFooterHidden
                ? 'none'
                : 'inline-block',
            },
          }}
        >
          <span>{this.getContentByType(type).text}</span>
        </Modal>
      </div>
    );
  }
}

export default Table;
