import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import classes from './HomePage.module.scss';
import { ROUTES } from '../../constants/routes';
import mainHeroWave from '../../assets/images/mainHeroWave.svg';
import waveMiddle from '../../assets/images/waveMiddle.svg';
import waveBottom from '../../assets/images/waveBottom.svg';
import homeCheck from '../../assets/images/homeCheck.svg';
import homeBox from '../../assets/images/homeBox.svg';
import commissionHand from '../../assets/images/commissionsHand.svg';
import benefitCloudRight from '../../assets/images/benefitCloudRight.svg';
import benefitCloudLeft from '../../assets/images/benefitCloudLeft.svg';
import moneyWorld from '../../assets/images/moneyWorld.svg';
import content from './content';
import logo from '../../assets/images/logo_transparent.png';
import ContactForm from './components/ContactForm';
import Navbar from './components/Navbar';
import Hamburger from '../../components/Hamburger/Hamburger';

const {
  heroContent,
  ourGoal,
  howItWorks,
  opportunities,
  platform,
  ourClients,
  contact,
  pricing,
  benefits,
} = content;

export default class HomePage extends Component {
  constructor() {
    super();
    this.howItWorksRef = createRef();
    this.contactRef = createRef();
    this.pricingRef = createRef();
    this.opportunitiesRef = createRef();
  }
  render() {
    const { submitContactForm, isAuthenticated } = this.props;
    const { howItWorksRef, pricingRef, opportunitiesRef, contactRef } = this;

    return (
      <div className={classes.home}>
        <header className={classes.header}>
          <Link to={ROUTES.HOME} className={classes.logo}>
            <img src={logo} alt="Upfront" />
          </Link>
          <Navbar
            isAuthenticated={isAuthenticated}
            howItWorks={howItWorksRef}
            contact={contactRef}
            pricing={pricingRef}
            opportunities={opportunitiesRef}
          />
          <Hamburger
            isHomePage
            howItWorks={howItWorksRef}
            contact={contactRef}
            pricing={pricingRef}
            opportunities={opportunitiesRef}
            isAuthenticated={isAuthenticated}
          />
          {isAuthenticated ? (
            <div>
              <Link to={ROUTES.CAMPAIGNS}>
                <button className={classes.buttonSmall}>
                  Особистий кабінет
                </button>
              </Link>
            </div>
          ) : (
            <div className={classes.userButtons}>
              <Link to={ROUTES.LOGIN}>
                <button className={classes.buttonSmallSecondary}>Вхід</button>
              </Link>
              <Link to={ROUTES.SIGNUP}>
                <button className={classes.buttonSmall}>Реєстрація</button>
              </Link>
            </div>
          )}
        </header>
        <div className={classes.hero}>
          <div className={classes.heroContent}>
            <h1 className={classes.mainHeading}>{heroContent.mainHeading}</h1>
            <p className={classes.subHeading}>{heroContent.subHeading}</p>

            <div>
              {isAuthenticated ? (
                <Link to={ROUTES.CREATE_CAMPAIGN}>
                  <button className={classes.button}>
                    {heroContent.createButtonLabel}
                  </button>
                </Link>
              ) : (
                <Link to={ROUTES.SIGNUP}>
                  <button className={classes.button}>
                    {heroContent.buttonLabel}
                  </button>
                </Link>
              )}

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://upfront.com.ua/campaign/5efb41576366b92f8fa7e768"
              >
                <button className={classes.buttonSecondary}>Демо</button>
              </a>
            </div>
          </div>
          <div className={classes.heroImage}>
            <img className={classes.waveImage} src={mainHeroWave} alt="wave" />
            <img className={classes.homeBox} src={homeBox} alt="Upfront box" />
          </div>
        </div>
        <section className={classes.benefits}>
          <div className={classes.title}>{benefits.title}</div>
          <img
            className={classes.benefitCloudRight}
            src={benefitCloudRight}
            alt="cloud"
          />
          <img
            className={classes.benefitCloudLeft}
            src={benefitCloudLeft}
            alt="cloud"
          />
          <ul>
            {benefits.list.map(benefit => (
              <div className={classes.benefitItem} key={benefit}>
                <img className={classes.check} src={homeCheck} alt="check" />
                <p>{benefit}</p>
              </div>
            ))}
          </ul>
          <div className={classes.ourGoalImage}>
            <img src={ourGoal.image} alt="Rocket" />
          </div>
        </section>
        <section className={classes.opportunities} ref={opportunitiesRef}>
          <div className={classes.title}>{opportunities.title}</div>
          <div className={classes.wrapper}>
            <div className={classes.image}>
              <img src={opportunities.image} alt="Opportunities" />
            </div>
            <div className={classes.content}>
              {opportunities.list.map(item => (
                <div className={classes.listItem} key={item.text}>
                  <img className={classes.check} src={homeCheck} alt="check" />
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className={classes.howItWorks} ref={howItWorksRef}>
          <div className={classes.title}>{howItWorks.title}</div>
          {howItWorks.steps.map(item => (
            <div className={classes.step} key={item.text}>
              <div className={classes.image}>
                <img src={item.image} alt="How it work step" />
              </div>
              <div className={classes.content}>
                <div className={classes.number}>{item.number}</div>
                <div className={classes.info}>
                  <h3 className={classes.stepTitle}>{item.title}</h3>
                  <p className={classes.stepText}>{item.text}</p>
                </div>
              </div>
            </div>
          ))}
        </section>
        <section className={classes.platform}>
          <img className={classes.waveMiddle} src={waveMiddle} alt="wave" />
          <div className={classes.title}>{platform.title}</div>
          <div className={classes.wrapper}>
            {platform.list.map(item => (
              <div className={classes.card} key={item.text}>
                <img
                  className={classes.cardImage}
                  src={item.image}
                  alt="platform feature"
                />
                <p className={classes.cardText}>{item.text}</p>
              </div>
            ))}
          </div>
        </section>
        {/*<section className={classes.ourClients}>
          <div className={classes.title}>{ourClients.title}</div>
          <div className={classes.wrapper}>
            {ourClients.list.map(item => (
              <div className={classes.card}>
                <img src={item.image} alt="clients logos" />
              </div>
            ))}
          </div>
        </section>*/}
        <section className={classes.pricing} ref={pricingRef}>
          <div className={classes.circlesContainer}>
            <span className={classes.circle1} />
            <span className={classes.circle2} />
            <span className={classes.circle3} />
            <span className={classes.circle4} />
          </div>
          <div className={classes.pricingText}>
            <img
              className={classes.pricingLogo}
              src={logo}
              alt="Pricing logo"
            />
            <div className={classes.title}>{pricing.title}</div>
            <div className={classes.subHeading}>
              {`${pricing.terms} `} {/* to add space between sentences*/}
              <span className={classes.highlighted}>
                {pricing.highlightedTerms}
              </span>
            </div>
            <p className={classes.info}>{pricing.advantages}</p>
            <div className={classes.pricingButtons}>
              <div className={classes.pricingButtonsContainer}>
                <p className={classes.selfPricing}>
                  <span className={classes.pricingBadge}>
                    <img
                      src={commissionHand}
                      className={classes.commissionImg}
                      alt="Commission"
                    />
                    {pricing.selfCommission}
                  </span>
                </p>
              </div>
              <div className={classes.pricingButtonsContainer}>
                <p className={classes.selfPricing}>
                  <span className={classes.pricingBadge}>
                    <img
                      src={moneyWorld}
                      className={classes.moneyWorldImg}
                      alt="Commission"
                    />
                    {pricing.paymentCommission}
                  </span>
                </p>
              </div>
            </div>
            {isAuthenticated ? (
              <Link to={ROUTES.CREATE_CAMPAIGN}>
                <button className={classes.button}>
                  {ourClients.createButtonLabel}
                </button>
              </Link>
            ) : (
              <Link to={ROUTES.SIGNUP}>
                <button className={classes.button}>
                  {ourClients.buttonLabel}
                </button>
              </Link>
            )}
          </div>
        </section>
        <section className={classes.contact} ref={contactRef}>
          <img className={classes.waveBottom} src={waveBottom} alt="wave" />
          <div className={classes.formWrapper}>
            <div className={classes.title}>{contact.title}</div>
            <div className={classes.formSubTitle}>{contact.subTitle}</div>
            <ContactForm
              labels={contact.form}
              submitContactForm={submitContactForm}
            />
          </div>
        </section>
      </div>
    );
  }
}
