import mainHero from '../../assets/images/mainHero.svg';
import ourGoalRocket from '../../assets/images/ourGoalRocket.svg';
import howItWorks_1 from '../../assets/images/howItWorks_1.svg';
import howItWorks_2 from '../../assets/images/howItWorks_2.svg';
import howItWorks_3 from '../../assets/images/howItWorks_3.svg';
import howItWorks_4 from '../../assets/images/howItWorks_4.svg';
import howItWorks_5 from '../../assets/images/howItWorks_5.svg';
import opportunities from '../../assets/images/opportunities.svg';
import platform_1 from '../../assets/images/platform_1.svg';
import platform_2 from '../../assets/images/platform_2.svg';
import platform_3 from '../../assets/images/platform_3.svg';
import platform_4 from '../../assets/images/platform_4.svg';
import platform_5 from '../../assets/images/platform_5.svg';
import platform_6 from '../../assets/images/platform_6.svg';
import { PHONE_NUMBER, PHONE_NUMBER_TEL } from '../../constants/texts';

const content = {
  heroContent: {
    mainHeading: 'Почніть продавати електронні сертифікати просто зараз!',
    subHeading:
      'Легкий спосіб збільшити дохід та залучити нових клієнтів, відкривши новий канал продажу.',
    buttonLabel: 'Створити сертифікат',
    createButtonLabel: 'Створити кампанію',
    image: mainHero,
  },
  benefits: {
    title: 'Ви отримаєте',
    list: [
      'Індивідуальний сайт для продажу сертифікатів під брендом вашого бізнесу',
      'Легке підключення онлайн платежів',
      'Повна автоматизація процесів продажу та списання сертифікатів',
      'Автоматичні сповіщення клієнтам на електронну пошту',
      'Система управління та обліку проданих сертифікатів ',
      'Надійна підтримка, що гарантує вчасну доставку сертифікатів вашим клієнтам',
    ],
  },
  pricing: {
    title: 'Тарифи',
    terms: 'Ви платите комісію лише',
    highlightedTerms: 'з кожного проданого сертифіката.',
    selfCommission: 'Комісія Upfront - 5%',
    paymentCommission: 'Комісія платіжної системи - 2,75%',
    advantages:
      'Це значно вигідніше та простіше, ніж розробка власного сайту з нуля. Платите тільки за результат — успішний продаж!',
    demo: 'демо.',
    description: 'Не знаєте як буде виглядати сторінка? Переглянути ',
    demoLink: 'https://upfront.com.ua/campaign/sample',
  },
  ourGoal: {
    title: 'Наша мета',
    subTitle:
      'Наша мета — надати підприємцям легкий доступ до продажу електронних сертифікатів, а також зробити процес покупки та використання зручним для їхніх клієнтів.',
    info:
      'Ми створили інструмент, за допомогою якого будь-який бізнес може здійснювати продаж товарів чи послуг в інтернеті, створювати або розширювати власну онлайн аудиторію та диверсифікувати джерела доходу. ',
    image: ourGoalRocket,
  },
  howItWorks: {
    title: 'Як це працює',
    steps: [
      {
        number: 1,
        title: 'Реєстрація',
        text: 'Створюєте акаунт на Upfront і підключаєте платіжну систему.',
        image: howItWorks_1,
      },
      {
        number: 2,
        title: 'Налаштування кампанії',
        text: 'Додаєте логотип, фото, встановлюєте параметри сертифікатів.',
        image: howItWorks_2,
      },
      {
        number: 3,
        title: 'Поширення',
        text:
          'Розповсюджуєте вашу веб-сторінку в соцмережах, пошті та рекламі.',
        image: howItWorks_3,
      },
      {
        number: 4,
        title: 'Отримання коштів',
        text: 'Після продажу кошти надходять вам протягом 24 годин.',
        image: howItWorks_4,
      },
      {
        number: 5,
        title: 'Використання сертифікатів',
        text:
          'Сертифікати використовуються з кодом або QR-кодом, а залишок доступний для наступних покупок.',
        image: howItWorks_5,
      },
    ],
  },
  opportunities: {
    title: 'Можливості',
    image: opportunities,
    list: [
      {
        text:
          'Продавайте електронні сертифікати, щоб залучати нових клієнтів та підвищувати лояльність.',
      },
      {
        text:
          'Запускайте ваучери та купони зі знижками для швидкого розвитку бізнесу.',
      },
      {
        text:
          'Продавайте абонементи з визначеною кількістю візитів та попередні сертифікати з майбутньою датою активації.',
      },
      {
        text:
          'Отримуйте підтримку від лояльних клієнтів навіть у кризові періоди.',
      },
      {
        text:
          'Легко керуйте кількома веб-сторінками з різними пропозиціями одночасно.',
      },
      {
        text:
          'Автоматизуйте процеси продажу та списання сертифікатів для бездоганної роботи.',
      },
    ],
  },
  platform: {
    title: 'Особливості платформи',
    list: [
      {
        image: platform_2,
        text: 'Запуск кількох кампаній з індивідуальними умовами одночасно',
      },
      {
        image: platform_3,
        text: 'Гнучке налаштування цін та знижок для сертифікатів',
      },
      {
        image: platform_1,
        text: 'Проста інтеграція з платіжною системою',
      },
      {
        image: platform_4,
        text:
          'Сторінка та повідомлення в стилі вашого бренду з підтримкою мобільних пристроїв',
      },
      {
        image: platform_5,
        text: 'Легкий подарунок сертифікатів друзям та колегам',
      },
      {
        image: platform_6,
        text:
          'Зручне управління сертифікатами з переглядом, обліком і використанням',
      },
    ],
  },
  ourClients: {
    title: 'Наші клієнти',
    list: [],
    buttonLabel: 'Зареєструватись',
    createButtonLabel: 'Створити кампанію',
  },
  contact: {
    title: 'Маєте запитання?',
    subTitle: 'Залиште заявку для персональної консультації про наш сервіс',
  },
  footer: {
    address: 'м. Київ, Кловський узвіз 7А, 02132',
    tel: PHONE_NUMBER,
    hrefTel: PHONE_NUMBER_TEL,
    email: 'send@upfront.com.ua',
    policy: 'Політика конфіденційності',
    faq: 'FAQ',
    agrement: 'Публічна оферта',
    telegram: 'https://t.me/upfront_support',
  },
};

export default content;
