import React, { Component } from 'react';
import classnames from 'classnames';
import CertificateTiers from '../../components/NewLanding/CertificateTiers';
import { USER_LANDING } from '../../constants/newLandingTexts';
import { getFontColor } from '../../utils';
import ContactForm from '../../components/NewLanding/ContactForm';
import CampaignViewTracker from '../../components/CampaignViewTracker';
import styles from './NewLanding.module.scss';

export default class NewLandingPage extends Component {
  render() {
    const {
      certificateTiers,
      campaignData: {
        primaryColor,
        brandLogo,
        language,
        fontColor,
        backgroundColor,
        id,
        additionalText,
      },
      isPreview,
    } = this.props;

    const { isFontWhite } = getFontColor(backgroundColor);
    const { certificateRef, feedbackRef } = this;

    return (
      <div className={styles.landingPage} style={{ backgroundColor }}>
        <CampaignViewTracker campaignId={id} />
        <section className={styles.certificatesBlock} ref={certificateRef}>
          <h4
            className={classnames(styles.title, {
              [styles.white]: isFontWhite,
            })}
          >
            {USER_LANDING.CHOOSE_CERTIFICATE[language]}
          </h4>
          <CertificateTiers
            logo={brandLogo}
            isPreview={isPreview}
            tiers={certificateTiers}
            primaryColor={primaryColor}
            buttonFontColor={fontColor}
            language={language}
            backgroundColor={backgroundColor}
          />
        </section>
        <section
          className={classnames(styles.contactBlock, {
            [styles.contactBlockWhite]: isFontWhite,
            [styles.withAdditionalText]: !!additionalText,
          })}
          ref={feedbackRef}
        >
          <h4 className={styles.contactTitle}>
            {USER_LANDING.CONTACT_FORM.CONTACT_TITLE[language]}
          </h4>
          <p>{USER_LANDING.CONTACT_FORM.DESCRIPTION[language]}</p>
          <ContactForm
            ref={feedbackRef}
            fontColor={fontColor}
            language={language}
            color={primaryColor}
            background={backgroundColor}
          />
        </section>
        {!!additionalText && (
          <section className={styles.additionalBlock}>
            <p
              className={classnames(styles.additionalText, {
                [styles.white]: isFontWhite,
              })}
            >
              {additionalText}
            </p>
          </section>
        )}
      </div>
    );
  }
}
