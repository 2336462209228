import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HomePage from './pages/HomePage';
import AdminUsersPage from './pages/AdminUsersPage';
import AdminReferralPage from './pages/AdminReferralPage';
import AdminContainer from './components/AdminContainer';
import MainLayout from './pages/MainLayout';
import ConfirmEmailPage from './pages/ConfirmEmail';
import AuthContainer from './components/AuthContainer';
import PrivateRoute from './components/PrivateRoute';
import ProfilePage from './pages/ProfilePage';
import DashboardPage from './pages/DashboardPage';
import LandingContainer from './components/LandingContainer';
import CampaignsPage from './pages/CampaignsPage';
import CertificatesPage from './pages/CertificatesPage';
import AdminCampaignPage from './pages/AdminCampaignPage';
import LiqpayAccountCreatePage from './pages/LiqpayAccountCreatePage';
import BillingSettingsPage from './pages/BillingSettings';
import UserAdministrationSettingsPage from './pages/UserAdministrationSettingsPage';
import FaqPage from './pages/FAQ';
import HelpPage from './pages/HelpPage';
import TermsPage from './pages/Terms';
import AgreementPage from './pages/Agreement';
import { ROUTES } from './constants/routes';
import GA from './components/GoogleAnalytics';

const Routes = () => (
  <MainLayout>
    {GA.init() && <GA.RouteTracker />}
    <Switch>
      <Route path={ROUTES.HOME} component={HomePage} exact />
      <Route path={ROUTES.TERMS} component={TermsPage} exact />
      <Route path={ROUTES.AGREEMENT} component={AgreementPage} exact />
      <Route path={ROUTES.USER_LANDING} component={LandingContainer} />
      <Route path={ROUTES.FAQ} component={FaqPage} />
      <PrivateRoute
        path={ROUTES.USER_LANDING_PREVIEW}
        component={LandingContainer}
      />
      <PrivateRoute path={ROUTES.ADMIN} component={AdminContainer} exact />
      <PrivateRoute path={ROUTES.HELP} component={HelpPage} exact />
      <PrivateRoute
        path={ROUTES.ADMIN_USERS}
        component={AdminUsersPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.ADMIN_REFERRAL}
        component={AdminReferralPage}
        exact
      />
      <Route path={ROUTES.CONFIRM_EMAIL} component={ConfirmEmailPage} />
      <PrivateRoute path={ROUTES.PROFILE} component={ProfilePage} exact />
      <PrivateRoute
        path={ROUTES.BILLING_SETTINGS}
        component={BillingSettingsPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.USER_ADMINISTRATION_SETTINGS}
        component={UserAdministrationSettingsPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.LIQPAY_ACCOUNT_CREATE}
        component={LiqpayAccountCreatePage}
        exact
      />
      <PrivateRoute path={ROUTES.DASHBOARD} component={DashboardPage} />
      <PrivateRoute path={ROUTES.CAMPAIGNS} component={CampaignsPage} />
      <PrivateRoute
        path={ROUTES.CERTIFICATES}
        component={CertificatesPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.CERTIFICATES_USE}
        component={CertificatesPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.CERTIFICATES_VIEW}
        component={CertificatesPage}
      />
      <PrivateRoute
        path={ROUTES.CREATE_CAMPAIGN}
        component={AdminCampaignPage}
      />
      <PrivateRoute path={ROUTES.EDIT_CAMPAIGN} component={AdminCampaignPage} />
      <AuthContainer />
    </Switch>
  </MainLayout>
);

export default Routes;
