import { connect } from 'react-redux';

import DashboardPage from './DashboardPage';

const selector = null;

const actions = {};

export { DashboardPage };

export default connect(selector, actions)(DashboardPage);
