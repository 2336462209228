import React from 'react';
import classnames from 'classnames';
import classes from './NewBrandInfo.module.scss';
import { USER_LANDING } from '../../../constants/texts';
import { getFontColor } from '../../../utils';
import Vector from '../../../assets/images/vector.svg';

class NewBrandInfo extends React.Component {
  state = { isOpen: false };

  handleOpenClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { isOpen } = this.state;
    const { about, language, backgroundColor } = this.props;

    if (!about) {
      return null;
    }

    const { isFontWhite } = getFontColor(backgroundColor);

    return (
      <div className={classes.infoContainer} style={{ backgroundColor }}>
        <div className={classes.wrapper} onClick={this.handleOpenClick}>
          <div className={classes.row}>
            <span className={classnames({ [classes.white]: isFontWhite })}>
              {isOpen
                ? USER_LANDING.ABOUT_HIDE[language]
                : USER_LANDING.ABOUT[language]}
            </span>
            <img
              src={Vector}
              className={isOpen ? classes.vectorOpen : classes.vectorClose}
              alt="Arrow"
            />
          </div>
          <div
            className={classnames(classes.about, {
              [classes.visible]: isOpen,
              [classes.white]: isFontWhite,
            })}
          >
            <span className={classes.aboutContainer}>{about}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default NewBrandInfo;
