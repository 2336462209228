import React from 'react';
import { Modal, Select } from 'antd';
import { LANGUAGE_LABELS } from '../../../constants/labels';
import * as classes from './AddLanguagePopup.module.scss';

export default class AddLanguagePopup extends React.Component {
  state = { translation: this.props.translation || {} };

  componentDidMount() {
    const { getCampaigns, userId, translation } = this.props;

    getCampaigns(userId);

    if (translation) {
      this.setState({ translation });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !prevProps.translation &&
      prevProps.translation !== this.props.translation
    ) {
      this.setState({ translation: this.props.translation });
    }
  }

  render() {
    const { isOpen, onCancel, campaigns, translation, language } = this.props;

    if (!translation) {
      return null;
    }

    return (
      <Modal
        visible={isOpen}
        okText="Готово"
        cancelText="Відмінити"
        title="Додати переклад"
        onOk={this.handleOkClick}
        onCancel={onCancel}
        destroyOnClose
        centered
      >
        <div>
          <div>
            <span>
              Мова кампанії: <b>{LANGUAGE_LABELS[language]}</b>
            </span>
          </div>
          <div>
            <span>
              Доступні мови для перекладу:{' '}
              <b>{this.getAvailableLanguages(language)}</b>
            </span>
            <div className={classes.wrapper}>
              {this.getSelectLanguages(language).map(l => (
                <div key={l}>
                  <span>
                    <b>{LANGUAGE_LABELS[l]}:</b>
                  </span>
                  <Select
                    value={this.state.translation[l] || null}
                    onSelect={this.handleSelect(l)}
                    options={campaigns}
                    placeholder="Виберіть кампанію для мови"
                    onBlur={event => event.preventDefault()}
                    className={classes.select}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  handleSelect = key => value => {
    const translation = { ...this.state.translation };

    translation[key] = value;

    this.setState({ translation });
  };

  handleOkClick = () => {
    const { changeField, onOk } = this.props;
    const { translation } = this.state;

    Object.keys(translation).forEach(language => {
      changeField(`translation.${language}`, translation[language]);
    });
    onOk();
    this.setState({});
  };

  getSelectLanguages = language => {
    return Object.keys(LANGUAGE_LABELS).filter(l => l !== language);
  };

  getAvailableLanguages = language => {
    return this.getSelectLanguages(language)
      .map(l => LANGUAGE_LABELS[l])
      .join(', ');
  };
}
