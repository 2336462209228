import superagent from 'superagent';
import {
  setToken as setTokenToStorage,
  removeToken as removeTokenFromStorage,
  getToken as getTokenFromStorage,
} from '../localStorage';

const METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

export default class ApiClient {
  constructor(prefix) {
    if (!prefix) {
      throw new Error('[apiPrefix] required');
    }

    this.prefix = prefix;
    this.token = getTokenFromStorage();
  }

  setToken(token) {
    this.token = token;
    setTokenToStorage(token);
  }

  removeToken() {
    this.token = null;
    removeTokenFromStorage();
  }

  get(url, params = {}) {
    return this.request({ url, method: METHODS.GET, params });
  }

  post(url, body) {
    return this.request({ url, method: METHODS.POST, body });
  }

  patch(url, body) {
    return this.request({ url, method: METHODS.PATCH, body });
  }

  put(url, body) {
    return this.request({ url, method: METHODS.PUT, body });
  }

  delete(url, body) {
    return this.request({ url, method: METHODS.DELETE, body });
  }

  request({ url, method, params, body }) {
    const path = `${this.prefix}${url}`;
    const req = superagent[method](path);

    if (params) {
      req.query(params);
    }
    if (body) {
      req.send(body);
    }
    if (this.token) {
      req.set('Authorization', `Bearer ${this.token}`);
    }

    return req.then(res => {
      const { status, data, error } = res.body;

      if (!status) {
        throw error;
      }

      return data;
    });
  }
}
