import { takeEvery, select } from 'redux-saga/effects';
import { scrollToElementId } from '../../helpers/common';
import { GO_TO_PAGE_AND_SCROLL } from './actions';
import { isRootLanding } from './selectors';

export function* pushToRouteAndScroll(history) {
  yield takeEvery(GO_TO_PAGE_AND_SCROLL, function* (action) {
    const { path, id, offset } = action.payload;
    const isLanding = yield select(isRootLanding);

    if (!isLanding) {
      yield history.push(path);
    }

    setTimeout(() => scrollToElementId(id, offset), 100);
  });
}
