import 'moment/locale/uk';
import React from 'react';
import locale from 'antd/es/date-picker/locale/uk_UA';
import qs from 'qs';
import moment from 'moment';
import { DatePicker } from 'antd';
import styles from './Search.module.scss';

const { RangePicker } = DatePicker;

class ExportCertificatesModal extends React.Component {
  state = {
    dateRange: [moment().startOf('month'), moment().endOf('day')],
  };

  handleDateChange = dates => {
    if (!dates) {
      return;
    }

    this.setState({ dateRange: dates });
  };

  getQuery = () => {
    const { userId, token } = this.props;
    const { dateRange } = this.state;
    const stringRange = dateRange.map((value, position) => {
      const date = moment(value);

      if (dateRange.length - 1 === position) {
        return date.endOf('day').format();
      }

      return date.format();
    });
    const [dateFrom, dateTo] = stringRange;

    return qs.stringify({
      token,
      userId,
      dateFrom,
      dateTo,
    });
  };
  disabledDate(current) {
    return current && current > moment().startOf('day');
  }

  render() {
    const { dateRange } = this.state;
    const { handleHide, visible } = this.props;
    return (
      <>
        <div className={styles.exportCertificatesModal}>
          <RangePicker
            onChange={this.handleDateChange}
            value={dateRange}
            allowClear={false}
            disabledDate={this.disabledDate}
            locale={locale}
          />
          <a
            href={`/api/v1/certificates/export/certificates?${this.getQuery()}`}
            className={styles.modalButton}
            onClick={() => handleHide(!visible)}
          >
            Експорт
          </a>
        </div>
      </>
    );
  }
}
export default ExportCertificatesModal;
