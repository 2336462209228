import { createSelector } from 'reselect';
import moment from 'moment';
import { prefix } from './actions';
import { formatDate, priceWithDecimal } from '../../helpers/stringFormatters';
import { getFormValues, validateForm } from '../forms/utils';
import { shortCampaigns } from '../campaign/selectors';
import { CERTIFICATE_TYPES } from '../../constants/labels';

const root = state => state[prefix];
const STATUSES = {
  ACTIVE: 'Aктивний',
  NOT_ACTIVE: 'Неактивний',
  USED: 'Використаний',
};
const TYPE = {
  REPLENISHMENT: 'REPLENISHMENT',
  WITHDRAWAL: 'WITHDRAWAL',
  CORRECTION: 'CORRECTION',
};
const excludedFields = ['createCertificate'];

const getTransactionText = (type, amount, createdAt) => {
  const text = `ACTION вартості у розмірі ${priceWithDecimal(
    amount,
  )} за ${moment(createdAt).format('l')}`;
  switch (type) {
    case TYPE.WITHDRAWAL: {
      return text.replace('ACTION', 'Списання');
    }
    case TYPE.CORRECTION: {
      return text.replace('ACTION', 'Корегування');
    }
    default: {
      return text.replace('ACTION', 'Нарахування');
    }
  }
};

export const loading = createSelector(root, state => state.loading);

export const error = createSelector(root, state => state.error);

export const page = createSelector(root, state => state.page);

export const total = createSelector(root, state => state.total);

export const search = createSelector(root, state => state.search);

export const balance = createSelector(root, state => state.balance);

export const selectedCertificateId = createSelector(
  root,
  state => state.selectedCertificateId,
);

export const isExportVisible = createSelector(root, state =>
  Boolean(state.list.length),
);

export const certificates = createSelector(root, state =>
  state.list.map(certificate => {
    const {
      selectedTier = {},
      firstName,
      lastName,
      remainder,
      transactions,
      expiredAt,
      createdAt,
      commencementDate,
      status,
    } = certificate;
    const isExpired = expiredAt ? moment().isAfter(moment(expiredAt)) : false;

    return {
      ...certificate,
      isExpired,
      status: STATUSES[status],
      isActive: certificate.status === 'ACTIVE',
      type: selectedTier.name,
      isReusable: selectedTier.isReusable,
      price: priceWithDecimal(selectedTier.price),
      value: priceWithDecimal(remainder),
      initialValue: priceWithDecimal(selectedTier.value),
      createdAt: formatDate(createdAt),
      activatedAt: formatDate(commencementDate),
      buyerName: `${firstName} ${lastName}`,
      remainder: (remainder / 100).toFixed(2),
      expiredAt: expiredAt ? formatDate(expiredAt) : 'Необмежений',
      initialRemainder: remainder,
      transactions: transactions.map(
        ({ createdAt, amount, id, comment, type }) => ({
          text: getTransactionText(type, amount, createdAt),
          createdAt: moment(createdAt).format('l'),
          amount: priceWithDecimal(amount),
          comment,
          id,
        }),
      ),
    };
  }),
);

export const selectedCertificate = createSelector(
  selectedCertificateId,
  certificates,
  (selectedCertificateId, certificates) => {
    return certificates.find(({ id }) => id === selectedCertificateId);
  },
);

export const useCertificateFormValues = createSelector(
  state => state.form.useCertificate,
  getFormValues,
);
export const resendCertificateFormValues = createSelector(
  state => state.form.resendCertificates,
  getFormValues,
);

export const createCertificateValues = createSelector(
  state => state.form.createCertificate,
  getFormValues,
);

export const createCertificateDisabled = createSelector(
  state => state.form.createCertificate,
  validateForm(excludedFields),
);

export const formCertificateTiers = createSelector(
  shortCampaigns,
  createCertificateValues,
  (list, { campaign: id }) => {
    const campaign = list.find(campaign => campaign.id === id);

    if (!campaign) {
      return [];
    }

    return campaign.certificateTiers.map(({ _id, name }) => ({
      value: _id,
      label: name,
    }));
  },
);

export const isGiftCreateCertificate = createSelector(
  createCertificateValues,
  ({ type }) => type === CERTIFICATE_TYPES.GIFT,
);
