import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faEnvelopeOpenText,
} from '@fortawesome/free-solid-svg-icons';

import styles from './ConfirmEmail.module.scss';
import { ROUTES } from '../../constants/routes';

class ConfirmEmail extends Component {
  static propTypes = {
    confirm: PropTypes.func.isRequired,
    isConfirmed: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const {
      confirm,
      match: {
        params: { hash },
      },
    } = this.props;
    confirm(hash);
  }

  render() {
    const { isConfirmed } = this.props;

    return (
      <div className={styles.confirmEmailWrap}>
        {isConfirmed ? (
          <div className={styles.column}>
            <FontAwesomeIcon
              className={styles.icon}
              icon={faEnvelopeOpenText}
            />
            <h3>Дякую, за підтрвердження електронної пошти.</h3>
            <p>
              Тепер ви можете увійти в{' '}
              <Link to={ROUTES.LOGIN}>особистий кабінет</Link>.
            </p>
          </div>
        ) : (
          <div className={styles.column}>
            <FontAwesomeIcon className={styles.icon} icon={faEnvelope} />
            <h3>Ваша електронна пошта не підтрвердженна.</h3>
            <p>Будь ласка, перевірте електронну пошту.</p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ConfirmEmail);
