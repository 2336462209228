import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import UserAdministrationSettingsPage from './UserAdministrationSettingsPage';
import { getShortCampaigns } from '../../redux/campaign/actions';
import { loading } from '../../redux/administration/selectors';
import { userId } from '../../redux/profile/selectors';

const mapStateToProps = createStructuredSelector({
  loading,
  adminId: userId,
});

const mapDispatchToProps = {
  getCampaigns: getShortCampaigns,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAdministrationSettingsPage);
