import React, { Component } from 'react';
import classnames from 'classnames';
import {
  CheckOutlined,
  StopOutlined,
  GiftOutlined,
  WalletOutlined,
  SendOutlined,
  FormOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Table as AntTable,
  Pagination,
  Tooltip,
  Popover,
  Button,
  Collapse,
  Modal,
} from 'antd';
import { RESEND_CERTIFICATES } from '../../../redux/forms/names';
import UseModal from '../UseModal/UseModal';
import ResendModal from '../ResendModal/index';
import * as classes from './Table.module.scss';
import { CERTIFICATE_TYPES } from '../../../constants/labels';

const { Panel } = Collapse;

class CertificatesTable extends Component {
  state = {
    isUseOpen: false,
    isDeactivateOpen: false,
    selectedCertificate: {},
    initialSearchValue: null,
    isResendModalOpen: false,
  };

  componentDidMount() {
    const {
      userId,
      getCertificates,
      fetchInitialData,
      initialSearchValue,
    } = this.props;

    if (initialSearchValue) {
      this.setState({ initialSearchValue });
    }

    if (fetchInitialData) {
      getCertificates(userId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { initialSearchValue } = this.props;
    if (
      prevProps.initialSearchValue &&
      initialSearchValue &&
      prevProps.initialSearchValue !== initialSearchValue
    ) {
      this.setState({ initialSearchValue });
    }
  }

  handleDeactivateClick = () => {
    const { selectedCertificate } = this.state;

    this.props.deactivate(selectedCertificate.id);
    this.handleDeactivateModelClick()();
  };

  handleUseModelClick = (selectedCertificate = {}) => () => {
    this.setState({
      selectedCertificate,
      isUseOpen: !this.state.isUseOpen,
    });
  };

  handleDeactivateModelClick = (selectedCertificate = {}) => () => {
    this.setState({
      selectedCertificate,
      isDeactivateOpen: !this.state.isDeactivateOpen,
    });
  };

  handleResendClick = data => async () => {
    const { resendCertificate } = this.props;
    await resendCertificate(data);
    this.handleResendModelClick(null)();
  };

  handleResendModelClick = (id = null) => () => {
    const { setSelectedCertificateId, initialize } = this.props;

    this.setState({
      isResendModalOpen: !this.state.isResendModalOpen,
    });
    setSelectedCertificateId(id);

    if (!id) {
      initialize(RESEND_CERTIFICATES);
    }
  };

  handleSubmitClick = async () => {
    const { formValues, use, userId } = this.props;
    const { selectedCertificate } = this.state;
    const { amount, comment, code } = formValues;

    return use(
      selectedCertificate.id,
      { amount: amount * 100, comment, code: code ? code.toUpperCase() : null },
      userId,
    );
  };

  handlePageClick = page => {
    const { userId, changePage, getCertificates } = this.props;

    changePage(page);
    getCertificates(userId, page);
  };

  getCertificateType = certificate => {
    const { recipient, email, createdManually, certificateType } = certificate;
    const popovers = [];
    const isGift = certificateType === CERTIFICATE_TYPES.GIFT;
    const icon = isGift ? <GiftOutlined /> : <WalletOutlined />;
    const tip = isGift
      ? 'Сертифікат в якості подарунку'
      : 'Сертифікат у власне користування';
    const user = isGift ? recipient : email;

    popovers.push(
      <Popover
        title=""
        placement="bottom"
        trigger="click"
        content={
          <div className={classes.popover}>
            <b>{tip}</b>
            <b className={classes.margin}>Отримувач:</b> {user}
          </div>
        }
      >
        <span className={classes.typeIcon}>{icon}</span>
      </Popover>,
    );

    if (createdManually) {
      const manualTip = createdManually ? 'Сертифікат створено вручну' : null;

      popovers.push(
        <Popover
          title=""
          placement="bottom"
          trigger="click"
          content={
            <div className={classes.popover}>
              <b className={classnames(classes.row, classes.manual)}>
                <WarningOutlined />
                <p>{manualTip}</p>
              </b>
            </div>
          }
        >
          <span className={classes.typeIcon}>
            <FormOutlined />
          </span>
        </Popover>,
      );
    }

    return popovers;
  };

  render() {
    const {
      isUseOpen,
      selectedCertificate,
      isDeactivateOpen,
      initialSearchValue,
      isResendModalOpen,
    } = this.state;
    const { certificates, page, total, search, error } = this.props;

    return (
      <div className={classes.container}>
        <AntTable
          className={classnames({
            [classes.withData]: Boolean(certificates.length),
          })}
          rowKey="uid"
          columns={[
            {
              title: 'Назва',
              dataIndex: 'type',
              key: 'type',
            },
            {
              title: 'Номер',
              dataIndex: 'certificateID',
              key: 'certificateID',
            },
            {
              title: 'Дата створення',
              dataIndex: 'createdAt',
              key: 'createdAt',
            },
            {
              title: 'Дійсний до',
              dataIndex: 'expiredAt',
              key: 'expiredAt',
              render: (text, record) => {
                return (
                  <span
                    className={classnames({
                      [classes.expired]: record.isExpired,
                    })}
                  >
                    {text}
                  </span>
                );
              },
            },
            {
              title: 'Дані клієнта',
              dataIndex: 'email',
              key: 'email',
              render: (text, record) => (
                <Popover
                  placement="bottom"
                  title=""
                  content={
                    <div className={classes.popover}>
                      <span>
                        <b>{record.buyerName}</b>
                      </span>
                      {record.phone && <span>{record.phone}</span>}
                      {record.additionalInfo && (
                        <span>{record.additionalInfo}</span>
                      )}
                      <span className={classes.email}>{record.email}</span>
                      <span className={classes.payment}>
                        <b>Платіж:</b> {record.chargeId}
                      </span>
                    </div>
                  }
                  trigger="click"
                >
                  <span className={classes.link}>{text}</span>
                </Popover>
              ),
            },
            {
              title: 'Тип',
              dataIndex: 'certificateType',
              key: 'certificateType',
              render: (text, record) => (
                <div className={classes.row}>
                  {this.getCertificateType(record)}
                </div>
              ),
            },
            {
              title: 'Ціна(грн)',
              dataIndex: 'price',
              key: 'price',
            },
            {
              title: 'Поточна вартість(грн)',
              dataIndex: 'value',
              key: 'value',
              render: (text, record) => (
                <Popover
                  placement="bottom"
                  title=""
                  content={
                    <div className={classes.popover}>
                      <span>
                        <b>Початкова вартість: </b>
                        {record.initialValue}
                      </span>
                      <Collapse className={classes.collapse}>
                        {record.transactions.map(t => (
                          <Panel
                            key={t.id}
                            header={`${t.createdAt} - ${t.amount}`}
                          >
                            <p>{t.text}</p>
                            {t.comment && <q>{t.comment}</q>}
                          </Panel>
                        ))}
                      </Collapse>
                    </div>
                  }
                  trigger="click"
                >
                  <span className={classes.link}>{text}</span>
                </Popover>
              ),
            },
            {
              title: 'Статус',
              dataIndex: 'status',
              key: 'status',
              render: (text, record) => (
                <span
                  className={classnames(classes.status, {
                    [classes.active]: record.isActive,
                    [classes.notActive]: !record.isActive,
                  })}
                >
                  {text}
                </span>
              ),
            },
            {
              title: null,
              key: 'action',
              render: (text, record) => (
                <div className={classes.buttons}>
                  <Tooltip placement="top" title="Переслати сертифікат">
                    <Button
                      disabled={!record.isActive}
                      className={classes.secondary}
                      onClick={this.handleResendModelClick(record.id)}
                    >
                      <SendOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={
                      record.isActive
                        ? `Деактивувати ${record.type} сертифікат`
                        : 'Сертифікат неактивний'
                    }
                  >
                    <Button
                      disabled={!record.isActive}
                      className={classes.notActive}
                      onClick={this.handleDeactivateModelClick(record)}
                    >
                      <StopOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    placement="topRight"
                    title={
                      record.isActive
                        ? `Використати ${record.type} сертифікат`
                        : 'Сертифікат неактивний'
                    }
                  >
                    <Button
                      disabled={!record.isActive}
                      className={classes.active}
                      onClick={this.handleUseModelClick(record)}
                    >
                      <CheckOutlined />
                    </Button>
                  </Tooltip>
                </div>
              ),
            },
          ]}
          size="small"
          dataSource={certificates}
          pagination={false}
        />

        <UseModal
          isOpen={isUseOpen}
          requestError={error}
          isCertificateInputShown={initialSearchValue !== search}
          id={selectedCertificate.id}
          remainder={selectedCertificate.remainder}
          isReusable={selectedCertificate.isReusable}
          validateRemainder={selectedCertificate.initialRemainder}
          onCancelClick={this.handleUseModelClick()}
          onSubmitClick={this.handleSubmitClick}
        />
        <Modal
          destroyOnClose
          title="Деактивувати сертифікат"
          centered
          okText="Підтвердити"
          cancelText="Скасувати"
          visible={isDeactivateOpen}
          onOk={this.handleDeactivateClick}
          onCancel={this.handleDeactivateModelClick()}
        >
          <span>
            Ви впевнені, що хочете деактивувати{' '}
            <b>{selectedCertificate.name}</b>?
          </span>
        </Modal>
        <ResendModal
          isOpen={isResendModalOpen}
          onSubmit={this.handleResendClick}
          onCancel={this.handleResendModelClick(null)}
        />
        <Pagination
          className={classes.pagination}
          onChange={this.handlePageClick}
          pageSize={10}
          defaultCurrent={1}
          total={total}
          current={page}
        />
      </div>
    );
  }
}

export default CertificatesTable;
