import React from 'react';
import viewTrackerImg from '../../assets/images/viewTrackerImage.png';
import classes from './CampaignViewTracker.module.scss';

class CampaignViewTracker extends React.Component {
  updateViews = () => {
    const { updateCampaignViews, campaignId } = this.props;
    updateCampaignViews(campaignId);
  };

  render() {
    return (
      <img
        src={viewTrackerImg}
        onLoad={this.updateViews}
        onError={this.updateViews}
        className={classes.viewTracker}
        alt="viewTracker"
      />
    );
  }
}
export { CampaignViewTracker };
