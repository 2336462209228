import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { campaignData } from '../../redux/landing/selectors';
import { campaignId } from '../../redux/router/selectors';

import BrandFooter from './BrandFooter';

const selector = createStructuredSelector({
  campaignId,
  campaignData,
});

export { BrandFooter };

export default connect(selector, null)(BrandFooter);
