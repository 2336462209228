import { combineReducers } from 'redux';
import { cloneDeep } from 'lodash';
import * as actions from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case actions.LANDING_DATA_REQUEST:
      case actions.PAYMENT_DATA_REQUEST:
      case actions.CONTACT_FORM_SUBMIT_REQUEST:
      case actions.SUCCESS_PAYMENT_DATA_REQUEST:
        return true;
      case actions.LANDING_DATA_SUCCESS:
      case actions.LANDING_DATA_FAILURE:
      case actions.PAYMENT_DATA_SUCCESS:
      case actions.PAYMENT_DATA_FAILURE:
      case actions.CONTACT_FORM_SUBMIT_SUCCESS:
      case actions.CONTACT_FORM_SUBMIT_FAILURE:
      case actions.SUCCESS_PAYMENT_DATA_SUCCESS:
      case actions.SUCCESS_PAYMENT_DATA_FAILURE:
        return false;
      default:
        return state;
    }
  },
  paymentIntentionData(state = {}, action) {
    switch (action.type) {
      case actions.PAYMENT_DATA_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  landingData(state = { campaign: {} }, action) {
    switch (action.type) {
      case actions.LANDING_DATA_SUCCESS:
        return cloneDeep(action.data);
      default:
        return state;
    }
  },
  certificate(state = null, action) {
    switch (action.type) {
      case actions.SHOW_SUCCESS_PAYMENT_SCREEN: {
        const { campaignId, certificateId } = action.payload;

        return {
          campaignId,
          certificateId,
        };
      }

      default:
        return state;
    }
  },
});
