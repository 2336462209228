import React, { Component } from 'react';
import classnames from 'classnames';
import { USER_LANDING } from '../../constants/texts';
import InfoBlock from '../../components/Landing/InfoBlock';
import { ROUTES } from '../../constants/routes';
import styles from './SuccessCertificatePage.module.scss';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { getFontColor } from '../../utils';
import { getCommencementDate, getExpireDate } from '../../helpers/common';

export default class SuccessCertificatePage extends Component {
  render() {
    const {
      campaignData,
      paidCertificate,
      history,
      certificateData,
    } = this.props;
    const {
      language,
      brandLogo,
      identifier,
      fontColor,
      primaryColor,
      backgroundColor,
      certificatesCount,
    } = campaignData;

    if (!paidCertificate) {
      history.push(ROUTES.USER_LANDING.replace(':id', identifier));
      return null;
    }

    const { isFontWhite } = getFontColor(backgroundColor);

    return (
      <div
        className={styles.successCertificatePage}
        style={{ backgroundColor }}
      >
        <InfoBlock
          campaignId={identifier}
          backgroundColor={backgroundColor}
          wrapperClassName={styles.infoBlock}
          logo={brandLogo}
          heading={USER_LANDING.PAYMENT.HEADER[language]}
          isIconAdded={false}
          translation={[]}
        />
        <div
          className={classnames(styles.message, {
            [styles.white]: isFontWhite,
          })}
        >
          <p className={styles.bold}>
            {certificatesCount === 1
              ? USER_LANDING.PAYMENT.CERTIFICATE[language]
              : USER_LANDING.PAYMENT.CERTIFICATES[language]}
            {getCommencementDate({ certificateData, campaignData })}{' '}
            {getExpireDate({ certificateData, campaignData })}
          </p>
          <p>{USER_LANDING.PAYMENT.SPAM[language]}</p>
        </div>
        <Button
          className={styles.button}
          htmlType="submit"
          type="primary"
          style={{ backgroundColor: primaryColor, color: fontColor }}
        >
          <Link to={ROUTES.USER_LANDING.replace(':id', identifier)}>
            {USER_LANDING.PAYMENT.BUY_ANOTHER[language]}
          </Link>
        </Button>
      </div>
    );
  }
}
