import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { campaignData } from '../../redux/landing/selectors';
import { campaignId } from '../../redux/router/selectors';

import BrandHeader from './BrandHeader';

const selector = createStructuredSelector({
  campaignData,
  campaignId,
});

const actions = {};

export { BrandHeader };

export default connect(selector, actions)(BrandHeader);
