import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import { user, loading as profileLoading } from '../../redux/profile/selectors';
import { getCampaigns } from '../../redux/campaign/actions';
import {
  isCampaignsExists,
  loading as campaignLoading,
} from '../../redux/campaign/selectors';
import BillingSettings from './BillingSettings';

const loading = createSelector(
  profileLoading,
  campaignLoading,
  (profileLoading, campaignLoading) => campaignLoading || profileLoading,
);

const selector = createStructuredSelector({
  user,
  loading,
  isCampaignsExists,
});

const actions = {
  getCampaigns,
};

export { BillingSettings };

export default connect(selector, actions)(BillingSettings);
