import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pathname, location } from '../../redux/router/selectors';
import { loadProfile, logout, setToken } from '../../redux/profile/actions';
import { user, isAdmin } from '../../redux/profile/selectors';
import {
  isHomePage,
  isLandingPage,
  isPreviewPage,
  showHomeFooter,
} from '../../redux/router/selectors';
import { isCookiesTermsUsagesAccepted } from '../../redux/common/selectors';
import MainLayout from './MainLayout';

const selector = createStructuredSelector({
  user,
  location,
  pathname,
  isHomePage,
  isLandingPage,
  isPreviewPage,
  showHomeFooter,
  isAdmin,
  isCookiesTermsUsagesAccepted,
});

const actions = {
  init: loadProfile,
  setToken,
  logout,
};

export { MainLayout };

export default connect(selector, actions)(MainLayout);
