import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  certificateData,
  campaignData,
  hasCertificateImages,
} from '../../redux/landing/selectors';
import { getLandingData } from '../../redux/landing/actions';
import BuyCerticatacePage from './BuyCerticatacePage';

const selector = createStructuredSelector({
  certificateData,
  campaignData,
  hasCertificateImages,
});

const actions = {
  getLandingData,
};

export { BuyCerticatacePage };

export default connect(selector, actions)(BuyCerticatacePage);
