import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Search from './Search';

import { search, isExportVisible } from '../../../redux/certificate/selectors';
import {
  changeSearch,
  getCertificates,
  exportClients,
  exportCertificates,
} from '../../../redux/certificate/actions';
import { userId } from '../../../redux/profile/selectors';
import { token } from '../../../redux/common/selectors';

const selectors = createStructuredSelector({
  search,
  userId,
  token,
  isExportVisible,
});
const actions = {
  changeSearch,
  getCertificates,
  exportClients,
  exportCertificates,
};

export default connect(selectors, actions)(Search);
