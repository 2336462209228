import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React from 'react';
import classnames from 'classnames';
import { SRLWrapper } from 'simple-react-lightbox';
import Slider from 'react-slick';
import { Arrow } from '../Arrow';
import { getFontColor } from '../../../utils';
import ImageLoading from '../../../components/Image';
import { priceWithDecimal } from '../../../helpers/stringFormatters';
import DefaultCertificateImage from '../../../components/NewLanding/DefaultCardImage';
import classes from './Content.module.scss';

const getSRLSettings = () => ({
  settings: {
    autoplaySpeed: 1500,
    transitionSpeed: 900,
  },
  caption: {
    captionColor: '#FFF',
    captionFontFamily: 'Product Sans Medium !important',
    captionFontWeight: 'bold',
    captionTextTransform: 'uppercase',
  },
});

const getSlickSettings = className => ({
  speed: 500,
  arrows: false,
  keyboard: true,
  infinite: true,
  draggable: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  appendDots: function renderDots(dots) {
    return (
      <div>
        <ul>
          {dots.map((item, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <span key={index} className={className}>
                {item}
              </span>
            );
          })}
        </ul>
      </div>
    );
  },
});

export default class Content extends React.Component {
  slider = React.createRef();

  getFont = color => {
    return getFontColor(color).fontColor;
  };

  nextSlide = () => {
    this.slider.current.slickNext();
  };

  prevSlide = () => {
    this.slider.current.slickPrev();
  };

  getOldPrice = () => {
    const {
      certificateData: { price, value },
    } = this.props;

    if (Number(price) < Number(value)) {
      return (
        <span className={classes.discount}>{`${priceWithDecimal(
          value,
        )} ₴`}</span>
      );
    }

    return null;
  };

  getImages = () => {
    const {
      certificateData: { images = [], name },
      campaignData: { primaryColor },
    } = this.props;

    if (!images.length) {
      return (
        <div className={classes.sliderImage}>
          <DefaultCertificateImage primaryColor={primaryColor} />
        </div>
      );
    }

    return images.map(img => (
      <ImageLoading
        wrapperClassName={classes.imageWrapper}
        src={img}
        alt={name}
      />
    ));
  };

  getArrows = () => {
    const {
      campaignData: { backgroundColor, primaryColor },
      certificateData: { images = [] },
    } = this.props;

    if (images.length <= 1) {
      return null;
    }

    return (
      <div className={classes.arrowsContainer}>
        <div
          style={{ '--color': primaryColor }}
          className={classes.arrowLeft}
          onClick={this.prevSlide}
        >
          <Arrow color={this.getFont(backgroundColor)} />
        </div>
        <div
          style={{ '--color': primaryColor }}
          className={classes.arrowRight}
          onClick={this.nextSlide}
        >
          <Arrow color={this.getFont(backgroundColor)} />
        </div>
      </div>
    );
  };

  render() {
    const {
      campaignData: { backgroundColor, primaryColor, heading },
      certificateData: { price, description, name },
    } = this.props;
    const { isFontWhite } = getFontColor(backgroundColor);
    const dotsClass = isFontWhite ? 'whiteDots' : '';

    return (
      <>
        <h3
          className={classnames(classes.mainTitle, {
            [classes.whiteFonts]: isFontWhite,
          })}
        >
          {heading}
        </h3>
        <SRLWrapper options={getSRLSettings()}>
          <div
            className={classnames(classes.mainContainer, {
              [classes.whiteFonts]: isFontWhite,
            })}
          >
            <div className={classes.sliderContainer}>
              <div className={classes.mobile}>{this.getArrows()}</div>
              <Slider
                className={classes.slider}
                ref={this.slider}
                {...getSlickSettings(dotsClass)}
              >
                {this.getImages()}
              </Slider>
            </div>
            <div className={classes.content}>
              <div className={classes.desktop}>{this.getArrows()}</div>
              <h3 className={classes.cardTitle}>{name}</h3>
              <div className={classes.priceContainer}>
                <p
                  style={{ color: primaryColor }}
                  className={classes.price}
                >{`${priceWithDecimal(price)}₴`}</p>
                {this.getOldPrice()}
              </div>
              <div className={classes.description}>{description}</div>
            </div>
          </div>
        </SRLWrapper>
      </>
    );
  }
}
