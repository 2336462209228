import React, { Component } from 'react';
import { Spin, Button } from 'antd';
import { isEqual } from 'lodash';
import { Link } from 'react-router-dom';
import { SaveOutlined, ImportOutlined } from '@ant-design/icons';
import Details from '../../components/Campaigns/Details';
import CertificateTiers from '../../components/Campaigns/CertificateTiers';
import PageSettings from '../../components/Campaigns/PageSettings';
import { ROUTES } from '../../constants/routes';
import { Prompt } from 'react-router';
import * as classes from './AdminCampaignPage.module.scss';

class AdminCampaignPage extends Component {
  state = {
    wantToSave: false,
  };

  componentDidMount() {
    const { userId, init } = this.props;
    init(userId);
  }

  handleSubmitClick = async () => {
    const { create, update, values } = this.props;
    this.setState({ ...this.state, wantToSave: true });

    if (values.id) {
      const { detections, userId, ...rest } = values;
      await update({ ...rest });
    } else {
      await create(values);
    }
  };

  checkIsCampaignCompleted = () => {
    const { values, currentCampaign } = this.props;
    const { wantToSave } = this.state;
    if (wantToSave || !currentCampaign || !values) return false;

    const { certificateTiers } = currentCampaign;
    const certificateTiersFullPrice = certificateTiers
      .map(certificate => ({
        ...certificate,
        price: certificate.price * 100,
        value: certificate.value * 100,
      }))
      .sort((a, b) => a.order - b.order);

    const currentCampaignWithCertificatesFullPrice = {
      ...currentCampaign,
      certificateTiers: certificateTiersFullPrice,
    };
    const valuesWithSortedCertificates = {
      ...values,
      certificateTiers: values.certificateTiers.sort(
        (a, b) => a.order - b.order, //we need to sort as we are getting them in different order in different cases
      ),
    };

    const areEqual = isEqual(
      valuesWithSortedCertificates,
      currentCampaignWithCertificatesFullPrice,
    );

    return !areEqual;
  };

  render() {
    const { loading, disabled, submitName } = this.props;
    return (
      <Spin className={classes.container} spinning={loading}>
        <Prompt
          when={this.checkIsCampaignCompleted()}
          message="Ви впевнені, що хочете покинути сторінку? Всі незбережені дані будуть втрачені."
        />
        <div className={classes.wrapper}>
          <Link to={ROUTES.CAMPAIGNS}>
            <Button>
              <ImportOutlined className={classes.icon} /> Назад
            </Button>
          </Link>
        </div>
        <div className={classes.container}>
          <div className={classes.row}>
            <Details />
            <div className={classes.column}>
              <CertificateTiers />
              <PageSettings />
            </div>
          </div>
          <div className={classes.center}>
            <Button onClick={this.handleSubmitClick} disabled={disabled}>
              <SaveOutlined className={classes.icon} /> {submitName}
            </Button>
          </div>
        </div>
      </Spin>
    );
  }
}

export default AdminCampaignPage;
