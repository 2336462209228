import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import YoutubeBackground from 'react-youtube-background';
import Image from '../Image';
import Hamburger from '../Hamburger';
import { ROUTES } from '../../constants/routes';
import { USER_LANDING } from '../../constants/texts';
import { ELEMENTS_IDS } from '../../constants/labels';
import { getFontColor, getCoverParams } from '../../utils';
import classes from './NewBrandHeader.module.scss';

const ID_POSITION = 2;
const ID_LENGTH = 11;
const videoOptions = {
  playerVars: {
    rel: 0,
    autoplay: 1,
    controls: 0,
    showinfo: 0,
  },
};

export default class NewBrandHeader extends React.Component {
  getFullHeaderContent = () => {
    const {
      campaignData: { brandCover, brandVideo, description, backgroundColor },
      campaignId,
      isRootLanding,
    } = this.props;
    const { fontColor } = getFontColor(backgroundColor);
    const { url, isVideo } = getCoverParams(
      brandCover,
      brandVideo,
      ID_POSITION,
      ID_LENGTH,
    );

    return (
      <>
        <>
          <div className={classes.titleWrapper}>
            {this.getHeaderText(fontColor)}
          </div>
          <div
            className={classnames(classes.headerContent, {
              [classes.payment]: !isRootLanding,
              [classes.root]: isRootLanding,
            })}
          >
            {url && getContent(classes.content, url, isVideo, campaignId)}
            {isRootLanding && (
              <div className={classes.description} style={{ color: fontColor }}>
                {formatText(description)}
              </div>
            )}
          </div>
        </>
        {!isRootLanding && (
          <div className={classes.fullScreenHeader}>
            {getContent(classes.fullScreenContent, url, isVideo, campaignId)}
          </div>
        )}
      </>
    );
  };

  getHeaderText = color => {
    const {
      campaignData: { heading, language },
      isBuyLanding,
      isPaymentLanding,
      isSuccessLanding,
      isRootLanding,
      isBalanceLanding,
    } = this.props;

    switch (true) {
      case isRootLanding: {
        return (
          <p style={{ color }} className={classes.mainTitle}>
            {heading}
          </p>
        );
      }
      case isSuccessLanding: {
        return (
          <p style={{ color }} className={classes.secondaryTitle}>
            {USER_LANDING.SUCCESS_CERTIFICATE[language]}
          </p>
        );
      }
      case isBuyLanding: {
        return (
          <p style={{ color }} className={classes.secondaryTitle}>
            {USER_LANDING.CHOOSE_CERTIFICATE[language]}
          </p>
        );
      }
      case isPaymentLanding: {
        return (
          <p style={{ color }} className={classes.secondaryTitle}>
            {USER_LANDING.PAY_CERTIFICATE[language]}
          </p>
        );
      }
      case isBalanceLanding: {
        return (
          <p style={{ color }} className={classes.secondaryTitle}>
            {USER_LANDING.CERTIFICATE_BALANCE[language]}
          </p>
        );
      }

      default: {
        return null;
      }
    }
  };

  handleScrollCertificateClick = () => {
    const {
      goToPageAndScroll,
      campaignData: { identifier },
    } = this.props;

    goToPageAndScroll(
      ROUTES.USER_LANDING.replace(':id', identifier),
      ELEMENTS_IDS.CERTIFICATES,
    );
  };

  handleScrollFeedbackClick = () => {
    const {
      goToPageAndScroll,
      campaignData: { identifier },
    } = this.props;

    goToPageAndScroll(
      ROUTES.USER_LANDING.replace(':id', identifier),
      ELEMENTS_IDS.FEEDBACK,
      300,
    );
  };

  render() {
    const {
      elementsToScroll,
      campaignData: { backgroundColor, language, brandLogo, identifier },
    } = this.props;
    const { fontColor } = getFontColor(backgroundColor);

    return (
      <section>
        <div style={{ backgroundColor }}>
          <nav className={classes.navbar} style={{ backgroundColor }}>
            <Hamburger
              right="8"
              sidebarBackground={backgroundColor}
              scrollElementsCoords={elementsToScroll}
              handleScrollCertificateClick={this.handleScrollCertificateClick}
              handleScrollFeedbackClick={this.handleScrollFeedbackClick}
            />
            <Link to={ROUTES.USER_LANDING.replace(':id', identifier)}>
              <Image wrapperClassName={classes.logo} src={brandLogo} />
            </Link>
            <div className={classes.links}>
              <span
                style={{ '--color-var': fontColor }}
                className={classes.item}
                onClick={this.handleScrollCertificateClick}
              >
                {USER_LANDING.NAV.CERTIFICATES[language]}
              </span>
              <span
                style={{ '--color-var': fontColor }}
                className={classes.item}
                onClick={this.handleScrollFeedbackClick}
              >
                {USER_LANDING.NAV.FEEDBACK[language]}
              </span>
            </div>
          </nav>
          {this.getFullHeaderContent()}
        </div>
      </section>
    );
  }
}

function getContent(className, url, isVideo) {
  if (isVideo) {
    return (
      <YoutubeBackground
        className={className}
        videoId={url}
        opts={videoOptions}
        aspectRatio="4:3"
      />
    );
  }

  return (
    <Image
      wrapperClassName={className}
      className={classes.contentImage}
      src={url}
    />
  );
}

function formatText(text) {
  return text
    .split('.')
    .filter(Boolean)
    .map(sentence => <p>{sentence}.</p>);
}
