import { combineReducers } from 'redux';

import profile from './profile/reducer';
import form from './forms/reducer';
import router from './router/reducer';
import users from './users/reducer';
import landing from './landing/reducer';
import campaign from './campaign/reducer';
import certificate from './certificate/reducer';
import administration from './administration/reducer';

export default history =>
  combineReducers({
    form,
    profile,
    users,
    landing,
    campaign,
    certificate,
    administration,
    router: router(history),
  });
