import React, { Component } from 'react';
import { CertificateCard } from '../../components/CertificateCard';
import { CertificateCheckoutDetails } from '../../components/CertificateCheckoutDetails';
import BuyForm from '../../components/BuyForm';
import InfoBlock from '../../components/Landing/InfoBlock';
import styles from './BuyCerticatacePage.module.scss';
import classnames from 'classnames';
import { CERTIFICATE_TYPES } from '../../constants/labels';
import GalleryWidget from '../../components/GalleryWidget/GalleryWidget';
import { getFontColor } from '../../utils';

export default class BuyCerticatacePage extends Component {
  render() {
    const {
      certificateData,
      certificateData: { name },
      campaignData: {
        identifier,
        brandLogo,
        primaryColor,
        language,
        heading,
        backgroundColor,
        fontColor,
        certificateTypeByDefault,
        extraFormField,
        translation,
      },
      hasCertificateImages,
    } = this.props;
    const { isFontWhite } = getFontColor(backgroundColor);

    return (
      <div className={styles.buyCertificatePage} style={{ backgroundColor }}>
        <InfoBlock
          campaignId={identifier}
          backgroundColor={backgroundColor}
          wrapperClassName={styles.infoBlock}
          logo={brandLogo}
          heading={heading}
          isIconAdded={false}
          translation={translation}
        />
        <CertificateCheckoutDetails>
          <CertificateCard
            item={certificateData}
            logo={brandLogo}
            primaryColor={primaryColor}
            language={language}
            isBuyHidden
            isPreview
            isDisabled
            isShortDescription={false}
            backgroundColor={backgroundColor}
          />
        </CertificateCheckoutDetails>
        {hasCertificateImages && (
          <>
            <h3
              className={classnames(styles.galleryTitle, {
                [styles.galleryTitleWhite]: isFontWhite,
              })}
            >
              {name}
            </h3>
            <GalleryWidget
              certificateData={certificateData}
              backgroundColor={backgroundColor}
            />
          </>
        )}
        <BuyForm
          certificateTypeByDefault={
            certificateTypeByDefault || CERTIFICATE_TYPES.SELF
          }
          fontColor={fontColor}
          language={language}
          price={certificateData.price}
          color={primaryColor}
          certificateId={certificateData.id}
          extraFormField={extraFormField}
        />
      </div>
    );
  }
}
