import React, { Component } from 'react';
import { debounce } from 'lodash';
import { Button, Input, Tooltip, Popover } from 'antd';
import { ROUTES } from '../../../constants/routes';
import ExportCertificatesModal from './ExportCertificatesModal';
import CreateCertificateModal from '../CreateCertificateModal';
import * as classes from './Search.module.scss';

const { Search } = Input;

class SearchBar extends Component {
  debouncedGetCertificates = debounce(this.props.getCertificates, 500);

  state = {
    exportCertificatesOpen: false,
    createCertificateOpen: false,
  };

  async componentDidMount() {
    const {
      initialValue,
      userId,
      changeSearch,
      getCertificates,
      history,
    } = this.props;

    if (initialValue) {
      changeSearch(initialValue);
      await getCertificates(userId, 1, initialValue);
      history.push(ROUTES.CERTIFICATES);
    }
  }

  handleSearchChange = e => {
    const { changeSearch, userId } = this.props;
    const { value } = e.target;

    changeSearch(value);
    this.debouncedGetCertificates(userId, 1, value);
  };

  handleExportClick = () => {
    const { exportClients, userId } = this.props;

    exportClients(userId);
  };

  getExportCertificatesContent = () => {
    const { token, userId } = this.props;
    return (
      <ExportCertificatesModal
        userId={userId}
        token={token}
        handleHide={this.handleVisibleChange}
        visible={this.state.exportCertificatesOpen}
      />
    );
  };
  handleVisibleChange = visible => {
    this.setState({ exportCertificatesOpen: visible });
  };

  handleCreateCertificateToggle = () => {
    this.setState(prev => ({
      createCertificateOpen: !prev.createCertificateOpen,
    }));
  };
  render() {
    const { search, isExportVisible } = this.props;
    const { exportCertificatesOpen, createCertificateOpen } = this.state;

    return (
      <div className={classes.container}>
        <span className={classes.header}>Сертифікати</span>
        <div className={classes.wrapper}>
          {isExportVisible && (
            <>
              <Button onClick={this.handleExportClick}>Експорт клієнтів</Button>
              <Popover
                content={this.getExportCertificatesContent()}
                title="Експорт сертифікатів"
                trigger="click"
                visible={exportCertificatesOpen}
                onVisibleChange={this.handleVisibleChange}
              >
                <Button>Експорт сертифікатів</Button>
              </Popover>
            </>
          )}
          <Button
            className={classes.createCertificate}
            onClick={this.handleCreateCertificateToggle}
          >
            Створити сертифікат
          </Button>
          <CreateCertificateModal
            visible={createCertificateOpen}
            onClose={this.handleCreateCertificateToggle}
          />
          <Tooltip placement="topLeft" title="Пошук по сертифікатам і клієнтам">
            <Search
              placeholder="Пошук..."
              onChange={this.handleSearchChange}
              value={search}
              className={classes.search}
            />
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default SearchBar;
