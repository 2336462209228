import React from 'react';
import classnames from 'classnames';
import { Button } from 'antd';
import { Form, Field } from 'redux-form';
import { SelectField, TextAreaField, TextField } from 'redux-form-antd';
import * as classes from './Details.module.scss';
import FileUpload from '../FileUpload';
import ColorPicker from '../ColorPicker';
import {
  maxLength,
  minLength,
  required,
  youtube,
} from '../../../redux/validators';
import {
  CERTIFICATE_TYPES_OPTIONS,
  LANGUAGE_LABELS,
  LANGUAGE_OPTIONS,
} from '../../../constants/labels';
import AddLanguagePopup from '../AddLanguageModal';

const minInput = minLength(4);
const maxInput = maxLength(80);
const minTextarea = minLength(10);
const maxDescription = maxLength(500);
const maxAdditionalText = maxLength(1000);
const maxHeading = maxLength(100);

class Details extends React.Component {
  state = { isLanguageModalOpen: false, translation: null, language: null };

  handleModalOpen = () => {
    const { formValues } = this.props;
    this.setState({
      isLanguageModalOpen: true,
      language: formValues.language,
      translation: formValues.translation || {},
    });
  };

  handleModalClose = () => {
    this.setState({
      isLanguageModalOpen: false,
      language: null,
      translation: null,
    });
  };

  getLanguageTip = () => {
    const { formValues } = this.props;

    if (
      !formValues ||
      !Object.values(formValues.translation || {}).filter(Boolean).length
    ) {
      return null;
    }

    return (
      <span className={classes.notification}>
        Наразі добавлено переклад:{' '}
        {Object.keys(formValues.translation)
          .filter(l => !!formValues.translation[l])
          .map(l => LANGUAGE_LABELS[l])
          .join(', ')}
      </span>
    );
  };

  render() {
    const {
      footer = {},
      formValues,
      userId,
      campaigns,
      getCampaigns,
      changeField,
    } = this.props;
    const { isLanguageModalOpen, language, translation } = this.state;

    return (
      <Form onSubmit={() => ({})} className={classes.container}>
        <div className={classes.block}>
          <div className={classes.title}>Кампанія</div>
          <Field
            placeholder="Назва кампанії *"
            type="text"
            name="name"
            component={TextField}
            validate={[required, minInput, maxInput]}
          />
        </div>
        <div className={classes.block}>
          <div className={classes.title}>Мова лендінга</div>
          <Field
            placeholder="Мова лендінгу *"
            type="text"
            name="language"
            options={LANGUAGE_OPTIONS}
            onBlur={event => event.preventDefault()}
            component={SelectField}
            className={classes.select}
            validate={[required]}
          />
          <div className={classes.column}>
            <span className={classes.subtitle}>
              Додати переклад сторінки іншою мовою
            </span>
            <span className={classes.notification}>
              (Вам потрібно створити кампанію мовою, що ви бажаєте використати в
              якості перекладу цієї кампанії і прив&apos;язати її нижче.
              Доступні мови для використання: українська, англійська і
              російська)
            </span>
            <Button className={classes.button} onClick={this.handleModalOpen}>
              Додати переклад
            </Button>
            {this.getLanguageTip()}
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.title}>Тип сертифікату за промовчуванням</div>
          <div className={classes.column}>
            <span className={classes.notification}>
              (Значення, встановлене для ваших сертифікатів за замовчуванням.
              Користувачі зможуть самостійно обирати значення при покупці)
            </span>
            <Field
              placeholder="Тип сертифікату"
              type="text"
              name="certificateTypeByDefault"
              options={CERTIFICATE_TYPES_OPTIONS}
              onBlur={event => event.preventDefault()}
              component={SelectField}
            />
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.title}>Бренд</div>

          <div className={classes.column}>
            <span className={classes.subtitle}>Лого кампанії *</span>
            <Field
              validate={[required]}
              type="text"
              name="brandLogo"
              component={FileUpload}
            />
            <span className={classes.subtitle}>Фон кампанії</span>
            <span className={classes.notification}>
              (У випадку, коли вказано відео, то воно буде показуватись за
              промовчуванням як фон кампанії. Якщо сторніку кампанії відкрито з
              мобільного пристрою, то зображення буде встановленно в якості фону
              - в іншому випадку буде використано колір кампанії як колір
              заливки фону кампанії)
            </span>
            <span className={classes.subtitle}>Зображення</span>
            <Field type="text" name="brandCover" component={FileUpload} />
            <span className={classes.subtitle}>Відео</span>
            <Field
              type="text"
              name="brandVideo"
              component={TextField}
              validate={[youtube]}
            />
            <span className={classes.subtitle}>Додаткове лого</span>
            <span className={classes.notification}>
              (Це лого відображається в нижній частині сторінки поруч з
              контактними даними. Це поле не є обов'язквовим для заповнення)
            </span>
            <Field
              background={footer.color}
              type="text"
              name="footerLogo"
              component={FileUpload}
            />

            <span className={classes.subtitle}>Брендовий колір</span>
            <div className={classes.column}>
              <Field
                type="text"
                name="primaryColor"
                className={classes.color}
                component={ColorPicker}
                validate={[required]}
              />
            </div>

            <span className={classnames(classes.subtitle, classes.topIndent)}>
              Фон сторінки
            </span>
            <div className={classes.column}>
              <Field
                type="text"
                name="backgroundColor"
                className={classes.color}
                component={ColorPicker}
                validate={[required]}
              />
            </div>
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.title}>Заголовок</div>
          <Field
            placeholder="Заголовок кампанії *"
            type="text"
            name="heading"
            rows={2}
            component={TextAreaField}
            className={classes.textarea}
            validate={[required, minTextarea, maxHeading]}
          />
        </div>
        <div className={classes.block}>
          <div className={classes.title}>Опис</div>
          <Field
            placeholder="Опис кампанії *"
            type="text"
            name="description"
            rows={4}
            className={classes.textarea}
            component={TextAreaField}
            validate={[required, minTextarea, maxDescription]}
          />
        </div>
        <div className={classes.block}>
          <div className={classes.title}>Додатковий текст</div>
          <Field
            placeholder="Додатковий текст"
            type="text"
            name="additionalText"
            component={TextAreaField}
            rows={4}
            validate={[minTextarea, maxAdditionalText]}
          />
        </div>
        <AddLanguagePopup
          isOpen={isLanguageModalOpen}
          onCancel={this.handleModalClose}
          onOk={this.handleModalClose}
          getCampaigns={getCampaigns}
          formValues={formValues}
          userId={userId}
          changeField={changeField}
          campaigns={campaigns}
          translation={translation}
          language={language}
        />
      </Form>
    );
  }
}

export default Details;
