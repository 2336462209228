import { createStore, applyMiddleware, compose as simpleCompose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';

import apiMiddleware from './middlewares/api';
import wsMiddleware from './middlewares/ws';
import commonMiddleware from './middlewares/common';
import reducer from './reducer';
import rootSaga from './sagas';

export const history = createBrowserHistory();

export default function configureStore(api, _history) {
  const compose =
    process.env.NODE_ENV === 'production' ? simpleCompose : composeWithDevTools;

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    reducer(_history),
    compose(
      applyMiddleware(
        apiMiddleware(api),
        wsMiddleware(),
        commonMiddleware(),
        routerMiddleware(_history),
        sagaMiddleware,
      ),
    ),
  );

  sagaMiddleware.run(() => rootSaga(_history, api));

  return store;
}
