import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import classes from './ResetPasswordForm.module.scss';
import ResetPasswordForm from '../../components/ResetPasswordForm';

export default class ResetPasswordPage extends Component {
  static propTypes = {
    isChangedPwd: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  onSubmitChangePwd = data => {
    const {
      match: {
        params: { hash },
      },
    } = this.props;
    this.props.onSubmit({ ...data, hash });
  };

  render() {
    const {
      isChangedPwd,
      match: {
        params: { hash },
      },
    } = this.props;

    return (
      <div className={classes.container}>
        {hash && !isChangedPwd && (
          <ResetPasswordForm onSubmit={this.onSubmitChangePwd} />
        )}
        {isChangedPwd && (
          <>
            <div className={classes.column}>
              <FontAwesomeIcon className={classes.icon} icon={faCheckCircle} />
              <h3>Новий пароль успішно встановлено</h3>
              <p>
                Тепер ви можете <Link to={ROUTES.LOGIN}>увійти</Link> в
                особистий кабінет
              </p>
            </div>
          </>
        )}
      </div>
    );
  }
}
