import React from 'react';
import { Form, Button, Input, Modal } from 'antd';

import styles from './CreateUserModal.module.scss';

const CreateUserModal = props => {
  const {
    loading,
    handleCancel,
    createUser,
    updateUser,
    visible,
    initialValues,
  } = props;

  const onFinish = values => {
    if (initialValues.id) {
      updateUser({ ...values, id: initialValues.id });
    } else {
      createUser(values);
    }

    handleCancel();
  };

  const onFinishFailed = () => {};

  const onCancel = () => {
    handleCancel();
  };

  return (
    <Modal
      visible={visible}
      title="Створити користувача"
      onCancel={onCancel}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
        size="large"
      >
        <Form.Item
          type="text"
          name="firstName"
          label="Ім'я"
          className={styles.input}
        >
          <Input />
        </Form.Item>
        <Form.Item
          type="text"
          name="lastName"
          label="Прізвище"
          className={styles.input}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          type="text"
          label="Email"
          className={styles.input}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="company"
          type="text"
          label="Компанія"
          className={styles.input}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          type="text"
          label="Телефон"
          className={styles.input}
        >
          <Input />
        </Form.Item>
        <Form.Item className={styles.button}>
          <Button disabled={loading} htmlType="submit" type="primary">
            {initialValues.id ? 'Зберегти зміни' : 'Створити користувача'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUserModal;
