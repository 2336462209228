import React, { Component } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { Field } from 'redux-form';
import { Button, Tooltip, Empty } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { TextField, TextAreaField, SelectField } from 'redux-form-antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import CheckboxField from './Checkbox';
import * as classes from './CertificateTiers.module.scss';
import {
  minLength,
  maxLength,
  required,
  number,
  date,
  maxPrice,
} from '../../../redux/validators';
import DatePicker from '../DatePicker';
import ColorPicker from '../ColorPicker';
import AddImageModal from './AddImageModal/index';
import { CREATE_CERTIFICATE_TIERS } from '../../../redux/forms/names';
import { EXPIRATION_OPTIONS, EXPIRE_LABELS } from '../../../constants/labels';
import { MAX_PRICE_VALUE } from '../../../constants/texts';
import { formatPriceWithoutDecimals } from '../../../helpers/stringFormatters';
import DragSortingTable from './DraggableForm';

const minInput = minLength(4);
const maxInput = maxLength(60);
const minTextarea = minLength(10);
const maxDescription = maxLength(500);
const maxPriceValue = maxPrice(Math.round(MAX_PRICE_VALUE / 100));

class Form extends Component {
  state = {
    isEdit: false,
    index: 0,
    addImageModalOpen: false,
  };

  componentDidUpdate(prevProps) {
    const { createValues, changeField } = this.props;
    const { value, price, isPriceEqualToValue } = createValues;

    if (prevProps.createValues.value && !value && isPriceEqualToValue) {
      changeField(CREATE_CERTIFICATE_TIERS, 'isPriceEqualToValue', false);
    }

    if (value !== price && isPriceEqualToValue) {
      changeField(CREATE_CERTIFICATE_TIERS, 'price', value);
    }
  }
  handleToggleAddImageModalOpen = () => {
    this.setState(prev => ({
      addImageModalOpen: !prev.addImageModalOpen,
    }));
  };
  handleSamePriceClick = e => {
    const { checked } = e.target;
    const { changeField, createValues } = this.props;

    if (checked) {
      return changeField(CREATE_CERTIFICATE_TIERS, 'price', createValues.value);
    }

    return changeField(CREATE_CERTIFICATE_TIERS, 'price', 0);
  };

  handleSameDateClick = e => {
    const { checked } = e.target;
    const { changeField } = this.props;

    if (checked) {
      changeField(CREATE_CERTIFICATE_TIERS, 'commencementDate', '');
    }
  };

  handleEditClick = (record, index) => {
    const { changeField } = this.props;
    const {
      description,
      name,
      color,
      value,
      price,
      isPriceEqualToValue,
      commencementDate,
      expireDate,
      isDateSameAsBuyDate,
      isReusable,
      order,
      _id,
      images,
    } = record;

    changeField(CREATE_CERTIFICATE_TIERS, 'name', name);
    changeField(CREATE_CERTIFICATE_TIERS, 'order', order);
    changeField(CREATE_CERTIFICATE_TIERS, 'description', description);
    changeField(CREATE_CERTIFICATE_TIERS, 'color', color);
    changeField(CREATE_CERTIFICATE_TIERS, 'value', value);
    changeField(CREATE_CERTIFICATE_TIERS, 'price', price);
    changeField(CREATE_CERTIFICATE_TIERS, 'isReusable', isReusable);
    changeField(CREATE_CERTIFICATE_TIERS, 'commencementDate', commencementDate);
    changeField(CREATE_CERTIFICATE_TIERS, 'expireDate', expireDate);
    changeField(CREATE_CERTIFICATE_TIERS, 'images', images);
    changeField(
      CREATE_CERTIFICATE_TIERS,
      'isDateSameAsBuyDate',
      isDateSameAsBuyDate,
    );
    changeField(
      CREATE_CERTIFICATE_TIERS,
      'isPriceEqualToValue',
      Boolean(isPriceEqualToValue),
    );

    this.setState({ isEdit: true, row: index, recordId: _id || null });
  };

  handleUpdateClick = () => {
    const { row, recordId } = this.state;
    const { fields, onResetForm, createValues } = this.props;
    const valuesToSave = this.getSaveValues(createValues);

    fields.splice(row, 1, { ...valuesToSave, _id: recordId });
    this.setState({ isEdit: false });
    onResetForm();
  };

  handleDragDrop = data => {
    const { fields } = this.props;

    data.forEach((item, index) => {
      fields.splice(index, 1, { ...item, order: index });
    });
  };

  handleCreateClick = () => {
    const { fields, onResetForm, createValues } = this.props;
    const valuesToSave = this.getSaveValues(createValues);

    fields.push(valuesToSave);
    onResetForm();
  };

  handleSaveClick = () => {
    const { isEdit } = this.state;

    if (isEdit) {
      return this.handleUpdateClick();
    }

    return this.handleCreateClick();
  };

  getCommencementDateValidation = () => {
    const { createValues } = this.props;
    const { isEdit } = this.state;
    const { isDateSameAsBuyDate } = createValues;

    if (isEdit) {
      if (!isDateSameAsBuyDate) {
        return [required];
      }

      return [];
    }

    if (isDateSameAsBuyDate) {
      return [];
    }

    return [date, required];
  };
  getUploadImageName = () => {
    const { createValues } = this.props;
    const maxImageQty = 8;
    if (createValues.images && createValues.images.length) {
      return `Редагувати зображення (${createValues.images.length}/${maxImageQty})`;
    }
    return 'Додати зображення';
  };

  getSaveValues = values => {
    const { certificateTiers, ...rest } = values;
    const { filteredCertificates } = this.props;
    const { isEdit } = this.state;

    if (isEdit) {
      return {
        ...rest,
      };
    }

    return {
      ...rest,
      order: filteredCertificates.length,
    };
  };

  render() {
    const { fields, createValues, disabled, filteredCertificates } = this.props;
    const { isEdit } = this.state;
    const {
      certificateTiers = [],
      isPriceEqualToValue,
      isDateSameAsBuyDate,
      value,
    } = createValues;

    return (
      <div>
        <div className={classes.form}>
          <div className={classes.row}>
            <div className={classes.column}>
              <Field
                placeholder="Назва сертифікату *"
                type="text"
                name="name"
                component={TextField}
                validate={[required, minInput, maxInput]}
              />
              <Field
                placeholder="Умови використання сертифікату *"
                type="text"
                name="description"
                rows="6"
                className={classes.textarea}
                component={TextAreaField}
                validate={[required, minTextarea, maxDescription]}
              />
              <Field
                hasFeedback={false}
                name="isReusable"
                id="isReusable"
                component={CheckboxField}
                label="Можливість часткового списання вартості сертифікату"
              />
              <div className={classes.columnField}>
                <label
                  htmlFor="color"
                  className={classnames(classes.label, classes.rightIndent)}
                >
                  Оберіть колір *
                </label>
                <div className={classes.row}>
                  <Field
                    type="text"
                    name="color"
                    id="color"
                    className={classes.color}
                    component={ColorPicker}
                    validate={[required]}
                  />
                  <Button
                    onClick={this.handleToggleAddImageModalOpen}
                    className={classes.addImageButton}
                  >
                    {this.getUploadImageName()}
                  </Button>
                </div>
              </div>
            </div>
            <div className={classes.column}>
              <Field
                placeholder="Номінальна вартість *"
                type="text"
                name="value"
                component={TextField}
                validate={[required, number]}
              />
              <Field
                placeholder="Ціна продажу *"
                type="text"
                name="price"
                component={TextField}
                validate={[required, number, maxPriceValue]}
                className={classnames(classes.date, {
                  [classes.greyOut]: isPriceEqualToValue,
                })}
              />
              <Field
                hasFeedback={false}
                name="isPriceEqualToValue"
                id="isPriceEqualToValue"
                onClick={this.handleSamePriceClick}
                component={CheckboxField}
                disabled={!value}
                label="Ціна дорівнює номіналу"
              />
              <Field
                placeholder="Дата активації *"
                type="text"
                name="commencementDate"
                component={DatePicker}
                validate={this.getCommencementDateValidation()}
                className={classnames(classes.date, {
                  [classes.greyOut]: isDateSameAsBuyDate,
                })}
              />
              <Field
                hasFeedback={false}
                name="isDateSameAsBuyDate"
                id="isDateSameAsBuyDate"
                component={CheckboxField}
                onChange={this.handleSameDateClick}
                label="Активація в момент покупки"
              />
              <Field
                placeholder="Термін дії *"
                type="text"
                name="expireDate"
                options={EXPIRATION_OPTIONS}
                validate={[required]}
                onBlur={event => event.preventDefault()}
                component={SelectField}
                className={classes.date}
              />
              <AddImageModal
                visible={this.state.addImageModalOpen}
                toggleModal={this.handleToggleAddImageModalOpen}
              />
            </div>
          </div>
          <Button
            className={classes.button}
            disabled={disabled}
            onClick={this.handleSaveClick}
          >
            <PlusCircleOutlined className={classes.icon} />
            {isEdit ? 'Зберегти зміни' : 'Створити сертифікат'}
          </Button>
        </div>
        <div>
          <span className={classes.title}>Сертифікати</span>
          {certificateTiers.length ? (
            <DragSortingTable
              handleDragDrop={this.handleDragDrop}
              rowKey="uid"
              showHeader={false}
              pagination={false}
              dataSource={filteredCertificates}
              columns={[
                {
                  title: '',
                  dataIndex: 'name',
                  key: 'name',
                  render: (text, record) => (
                    <Tooltip
                      className={classes.main}
                      title={`${record.name} - ${record.color}`}
                      placement="top"
                    >
                      <i
                        style={{ background: record.color }}
                        className={classes.indicator}
                      />
                      <span>{record.name}</span>
                    </Tooltip>
                  ),
                },
                {
                  title: '',
                  dataIndex: 'description',
                  key: 'description',
                  render: (text, record) => (
                    <Tooltip
                      className={classes.description}
                      title={record.description}
                      placement="top"
                    >
                      {record.description}
                    </Tooltip>
                  ),
                },
                {
                  title: '',
                  dataIndex: 'price',
                  key: 'price',
                  render: (text, record) => (
                    <div className={classes.prices}>
                      <div>
                        Ціна: {formatPriceWithoutDecimals(record.price)}
                      </div>
                      <div>
                        Номінал: {formatPriceWithoutDecimals(record.value)}
                      </div>
                    </div>
                  ),
                },
                {
                  title: '',
                  dataIndex: 'expireDate',
                  key: 'expireDate',
                  render: (text, record) => (
                    <div className={classes.dates}>
                      <div>Активація: {getCommencementDate(record)}</div>
                      <div>
                        Термін дії:{' '}
                        {record.expireDate
                          ? EXPIRE_LABELS[record.expireDate]
                          : '-'}
                      </div>
                    </div>
                  ),
                },
                {
                  title: '',
                  dataIndex: 'action-edit',
                  key: 'action-edit',
                  render: (text, record, index) => (
                    <div>
                      <EditFilled
                        className={classes.editIcon}
                        onClick={() => this.handleEditClick(record, index)}
                      />
                      <Button
                        className={classes.editButton}
                        onClick={() => this.handleEditClick(record, index)}
                      >
                        Редагувати
                      </Button>
                    </div>
                  ),
                },
                {
                  title: '',
                  dataIndex: 'action-delete',
                  key: 'action-delete',
                  render: (text, record, index) => (
                    <div>
                      <DeleteFilled
                        className={classes.removeIcon}
                        onClick={() => fields.remove(index)}
                      />
                      <Button
                        className={classes.removeButton}
                        onClick={() => fields.remove(index)}
                      >
                        Видалити
                      </Button>
                    </div>
                  ),
                },
              ]}
            />
          ) : (
            <Empty description={<span>Немає сертифікатів</span>} />
          )}
        </div>
      </div>
    );
  }
}

function getCommencementDate(certificate) {
  if (certificate.isDateSameAsBuyDate) {
    return 'Дата покупки';
  }

  return certificate.commencementDate
    ? moment(certificate.commencementDate).format('l')
    : '-';
}

export default Form;
