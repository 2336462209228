import React from 'react';
import classname from 'classnames';
import { Spin } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import * as classes from './Image.module.scss';

export default class ImageLoading extends React.PureComponent {
  state = {
    isLoading: true,
    src: this.props.src,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.src !== state.src) {
      return {
        isLoading: true,
        src: props.src,
      };
    }

    return null;
  }

  handleLoad = () => {
    this.setState({ isLoading: false });
  };

  handleError = () => {
    this.setState({ isLoading: false });
  };

  render() {
    const { isLoading } = this.state;
    const {
      src,
      alt,
      className,
      background,
      wrapperClassName,
      onClick,
      onRemoveClick,
    } = this.props;

    return (
      <div
        className={classname(classes.wrapper, wrapperClassName)}
        style={{ background: background || 'transparent' }}
      >
        {onRemoveClick && (
          <DeleteFilled onClick={onRemoveClick} className={classes.remove} />
        )}
        <img
          src={src}
          alt={alt}
          className={className}
          onClick={onClick && onClick}
          onLoad={this.handleLoad}
          onError={this.handleError}
        />
        {isLoading && (
          <div className={classname(classes.cover, wrapperClassName)}>
            <Spin spinning={isLoading} />
          </div>
        )}
      </div>
    );
  }
}
