import React, { Component } from 'react';
import CertificateCard from '../../CertificateCard';
import styles from './CertificateTiers.module.scss';

class CertificateTiers extends Component {
  checkIsShortDescription = item => {
    const { tiers } = this.props;

    return (
      tiers.length !== 1 && item.description && item.description.length > 100
    );
  };

  render() {
    const {
      tiers,
      isPreview,
      logo,
      primaryColor,
      buttonFontColor,
      language,
      backgroundColor,
    } = this.props;

    return (
      <div className={getLayoutClass(tiers)}>
        {tiers.map(item => (
          <CertificateCard
            cardClassName={styles.tier}
            wrapperClassName={styles.container}
            descriptionClassName={styles.description}
            infoClassName={styles.info}
            language={language}
            key={item.id}
            item={item}
            logo={logo}
            isShortDescription={this.checkIsShortDescription(item)}
            isDisabled={isPreview}
            primaryColor={primaryColor}
            buttonFontColor={buttonFontColor}
            backgroundColor={backgroundColor}
          />
        ))}
      </div>
    );
  }
}

function getLayoutClass(tiers) {
  const { length } = tiers;
  if (length === 1) {
    return styles.singleTiersLayout;
  }

  if (length % 3 === 0 || length % 5 === 0) {
    return styles.shortTiersLayout;
  }

  if (length % 2 === 0) {
    return styles.longTiersLayout;
  }

  return styles.shortTiersLayout;
}

export default CertificateTiers;
