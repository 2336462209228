import React from 'react';
import { Spin } from 'antd';
import Filter from '../../components/Campaigns/Filter';
import Table from '../../components/Campaigns/Table';
import * as classes from './CampaignsPage.module.scss';

const CampaignsPage = ({ loading }) => (
  <Spin spinning={loading} className={classes.wrapper}>
    <Filter />
    <Table />
  </Spin>
);

export default CampaignsPage;
