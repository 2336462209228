import { createSelector } from 'reselect';
import { prefix } from './actions';
import { getReferralCode } from '../../localStorage';

export const root = state => state[prefix];
export const loading = state => state[prefix].loading;
export const isProfileRequested = state => state[prefix].isProfileRequested;
export const user = createSelector(root, state => state.user);
export const userId = state => state[prefix].user && state[prefix].user.id;
export const role = state => state[prefix].user && state[prefix].user.role;
export const isAdmin = state =>
  state[prefix].user && state[prefix].user.role === 'ADMIN';
export const error = state => state[prefix].error;
export const isConfirmed = state => state[prefix].isConfirmed;
export const isChangedPwd = state => state[prefix].isChangedPwd;
export const isRecoveryRequested = state => state[prefix].isRecoveryRequested;
export const successfullyRegistered = state =>
  state[prefix].successfullyRegistered;
export const liqpayPublicKey = state =>
  state[prefix].user && state[prefix].user.liqpayPublicKey;
export const liqpayAccountCreateData = state =>
  state[prefix].liqpayAccountCreateData;
export const isPaymentCreated = state => state[prefix].isPaymentCreated;

export const isAuthenticated = createSelector(user, user => !!user);

export const referralCode = () => {
  return getReferralCode();
};
