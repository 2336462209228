import React, { Component } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Link, Redirect } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { List } from 'antd';
import classes from './AdminContainer.module.scss';

export default class AdminContainer extends Component {
  static propTypes = exact({
    isAdmin: PropTypes.bool,
  });

  render() {
    const { isAdmin } = this.props;
    if (!isAdmin) {
      return <Redirect to={{ pathname: ROUTES.HOME }} />;
    }

    return (
      <div className={classes.container}>
        <List size="large" header="Налаштування адміна">
          <List.Item>
            <Link to={ROUTES.ADMIN_USERS}>Користувачі</Link>
          </List.Item>
          <List.Item>
            <Link to={ROUTES.ADMIN_REFERRAL}>Реферальна програма</Link>
          </List.Item>
        </List>
      </div>
    );
  }
}
