import React from 'react';
import styles from './HolidayGarland.module.scss';

export default class HolidayGarland extends React.PureComponent {
  render() {
    const { src, width, top, right } = this.props;

    return (
      <div className={styles.garlandMain}>
        <div
          className={styles.garlandCointainer}
          style={{ top: `${top}`, right: `${right}` }}
        >
          <img alt="HolidayGarland" src={src} style={{ width: `${width}%` }} />
        </div>
      </div>
    );
  }
}
