import { takeEvery } from 'redux-saga/effects';
import { notification } from 'antd';
import { PROFILE_FAILURE } from '../profile/actions';

export function* notify() {
  yield takeEvery('*', function (action) {
    const { data, type, error } = action;

    if (data && data.success) {
      const { message, description } = data.success;

      notification.success({ message, description }, 7);
    }

    if (error && type !== PROFILE_FAILURE) {
      const { fields } = error;
      const description = fields
        ? error.description || fields.description
        : null;

      notification.error(
        {
          message: 'Помилка!',
          description:
            description || "Щось пішло не так. Зв'яжіться з адміністратором",
        },
        7,
      );
    }
  });
}
