import React from 'react';
import { Modal } from 'antd';
import { Field, Form } from 'redux-form';
import { SelectField, TextAreaField, TextField } from 'redux-form-antd';
import { CERTIFICATE_TYPES_OPTIONS } from '../../../constants/labels';
import {
  notEmpty,
  required,
  email,
  number,
  minValue,
  minLength,
  maxLength,
} from '../../../redux/validators';
import classes from './CreateCertificateModal.module.scss';
import EmojiPicker from '../../EmojiPicker';
import { CREATE_CERTIFICATE } from '../../../redux/forms/names';

const minQuantity = minValue(1);
const minName = minLength(2);
const minText = minLength(5);
const maxText = maxLength(400);

export default class CreateCertificateModal extends React.Component {
  componentDidMount() {
    const { getShortCampaigns, campaigns, userId } = this.props;

    if (!campaigns.length) {
      getShortCampaigns(userId);
    }
  }

  render() {
    const {
      isGift,
      visible,
      disabled,
      campaigns,
      certificates,
      createValues,
    } = this.props;

    return (
      <Modal
        visible={visible}
        className={classes.modal}
        okButtonProps={{ disabled }}
        okText="Підтвердити"
        cancelText="Відміна"
        onCancel={this.handleClose}
        onOk={this.onSubmit}
      >
        <h2>Створити сертифікат</h2>
        <div className={classes.body}>
          <Form onFinish={this.onSubmit}>
            <div className={classes.block}>
              <Field
                type="text"
                name="campaign"
                placeholder="Кампанія"
                component={SelectField}
                options={campaigns}
                hasFeedback={false}
                validate={required}
                onBlur={this.handleBlur}
              />
            </div>
            <div className={classes.block}>
              <Field
                type="text"
                name="tier"
                placeholder="Сертифікат"
                component={SelectField}
                validate={required}
                options={certificates}
                hasFeedback={false}
                onBlur={this.handleBlur}
                disabled={!createValues.campaign}
              />
            </div>
            <div className={classes.block}>
              <Field
                type="number"
                name="quantity"
                placeholder="Кількість"
                component={TextField}
                hasFeedback={false}
                validate={[required, number, minQuantity]}
                disabled={!createValues.tier}
              />
            </div>
            <div className={classes.block}>
              <Field
                type="text"
                name="type"
                placeholder="Тип сертифіката"
                onBlur={this.handleBlur}
                validate={[required]}
                component={SelectField}
                hasFeedback={false}
                options={CERTIFICATE_TYPES_OPTIONS}
                disabled={!createValues.tier}
              />
            </div>
            <div className={classes.block}>
              <Field
                type="text"
                name="email"
                placeholder="Електронна пошта"
                component={TextField}
                hasFeedback={false}
                validate={[notEmpty, email, required]}
                disabled={!createValues.campaign}
              />
            </div>
            <div className={classes.block}>
              <Field
                type="text"
                name="from"
                placeholder="Від кого?"
                component={TextField}
                hasFeedback={false}
                validate={[notEmpty, minName, required]}
                disabled={!createValues.campaign}
              />
            </div>
            {isGift && (
              <>
                <div className={classes.block}>
                  <Field
                    type="text"
                    name="recipientEmail"
                    component={TextField}
                    hasFeedback={false}
                    validate={[notEmpty, email, required]}
                    placeholder="Пошта отримувача"
                  />
                </div>
                <div className={classes.block}>
                  <Field
                    className={classes.textarea}
                    type="text"
                    name="additionalInfo"
                    hasFeedback={false}
                    component={TextAreaField}
                    validate={[notEmpty, minText, maxText]}
                    placeholder="Персоналізоване повідомлення"
                  />
                  <div className={classes.emoji}>
                    <EmojiPicker onSelect={this.handleEmojiSelect} />
                  </div>
                </div>
              </>
            )}
          </Form>
        </div>
      </Modal>
    );
  }

  handleBlur = e => {
    e.preventDefault();
  };

  handleClose = () => {
    const { resetForm, onClose } = this.props;

    resetForm();
    onClose();
  };

  onSubmit = async () => {
    const { userId, createValues, createCertificate } = this.props;
    const {
      type,
      tier,
      email,
      quantity,
      from,
      campaign,
      recipientEmail,
      additionalInfo,
    } = createValues;

    const data = {
      email,
      userId,
      from,
      quantity,
      additionalInfo,
      campaignId: campaign,
      certificateType: type,
      certificateId: tier,
      recipientData: { recipientEmail, message: additionalInfo },
    };

    await createCertificate(data);
    this.handleClose();
  };

  handleEmojiSelect = emoji => {
    const { changeField, createValues } = this.props;
    const value = (createValues.additionalInfo || '') + emoji.native;

    changeField(CREATE_CERTIFICATE, 'additionalInfo', value);
  };
}
