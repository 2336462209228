import 'emoji-mart/css/emoji-mart.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmileBeam } from '@fortawesome/free-solid-svg-icons';
import { Popover } from 'antd';
import { Picker } from 'emoji-mart';

export default function EmojiPicker({ onSelect }) {
  return (
    <Popover
      content={
        <Picker
          emojiTooltip
          native
          i18n={{
            search: 'Пошук',
            categories: {
              search: 'Результат пошуку',
              recent: 'Нещодавні',
              smileys: 'Смайли і емоції',
              people: 'Люди',
              nature: 'Тварини і природа',
              foods: 'Їжа & Напої',
              activity: 'Активності',
              places: 'Подорожі & Місця',
              objects: "Об'єкти",
              symbols: 'Символи',
              flags: 'Прапори',
              custom: 'Кастомні',
            },
            categorieslabel: 'Категорії',
            notfound: 'Нічого не знайдено',
          }}
          title="Emoji"
          onSelect={onSelect}
        />
      }
      trigger="click"
    >
      <FontAwesomeIcon icon={faSmileBeam} />
    </Popover>
  );
}
