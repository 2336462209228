import Base from './base';

export default class Landing extends Base {
  getData(id) {
    return this.apiClient.get(`landing/${id}`);
  }

  getPaymentData(data) {
    return this.apiClient.post(`landing/payment`, { data });
  }

  successPaymentData(data) {
    return this.apiClient.post(`landing/payment-success`, { data });
  }

  submitContactForm(data) {
    return this.apiClient.post(`landing/contact`, { data });
  }

  paymentDataSave(data) {
    return this.apiClient.post(`landing/save-payment`, { data });
  }
}
