import React from 'react';
import { Spin } from 'antd';
import Table from '../../components/Certificates/Table';
import Search from '../../components/Certificates/Search';
import * as classes from './CertificatesPage.module.scss';

const CertificatesPage = ({ match, history, loading }) => (
  <Spin spinning={loading} className={classes.wrapper}>
    <Search history={history} initialValue={match.params.code} />
    <Table initialSearchValue={match.params.code} />
  </Spin>
);

export default CertificatesPage;
