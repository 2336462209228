import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { initialize } from 'redux-form';
import Table from './Table';
import {
  getCertificates,
  changePage,
  deactivate,
  use,
  setSelectedCertificateId,
  clearSelectedCertificateId,
  resendCertificate,
} from '../../../redux/certificate/actions';
import { userId } from '../../../redux/profile/selectors';
import { isFetchInitialCertificates } from '../../../redux/router/selectors';
import {
  total,
  page,
  loading,
  certificates,
  search,
  useCertificateFormValues,
  resendCertificateFormValues,
  error,
} from '../../../redux/certificate/selectors';

const selector = createStructuredSelector({
  userId,
  total,
  page,
  loading,
  search,
  certificates,
  error,
  formValues: useCertificateFormValues,
  resendformValues: resendCertificateFormValues,
  fetchInitialData: isFetchInitialCertificates,
});
const actions = {
  getCertificates,
  changePage,
  deactivate,
  use,
  setSelectedCertificateId,
  clearSelectedCertificateId,
  resendCertificate,
  initialize,
};

export default connect(selector, actions)(Table);
