import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import UsersTable from './UsersTable';
import { list, page, total } from '../../../redux/administration/selectors';
import {
  getList,
  changePage,
  updateStatus,
} from '../../../redux/administration/actions';
import { userId } from '../../../redux/profile/selectors';
import { getCampaigns } from '../../../redux/campaign/actions';

const mapStateToProps = createStructuredSelector({
  users: list,
  userId,
  page,
  total,
});
const mapDispatchToProps = {
  getList,
  changePage,
  updateStatus,
  getCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
