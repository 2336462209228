const prefix = 'common';
export const CHAT_REQUIRED = `${prefix}/CHAT_REQUIRED`;
export const SHOW_COOKIE_BANNER = `${prefix}/SHOW_COOKIE_BANNER`;
export const ACCEPT_COOKIE = `${prefix}/ACCEPT_COOKIE`;

export const requestChat = () => ({ type: CHAT_REQUIRED });

export const needToShowCookieBanner = () => ({ type: SHOW_COOKIE_BANNER });

export const acceptCookie = () => ({ type: ACCEPT_COOKIE });
