import React from 'react';
import { Form, Input, message } from 'antd';
import styles from '../HomePage.module.scss';
import { USER_LANDING } from '../../../constants/texts';

const validationRules = language => {
  return {
    name: [
      {
        required: true,
        message: USER_LANDING.CONTACT_FORM.ERROR_NAME[language],
      },
    ],
    email: [
      {
        required: true,
        message: USER_LANDING.CONTACT_FORM.ERROR_EMPTY_EMAIL[language],
      },
      {
        type: 'email',
        message: USER_LANDING.CONTACT_FORM.ERROR_WRONG_EMAIL[language],
      },
    ],
    message: [
      {
        required: true,
        message: USER_LANDING.CONTACT_FORM.ERROR_MESSAGE[language],
      },
    ],
  };
};

export default function ContactForm(props) {
  const [form] = Form.useForm();
  const { language = 'UK' } = props;

  const onFinish = async values => {
    const { submitContactForm } = props;
    try {
      await submitContactForm({ ...values });
      message.success(USER_LANDING.CONTACT_FORM.SUCCESS[language]);
      form.resetFields();
    } catch (e) {
      message.error(USER_LANDING.CONTACT_FORM.ERROR[language]);
    }
  };

  const onFinishFailed = () => {};

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      size="large"
      className={styles.contactForm}
    >
      <Form.Item
        type="text"
        name="name"
        rules={validationRules(language).name}
        validateTrigger="onBlur"
        className={styles.inputWrapper}
      >
        <Input
          className={styles.input}
          placeholder={USER_LANDING.CONTACT_FORM.NAME[language]}
        />
      </Form.Item>
      <Form.Item
        type="text"
        name="email"
        rules={validationRules(language).email}
        validateTrigger="onBlur"
        className={styles.inputWrapper}
      >
        <Input
          className={styles.input}
          placeholder={USER_LANDING.CONTACT_FORM.EMAIL[language]}
        />
      </Form.Item>
      <Form.Item
        type="text"
        name="message"
        rules={validationRules(language).message}
        validateTrigger="onBlur"
        className={styles.inputWrapper}
      >
        <Input.TextArea
          rows={4}
          className={styles.input}
          placeholder={USER_LANDING.CONTACT_FORM.HOME_MESSAGE[language]}
        />
      </Form.Item>

      <button className={styles.button} htmlType="submit" type="primary">
        {USER_LANDING.CONTACT_FORM.SUBMIT[language]}
      </button>
    </Form>
  );
}
