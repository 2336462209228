import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import CampaignsPage from './CampaignsPage';

import { loading } from '../../redux/campaign/selectors';

const selector = createStructuredSelector({
  loading,
});

export default connect(selector)(CampaignsPage);
