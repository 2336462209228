import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import HomePage from './HomePage';
import { homepageContact } from '../../redux/users/actions';
import { isAuthenticated } from '../../redux/profile/selectors';

const selector = createStructuredSelector({
  isAuthenticated,
});

const actions = { submitContactForm: homepageContact };

export { HomePage };

export default connect(selector, actions)(HomePage);
