import React from 'react';
import classes from '../NewCertificateBuyPage.module.scss';

const Quantity = ({ quantity, subtract, add }) => {
  return (
    <div className={classes.quantityInput}>
      <span onClick={subtract}>-</span>
      <span>{quantity}</span>
      <span onClick={add}>+</span>
    </div>
  );
};
export default Quantity;
