import Base from './base';

export default class Administration extends Base {
  get(ownerId, page) {
    return this.apiClient.get(`administration/${ownerId}/${page}`);
  }
  update(params) {
    return this.apiClient.put('administration', params);
  }
}
