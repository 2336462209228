import React, { Component } from 'react';
import { Button, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import classes from './UserAdministrationSettingsPage.module.scss';
import UsersTable from '../../components/Administration/UsersTable';

const spinStyle = {
  width: '100vw',
  height: '100vh',
  maxHeight: 'initial',
  zIndex: 2,
};

export default class UserAdministrationSettingsPage extends Component {
  state = {
    isOpen: false,
    selectedUser: {},
  };

  handleModalOpen = user => {
    const { adminId, getCampaigns } = this.props;

    this.setState({
      isOpen: !this.state.isOpen,
      selectedUser: user ? user : {},
    });

    getCampaigns(adminId);
  };

  render() {
    const { isOpen, selectedUser } = this.state;
    const { loading } = this.props;

    return (
      <div className={classes.container}>
        <Spin spinning={loading} size="large" style={spinStyle}>
          <div className={classes.column}>
            <FontAwesomeIcon className={classes.icon} icon={faUsers} />
            <h3 className={classes.title}>Адміністрування</h3>
          </div>
          <div className={classes.row}>
            <Button onClick={this.handleModalOpen} type="primary">
              Додати користувача
            </Button>
          </div>
          <UsersTable
            handleModalClick={this.handleModalOpen}
            isModalOpen={isOpen}
            selectedUser={selectedUser}
          />
        </Spin>
      </div>
    );
  }
}
