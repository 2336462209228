import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from '../../../utils';
import { loading } from '../../../redux/users/selectors';
import { userId } from '../../../redux/profile/selectors';
import { formCampaigns } from '../../../redux/campaign/selectors';
import {
  createUserByCampaignAdmin,
  updateUserByCampaignAdmin,
} from '../../../redux/users/actions';
import UserModal from './UserModal';

const selector = createStructuredSelector({
  loading,
  campaigns: formCampaigns,
  ownerId: userId,
});

const actions = {
  handleCreate: createUserByCampaignAdmin,
  handleUpdate: updateUserByCampaignAdmin,
};

const reduxConnect = connect(selector, actions);

export { UserModal };

export default withRouter(compose(reduxConnect)(UserModal));
