import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Input, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { isEmpty } from '../../helpers/verifying';

import styles from './SignupForm.module.scss';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROUTES } from '../../constants/routes';

const validationRules = {
  firstName: [{ required: true, message: "Будь ласка, введіть ваше ім'я" }],
  lastName: [{ required: true, message: 'Будь ласка, введіть ваше прізвище' }],
  email: [
    { required: true, message: 'Будь ласка, введіть вашу електронну пошту' },
    {
      type: 'email',
      message: 'Будь ласка, введіть правильну електронну пошту',
    },
  ],
  phone: [
    { required: true, message: 'Будь ласка, введіть ваш телефон' },
    {
      min: 10,
      max: 13,
      message: 'Будь ласка, введіть правильний телефон',
    },
  ],
  company: [{ required: true, message: 'Будь ласка, введіть назву компанії' }],
  password: [
    {
      required: true,
      min: 8,
      message: 'Пароль повинен містити щонайменше 8 символів',
    },
  ],
  confirmPassword: [
    {
      required: true,
      message: 'Будь ласка, підтвердіть пароль',
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject('Паролі не співпадають');
      },
    }),
  ],
  acceptTerms: [
    {
      required: true,
      type: 'boolean',
    },
    () => ({
      validator(rule, checked) {
        if (!checked)
          return Promise.reject(
            'Для реєстрації вам необхідно прийняти умови сервісу',
          );
        return Promise.resolve();
      },
    }),
  ],
};

export default class SignupForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetSuccessState: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };

  componentDidMount() {
    const { history, referral, setReferral } = this.props;
    const { location, replace } = history;

    if (referral) {
      setReferral(referral);

      return history.push(ROUTES.SIGNUP);
    }

    if (!isEmpty(location.query) && location.query.email) {
      this.props.initialize({
        email: location.query.email,
      });

      replace('/signup');
    }
  }

  componentWillUnmount() {
    this.props.resetSuccessState();
  }

  onFinish = values => {
    const { code, onSubmit } = this.props;
    const userToSave = { ...values };

    if (code) {
      userToSave.referral = code;
    }

    onSubmit(userToSave);
  };

  onFinishFailed = () => {};

  render() {
    const { signupSuccess, loading } = this.props;
    const formStyleName = signupSuccess ? 'form success' : 'form';

    return (
      <Form
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        className={formStyleName}
        initialValues={{ acceptTerms: false }}
      >
        <div className="register-success">
          <FontAwesomeIcon className={styles.success} icon={faCheckCircle} />
          <h3 className={styles.header}>Реєстрація успішна!</h3>
          <p>
            Наступний крок - підтвердіть адресу електронної пошти за допомогою
            листа, який ми щойно Вам надіслали.
          </p>
        </div>

        <h1 className={styles.title}>Реєстрація</h1>

        <Form.Item
          type="text"
          name="firstName"
          label="Ім'я"
          className={styles.input}
          rules={validationRules.firstName}
          validateTrigger="onBlur"
        >
          <Input />
        </Form.Item>
        <Form.Item
          type="text"
          name="lastName"
          label="Прізвище"
          className={styles.input}
          rules={validationRules.lastName}
          validateTrigger="onBlur"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          type="text"
          label="Електронна пошта"
          className={styles.input}
          rules={validationRules.email}
          validateTrigger="onBlur"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          type="text"
          label="Телефон"
          className={styles.input}
          rules={validationRules.phone}
          validateTrigger="onBlur"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="company"
          type="text"
          label="Назва компанії"
          className={styles.input}
          rules={validationRules.company}
          validateTrigger="onBlur"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          type="password"
          label="Пароль"
          className={styles.input}
          rules={validationRules.password}
          validateTrigger="onBlur"
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          type="password"
          label="Повторіть пароль"
          className={styles.input}
          rules={validationRules.confirmPassword}
          validateTrigger="onBlur"
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="acceptTerms"
          className={styles.input}
          valuePropName="checked"
          rules={validationRules.acceptTerms}
          validateTrigger="onSubmit"
        >
          <Checkbox>
            Я надаю згоду на обробку моїх персональних даних згідно із{' '}
            <Link to={ROUTES.TERMS} target="_blank">
              Політикою конфіденційності Upfront
            </Link>
            , та ознайомлений і погоджуюсь з умовами{' '}
            <Link to={ROUTES.AGREEMENT} target="_blank">
              Публічної оферти Upfront
            </Link>
            .
          </Checkbox>
        </Form.Item>

        <Form.Item className={styles.button}>
          <Button disabled={loading} htmlType="submit" type="primary">
            Зареєструватися
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
