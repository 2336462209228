import React from 'react';
import HomeFooter from './HomeFooter';
import LandingFooter from './LandingFooter';
import NewLandingFooter from '../NewLandingFooter';

export default function BrandFooter({
  campaignId,
  campaignData,
  isLandingPage,
}) {
  const { identifier, newDesign } = campaignData;

  if (newDesign && (identifier || campaignId === 'new')) {
    return (
      <NewLandingFooter campaignId={campaignId} campaignData={campaignData} />
    );
  }

  if (campaignId === 'new' || identifier) {
    return (
      <LandingFooter campaignId={campaignId} campaignData={campaignData} />
    );
  }

  if (!isLandingPage) {
    return <HomeFooter />;
  }

  return null;
}
