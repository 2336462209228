import React, { Component } from 'react';
import classnames from 'classnames';
import { Layout, ConfigProvider, Empty } from 'antd';
import MainHeader from '../../components/MainHeader/MainHeader';
import BrandFooter from '../../components/BrandFooter';
// import Helmet from '../../components/Helmet';
import classes from './MainLayout.module.scss';
import CookiesBanner from '../../components/CookieBanner';

const { Content } = Layout;

const CustomEmpty = () => (
  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Поки немає данних" />
);

export default class MainLayout extends Component {
  componentDidMount() {
    const { location, init, setToken } = this.props;

    if (location.query.token) {
      return setToken(location.query.token);
    }

    init();
  }

  getHeader = () => {
    const {
      user,
      pathname,
      isHomePage,
      isLandingPage,
      isPreviewPage,
      isAdmin,
      logout,
    } = this.props;

    return (
      <MainHeader
        user={user}
        pathname={pathname}
        logout={logout}
        isAdmin={isAdmin}
        isHomePage={isHomePage}
        isHidden={isLandingPage || isPreviewPage || isHomePage}
      />
    );
  };

  getFooter = () => {
    const { isLandingPage, isPreviewPage } = this.props;

    return <BrandFooter isLandingPage={isLandingPage || isPreviewPage} />;
  };

  getContent = () => {
    const { isHomePage, children } = this.props;

    return (
      <Content
        className={classnames(classes.content, { [classes.home]: isHomePage })}
      >
        {children}
      </Content>
    );
  };

  render() {
    const { isPreviewPage, isCookiesTermsUsagesAccepted } = this.props;

    return (
      <ConfigProvider renderEmpty={CustomEmpty}>
        <Layout className={classes.root}>
          {/*<Helmet />*/}
          {this.getHeader()}
          <div
            className={classnames({
              [classes.preview]: isPreviewPage,
            })}
          >
            {this.getContent()}
            {this.getFooter()}
          </div>
          {!isCookiesTermsUsagesAccepted && <CookiesBanner />}
        </Layout>
      </ConfigProvider>
    );
  }
}
