import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getPaymentData } from '../../redux/landing/actions';
import { isPaymentBlocked } from '../../redux/landing/selectors';

import BuyForm from './BuyForm';

const selector = createStructuredSelector({
  isPaymentBlocked,
});

const actions = { getPaymentData };

export { BuyForm };

export default connect(selector, actions)(BuyForm);
