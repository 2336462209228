import { formatDate } from '../../helpers/stringFormatters';

const STATUSES = {
  ACTIVE: 'Aктивна',
  NOT_ACTIVE: 'Неактивна',
};

export function prepareCampaignForTableRow(campaign) {
  const { views, orders, status, createdAt, slug, id, ...rest } = campaign;

  return {
    ...rest,
    id,
    slug,
    type: status,
    identifier: slug || id,
    status: STATUSES[status],
    createdAt: formatDate(createdAt),
    detections: [`Замовлень: ${orders}`, `Переглядів: ${views}`],
  };
}

export function populateCampaignWithFormValues(
  campaign,
  additionalValues = {},
  additionalCertificateValues = {},
) {
  const extraFormField = campaign.extraFormField || {};

  return {
    ...campaign,
    fieldLabel: extraFormField.fieldLabel || '',
    isFieldRequired: Boolean(extraFormField.isFieldRequired),
    certificateTiers: campaign.certificateTiers.map(certificate => ({
      ...certificate,
      price: certificate.price / 100,
      value: certificate.value / 100,
      ...additionalCertificateValues,
    })),
    ...additionalValues,
  };
}
