import React, { Component } from 'react';
import { Descriptions, Button, Modal } from 'antd';
import EditForm from '../../components/Profile/EditForm/EditForm';
import classnames from './ProfilePage.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard } from '@fortawesome/free-solid-svg-icons';

export default class ProfilePage extends Component {
  state = { open: false };

  handleModalOpen = () => {
    this.setState({ open: true });
  };

  handleModalClose = () => {
    const {
      initializeForm,
      user: { firstName, lastName, email, company, phone, id },
    } = this.props;
    this.setState({ open: false });
    initializeForm({ firstName, lastName, email, company, phone, id });
  };

  handleSubmitClick = async params => {
    const { updateProfile } = this.props;

    try {
      await updateProfile(params);
    } catch (e) {
      return;
    }

    this.handleModalClose();
  };

  render() {
    const { open } = this.state;
    const {
      loading,
      user: {
        firstName,
        lastName,
        email,
        company,
        phone,
        id,
        isPaymentBlocked,
      },
    } = this.props;
    return (
      <div className={classnames.container}>
        <div className={classnames.column}>
          <FontAwesomeIcon className={classnames.icon} icon={faIdCard} />
          <h3 className={classnames.title}>Мій профіль</h3>
        </div>
        <Descriptions className={classnames.formWrapper} bordered>
          <Descriptions.Item span={3} label="Ім'я">
            {firstName}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Прізвище">
            {lastName}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Електронна пошта">
            {email}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Телефон">
            {phone}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Компанія">
            {company}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Статус продажів">
            {isPaymentBlocked ? 'Призупинено' : 'Запущено'}
          </Descriptions.Item>
        </Descriptions>
        <Button onClick={this.handleModalOpen}>Редагувати профіль</Button>
        <Modal
          destroyOnClose
          onCancel={this.handleModalClose}
          visible={open}
          title="Редагування профілю"
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <EditForm
            loading={loading}
            onSubmit={this.handleSubmitClick}
            initialValues={{
              firstName,
              lastName,
              email,
              company,
              phone,
              id,
              switch: !isPaymentBlocked,
            }}
          />
        </Modal>
      </div>
    );
  }
}
