import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  liqpayAccountCreateData,
  isPaymentCreated,
  userId,
} from '../../redux/profile/selectors';
import { liqpayAccountCreate } from '../../redux/profile/actions';
import { getCampaigns } from '../../redux/campaign/actions';
import { campaigns } from '../../redux/campaign/selectors';

import LiqpayAccountCreatePage from './LiqpayAccountCreatePage';

const selector = createStructuredSelector({
  liqpayAccountCreateData,
  isPaymentCreated,
  userId,
  campaigns,
});

const actions = { liqpayAccountCreate, getCampaigns };

export { LiqpayAccountCreatePage };

export default connect(selector, actions)(LiqpayAccountCreatePage);
