import React, { Component } from 'react';
import classnames from 'classnames';
import { USER_LANDING } from '../../constants/texts';
import { ROUTES } from '../../constants/routes';
import styles from './NewSuccessCertificatePage.module.scss';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { getFontColor } from '../../utils';
import { getCommencementDate, getExpireDate } from '../../helpers/common';

export default class SuccessCertificatePage extends Component {
  render() {
    const {
      campaignData,
      paidCertificate,
      history,
      purchaseMessage,
      certificateData,
    } = this.props;
    const {
      language,
      identifier,
      fontColor,
      primaryColor,
      backgroundColor,
      certificatesCount,
    } = campaignData;

    if (!paidCertificate) {
      history.push(ROUTES.USER_LANDING.replace(':id', identifier));
      return null;
    }

    const { isFontWhite } = getFontColor(backgroundColor);

    return (
      <div
        className={classnames(styles.successCertificatePage, {
          [styles.whiteFonts]: isFontWhite,
        })}
        style={{ backgroundColor }}
      >
        <div className={styles.info}>
          <h3 className={styles.thanksTitle}>{purchaseMessage}</h3>
          <p className={styles.thanksInfo}>
            {certificatesCount === 1
              ? USER_LANDING.PAYMENT.CERTIFICATE[language]
              : USER_LANDING.PAYMENT.CERTIFICATES[language]}
            {getCommencementDate({ certificateData, campaignData })}{' '}
            {getExpireDate({ certificateData, campaignData })}
          </p>
          <p className={styles.additionalInfo}>
            {USER_LANDING.PAYMENT.SPAM[language]}
          </p>
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              htmlType="submit"
              type="primary"
              style={{ backgroundColor: primaryColor, color: fontColor }}
            >
              <Link to={ROUTES.USER_LANDING.replace(':id', identifier)}>
                {USER_LANDING.PAYMENT.BUY_ANOTHER[language]}
              </Link>
            </Button>
            <Button
              className={styles.button}
              htmlType="submit"
              type="primary"
              style={{
                backgroundColor: 'transparent',
                color: primaryColor,
                border: `3px solid ${primaryColor}`,
              }}
            >
              <Link to={ROUTES.HELP}>
                {USER_LANDING.PAYMENT.SUPPORT[language]}
              </Link>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
