import React, { Component } from 'react';
import { ROUTES } from '../../constants/routes';
import InfoBlock from '../../components/Landing/InfoBlock';
import { CertificateCard } from '../../components/CertificateCard';
import { CertificateCheckoutDetails } from '../../components/CertificateCheckoutDetails';
import styles from './PaymentCertificatePage.module.scss';
import { ELEMENTS_IDS } from '../../constants/labels';

const LIQPAY_LIB = 'liqpayCheckoutLib';
const LIQPAY_STATUSES = {
  SUCCESS: 'success',
  WAIT_COMPENSATION: 'wait_compensation',
};

function isSuccess(status) {
  return (
    status === LIQPAY_STATUSES.SUCCESS ||
    status === LIQPAY_STATUSES.WAIT_COMPENSATION
  );
}

export default class PaymentCertificatePage extends Component {
  componentDidMount() {
    const {
      paymentIntentionData,
      campaignData = { language: 'UK' },
    } = this.props;

    window.LiqPayCheckoutCallback = () => {
      window.LiqPayCheckout &&
        window.LiqPayCheckout.init({
          mode: 'embed',
          embedTo: `#${ELEMENTS_IDS.PAYMENT_FORM}`,
          data: paymentIntentionData.data,
          signature: paymentIntentionData.signature,
          language: campaignData.language.toLowerCase(),
        }).on('liqpay.callback', this.handleSuccess);
    };

    this.createLiqpayScript();
  }

  componentWillUnmount() {
    this.deleteLiqpayScript();
  }

  deleteLiqpayScript = () => {
    const liqpayScript = document.getElementById(LIQPAY_LIB);

    liqpayScript.remove();
  };

  createLiqpayScript = () => {
    const script = document.createElement('script');

    script.setAttribute('id', LIQPAY_LIB);

    script.src = '//static.liqpay.ua/libjs/checkout.js';
    script.async = true;

    document.body.appendChild(script);
  };

  handleSuccess = data => {
    const { certificateData, showSuccessPaymentScreen } = this.props;

    if (data) {
      const { status } = data;

      if (isSuccess(status)) {
        showSuccessPaymentScreen({
          campaignId: certificateData.campaignId,
          certificateId: certificateData.id,
        });
      }
    }
  };

  render() {
    const {
      history,
      certificateData,
      paymentIntentionData,
      campaignData: {
        brandLogo,
        heading,
        primaryColor,
        identifier,
        language,
        backgroundColor,
        translation,
      },
    } = this.props;

    if (!paymentIntentionData || !paymentIntentionData.signature) {
      history.push(ROUTES.USER_LANDING.replace(':id', identifier));

      return null;
    }

    return (
      <div className={styles.buyCertificatePage} style={{ backgroundColor }}>
        <InfoBlock
          backgroundColor={backgroundColor}
          campaignId={identifier}
          wrapperClassName={styles.infoBlock}
          logo={brandLogo}
          heading={heading}
          isIconAdded={false}
          translation={translation}
        />
        <CertificateCheckoutDetails>
          <CertificateCard
            item={certificateData}
            logo={brandLogo}
            primaryColor={primaryColor}
            language={language}
            isBuyHidden
            isPreview
            isDisabled
            isShortDescription={false}
            backgroundColor={backgroundColor}
          />
        </CertificateCheckoutDetails>
        <div className={styles.paymentWidget} id={ELEMENTS_IDS.PAYMENT_FORM} />
      </div>
    );
  }
}
