import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { campaignData, loading } from '../../redux/landing/selectors';
import { campaignId, isPreviewPage } from '../../redux/router/selectors';
import { getLandingData, getPreviewData } from '../../redux/landing/actions';
import LandingContainer from './LandingContainer';

const selector = createStructuredSelector({
  loading,
  campaignData,
  campaignId,
  isPreview: isPreviewPage,
});

const actions = { getLandingData, getPreviewData };

export default connect(selector, actions)(LandingContainer);
