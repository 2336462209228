import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Color from 'color';
import styles from './CertificateCard.module.scss';
import Icon from './Icon';
import { formatPrice } from '../../helpers/stringFormatters';
import { USER_LANDING } from '../../constants/texts';
import { getFontColor, getRoute } from '../../utils';

class CertificateCard extends React.Component {
  getButtonText = () => {
    const { language, item: { price, value } = {} } = this.props;

    if (Number(price) < Number(value)) {
      return USER_LANDING.DISCOUNT_CERTIFICATE_BUTTON[language];
    }

    return USER_LANDING.CERTIFICATE_BUTTON[language];
  };

  getOldPrice = () => {
    const {
      item: { price, value },
      language,
    } = this.props;

    if (Number(price) < Number(value)) {
      return (
        <span className={styles.oldPrice}>{formatPrice(value, language)}</span>
      );
    }

    return null;
  };

  getStyles = () => {
    const { item: { color } = {} } = this.props;

    return {
      background: `linear-gradient(112.13deg, rgba(${Color(color)
        .rgb()
        .array()
        .join()}, 0.1) 15.53%, rgba(${Color(color)
        .rgb()
        .array()
        .join()}, 0.05) 88.64%), #fff`,
    };
  };

  render() {
    const {
      logo,
      item: { name, color, price, description } = {},
      cardClassName = '',
      wrapperClassName = '',
      descriptionClassName = '',
      infoClassName = '',
      primaryColor,
      isBuyHidden = false,
      isPreview = false,
      buttonFontColor = null,
      isShortDescription,
      backgroundColor,
      language,
    } = this.props;

    const { isFontWhite } = getFontColor(backgroundColor);

    return (
      <div className={classnames(styles.wrapper, wrapperClassName)}>
        <Link
          to={getRoute(this.props)}
          style={this.getStyles()}
          className={classnames(styles.card, cardClassName, {
            [styles.preview]: isPreview,
          })}
        >
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
          </div>
          <div className={classnames(styles.info, infoClassName)}>
            <div className={styles.title}>{name}</div>
            <div className={styles.price} style={{ color: primaryColor }}>
              {formatPrice(price, language)}
            </div>
            {this.getOldPrice()}
            <Link
              style={{ background: primaryColor, color: buttonFontColor }}
              to={getRoute(this.props)}
            >
              {this.getButtonText()}
            </Link>
          </div>
          <div className={styles.icon}>
            <Icon color={color} />
          </div>
        </Link>
        <div
          className={classnames(
            classnames(styles.description, descriptionClassName, {
              [styles.white]: isFontWhite,
            }),
          )}
        >
          <p className={styles.desktop} data-title={description}>
            {isShortDescription
              ? description.substring(0, 100) + '...'
              : description}
          </p>
          <p className={styles.mobile}>{description}</p>

          {!isBuyHidden && (
            <Link
              style={{ background: primaryColor, color: buttonFontColor }}
              to={getRoute(this.props)}
            >
              {this.getButtonText()}
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default CertificateCard;
