import { ROUTES } from './routes';

export const MAX_PRICE_VALUE = 2500000;

export const USER_LANDING = {
  ABOUT: {
    UK: 'Про компанію',
    RU: 'О компании',
    EN: 'About business',
  },
  CURRENCY: {
    UK: 'грн',
    RU: 'грн',
    EN: 'uah',
  },
  ABOUT_HIDE: {
    UK: 'Сховати',
    RU: 'Скрыть',
    EN: 'Hide',
  },
  FOOTER_POLICY: {
    UK: 'Політика конфіденційності',
    RU: 'Политика конфиденциальности',
    EN: 'Privacy Policy',
  },
  FOOTER_OFFER: {
    UK: 'Публічна оферта',
    RU: 'Публичная оферта',
    EN: 'Terms of service',
  },
  CHOOSE_CERTIFICATE: {
    UK: 'Оберіть сертифікат!',
    RU: 'Выберите сертификат',
    EN: 'Choose certificate!',
  },
  CHOOSE_COUNT: {
    UK: 'Оберіть кількість сертифікатів',
    RU: 'Выберите количество сертификатов',
    EN: 'Select quantity of certificates',
  },
  CERTIFICATE_BUTTON: {
    UK: 'Придбати',
    RU: 'Купить',
    EN: 'Buy',
  },
  DISCOUNT_CERTIFICATE_BUTTON: {
    UK: 'Придбати зі знижкою',
    RU: 'Купить со скидкой',
    EN: 'Buy with discount',
  },
  CERTIFICATE_BALANCE: {
    UK: 'Баланс сертифікату',
    RU: 'Баланс сертификата',
    EN: 'Certificate balance',
  },
  MONEY_BALANCE: {
    UK: 'Залишок грошей',
    RU: 'Остаток денег',
    EN: 'Money balance',
  },
  CONTACT_FORM: {
    CONTACT_TITLE: {
      UK: "Зворотній зв'язок",
      RU: 'Обратная связь',
      EN: 'Feedback',
    },
    HEADER: {
      UK: 'Дізнатися більше',
      RU: 'Узнайте больше',
      EN: 'Find out more ',
    },
    DESCRIPTION: {
      UK: 'Ми вам зателефонуємо і відповімо на будь-які запитання',
      RU: 'Мы вам позвоним и ответим на все вопросы',
      EN: "We'll call you back and answer any questions you have",
    },
    NAME: {
      UK: "Ім'я",
      RU: 'Имя',
      EN: 'First name',
    },
    LAST_NAME: {
      UK: 'Прізвище',
      RU: 'Фамилия',
      EN: 'First name',
    },
    EMAIL: {
      UK: 'Електронна пошта',
      RU: 'Электронный адрес',
      EN: 'Email',
    },
    RECIPIENT_LABEL: {
      UK: 'Для кого ви купуєте сертифікат:',
      RU: 'Для кого вы покупаете сертификат:',
      EN: 'Whom are you buying for:',
    },
    RECIPIENT_OPTION1: {
      UK: 'Для себе',
      RU: 'Для себя',
      EN: 'For personal use',
    },
    RECIPIENT_OPTION2: {
      UK: 'У подарунок',
      RU: 'В подарок',
      EN: 'As a gift',
    },
    RECIPIENT_EMAIL: {
      UK: 'Електронна пошта отримувача',
      RU: 'Электронный адрес получателя',
      EN: "Recipient's email",
    },
    PHONE: {
      UK: 'Телефон',
      RU: 'Телефон',
      EN: 'Phone number',
    },
    COUNT: {
      UK: 'Оберіть кількість сертифікатів:',
      RU: 'Выберите количество сертификатов:',
      EN: 'Select quantity of certificates:',
    },
    MESSAGE: {
      UK: 'Персоналізоване повідомлення',
      RU: 'Персонализированное сообщение',
      EN: 'Custom message',
    },
    HOME_MESSAGE: {
      UK: 'Коментар',
      RU: 'Комментарий',
      EN: 'Comment',
    },
    SUCCESS: {
      UK: "Дякуємо за ваш запит! Ми зв'яжемося з вами найближчим часом",
      RU: 'Спасибо за ваш запрос! Мы свяжемся с вами в ближайшее время',
      EN: 'I accept the Terms of service and the Privacy policy ',
    },
    ERROR: {
      UK: 'Щось пішло не так',
      RU: 'Что-то пошло не так',
      EN: 'Something went wrong',
    },
    ERROR_NAME: {
      UK: "Будь ласка, введіть ваше ім'я",
      RU: 'Пожалуйста, введите ваше имя',
      EN: 'Please enter your first name',
    },
    ERROR_LAST_NAME: {
      UK: 'Будь ласка, введіть ваше прізвище',
      RU: 'Пожалуйста, введите вашу фамилию',
      EN: 'Please enter your last name',
    },
    ERROR_ADDITIONAL_FIELD: {
      UK: 'Будь ласка, не залишайте поле пустим',
      RU: 'Пожалуйста, не оставляйте поле пустым',
      EN: 'This field is required',
    },
    ERROR_COUNT: {
      UK: 'Будь ласка, виберіть кількість більше 1',
      RU: 'Пожалуйста, выберите количество больше 1',
      EN: 'Please enter quantity more than one',
    },
    ERROR_WRONG_EMAIL: {
      UK: 'Будь ласка, введіть правильну електронну пошту',
      RU: 'Пожалуйста, введите правильный электронный адрес',
      EN: 'Please enter valid email',
    },
    ERROR_EMPTY_EMAIL: {
      UK: 'Будь ласка, введіть ваш електронну пошту',
      RU: 'Пожалуйста, введите ваш электронный адрес',
      EN: 'Please enter your email',
    },
    ERROR_EMPTY_RECIPIENT_EMAIL: {
      UK: 'Будь ласка, введіть електронну пошту отримувача',
      RU: 'Пожалуйста, введите электронный адрес получателя',
      EN: "Please enter recipient's email email",
    },
    ERROR_WRONG_RECIPIENT_EMAIL: {
      UK: 'Будь ласка, введіть електронну пошту отримувача',
      RU: 'Пожалуйста, введите электронный адрес получателя',
      EN: "Please enter recipient's email email",
    },
    ERROR_PHONE: {
      UK: 'Будь ласка, введіть правильний телефон',
      RU: 'Пожалуйста, введите правильный телефон',
      EN: 'Please enter your phone',
    },
    ERROR_MESSAGE: {
      UK: 'Будь ласка, введіть ваше повідомлення',
      RU: 'Пожалуйста, введите ваше сообщение',
      EN: 'Please enter your message',
    },
    SUBMIT: {
      UK: 'Надіслати',
      RU: 'Отправить',
      EN: 'Submit',
    },
    CONTINUE: {
      UK: 'Продовжити',
      RU: 'Далее',
      EN: 'Proceed',
    },
    TERMS: color => {
      return {
        UK: `Ви ознайомлені і погоджуєтесь із умовами<a style="color:${color}  !important;" href="${ROUTES.AGREEMENT}" target="_blank">Публічної оферти Upfront</a>та<a style="color:${color}  !important;"  href="${ROUTES.TERMS}" target="_blank">Політики конфіденційності Upfront</a>`,
        RU: `Вы ознакомлены и согласны с условиями<a style="color:${color}  !important;" href="${ROUTES.AGREEMENT}" target="_blank">Публичной оферты Upfront</a>и<a style="color:${color}  !important;"  href="${ROUTES.TERMS}" target="_blank">Политики конфиденциальности Upfront</a>`,
        EN: `I accept the<a style="color:${color} !important;"  href="${ROUTES.AGREEMENT}" target="_blank">Terms of service Upfront</a>and the<a style="color:${color}  !important;"  href="${ROUTES.TERMS}" target="_blank">Privacy policy Upfront</a>`,
      };
    },
    TERMS_ERROR: {
      UK: 'Для продовження необхідно погодитися з умовами',
      RU: 'Для продолжения необходимо принять условия',
      EN: 'You need to accept the terms in order to proceed',
    },
  },
  PAYMENT: {
    HEADER: {
      UK: 'Дякуємо!',
      RU: 'Спасибо!',
      EN: 'Thank you!',
    },
    CERTIFICATES: {
      UK: 'Сертифікати були надіслані на електронну пошту отримувача. ',
      RU: 'Сертификаты были отправлены на электронный адрес получателя. ',
      EN: "Certificates have been sent to the recipient's email address.",
    },
    CERTIFICATE: {
      UK: 'Сертифікат був надісланий на електронну пошту отримувача. ',
      RU: 'Сертификат был отправлен на электронный адрес получателя. ',
      EN: "Certificate has been sent to the recipient's email address. ",
    },
    SPAM: {
      UK:
        'Якщо лист не був отриманий, перевірте папку спам. Якщо його там немає - зверніться до нашої служби пітримки.',
      RU:
        'Если письмо не было отправлено, проверьте папку спам. Если его там нет - обратитесь в нашу службу поддержки.',
      EN:
        'If the email was not received please check the spam folder. If it is not there - please reach out to our customer support team.',
    },
    ACTIVATE_DATE: {
      UK: 'Починаючи з ACTIVATE його можна використати для розрахунку.',
      RU: 'Начиная с ACTIVATE его можно использовать для рассчета.',
      EN: 'Starting with ACTIVATE it can be used as a payment.',
    },
    EXPIRE_DATE: {
      UK: 'Термін дії сертифіката - EXPIRE.',
      RU: 'Срок действия сертификата - EXPIRE.',
      EN: 'The certificate is valid for EXPIRE.',
    },
    INFINITY: {
      UK: 'Необмежений',
      RU: 'Неограничен',
      EN: 'Unlimited',
    },
    MONTHS_1: {
      UK: '1 місяць',
      RU: '1 месяць',
      EN: '1 month',
    },
    MONTHS_3: {
      UK: '3 місяць',
      RU: '3 месяць',
      EN: '3 months',
    },
    MONTHS_6: {
      UK: '6 місяців',
      RU: '6 месяцев',
      EN: '6 months',
    },
    MONTHS_12: {
      UK: '12 місяців',
      RU: '12 месяцев',
      EN: '12 months',
    },
    MONTHS_18: {
      UK: '18 місяців',
      RU: '18 месяцев',
      EN: '18 months',
    },
    MONTHS_24: {
      UK: '24 місяців',
      RU: '24 месяцев',
      EN: '24 months',
    },
    MONTHS_32: {
      UK: '36 місяців',
      RU: '36 месяцев',
      EN: '36 months',
    },
    BUY_ANOTHER: {
      UK: 'Придбати інший сертифікат',
      RU: 'Приобрести другой сертификат',
      EN: 'Buy another certificate',
    },
  },
  CERTIFICATES_AND_GIFT_CARDS: {
    UK: 'Електронні сертифікати і подарункові карти',
    RU: 'Электронные сертификаты и подарочные карты',
    EN: 'Online certificates and gift cards',
  },

  BUY_FORM: {
    ORDER_FORM: {
      UK: 'Форма для замовлення',
      RU: 'Форма для заказа',
      EN: 'Order form',
    },
    SEND: {
      UK: 'Продовжити',
      RU: 'Далее',
      EN: 'Proceed',
    },
    TOTAL: {
      UK: 'Разом',
      RU: 'Итого',
      EN: 'Total',
    },
  },
};
