import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import FileUpload from './FileUpload';
import { uploadFile, deleteFile } from '../../../redux/campaign/actions';
import { currentCampaign } from '../../../redux/campaign/selectors';

const selector = createStructuredSelector({
  campaign: currentCampaign,
});
const actions = {
  uploadFile,
  deleteFile,
};

export default connect(selector, actions)(FileUpload);
