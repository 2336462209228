import { prefix } from './actions';
import { createSelector } from 'reselect';

const root = state => state[prefix];

export const loading = createSelector(root, state => state.loading);

export const list = createSelector(root, state => state.list);

export const page = createSelector(root, state => state.page);

export const total = createSelector(root, state => state.total);
