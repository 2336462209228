import { priceWithDecimal } from '../helpers/stringFormatters';
/*eslint-disable no-useless-escape*/
export const youtubeVideoRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&\?]*).*/;
/*eslint-disable no-useless-escape*/
export const mobileRegex = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i;

export const required = value => (value ? null : "Поле обов'язкове");

export const notEmpty = value => {
  if (!value) {
    return null;
  }

  return value.trim() ? null : 'Поле не може бути пустим';
};

export const maxLength = max => value => {
  if (!value || (value.trim && !value.trim())) return null;

  return value && value.length <= max ? null : `Максимум ${max} символів`;
};

export const minLength = min => value => {
  if (!value || (value.trim && !value.trim())) return null;

  return value && value.length >= min ? null : `Мінімально ${min} символів`;
};

export const maxPrice = max => value => {
  if (!value || (value.trim && !value.trim())) return null;

  return value && Number(value) <= Number(max)
    ? null
    : `Максимальне значення ${priceWithDecimal(max * 100)} грн`;
};

export const minValue = min => value => {
  if (!value || (value.trim && !value.trim())) {
    return null;
  }

  return value >= min ? null : `Мінімальне значення ${min}`;
};

export const number = value => {
  return /^\d*\.?\d*$/.test(value) ? null : 'Має бути числовим значення';
};

export const price = value => {
  return /^\d+(?:\.\d{1,2})?$/.test(value) ? null : 'Невірний формат';
};

export const date = value => {
  const currentDate = new Date().setHours(0, 0, 0, 0);
  const selectedDate = new Date(value).setHours(0, 0, 0, 0);

  return value && currentDate > selectedDate
    ? 'Дата не може бути вчорашньою або пізніше'
    : null;
};

export const link = value => {
  if (!value || (value && !value.trim())) {
    return null;
  }
  const regExp = new RegExp(
    '(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-z]{1,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&=]*)',
  );

  return regExp.test(value) ? null : `Посилання має невірний формат`;
};

export const email = value => {
  if (!value) {
    return null;
  }

  return /^.+@.+\..+$/.test(value) ? null : 'Email має невірний формат';
};

export const phone = value => {
  if (value && !/^[\s\d,()\-+]*$/.test(value)) {
    return 'Номер має невірний формат';
  }
  if (value && value.length > 30) {
    return 'Довжина номера завелика';
  }
  return null;
};

export const youtube = value => {
  if (!value || (value && !value.trim())) {
    return null;
  }

  return youtubeVideoRegExp.test(value)
    ? null
    : 'Посилання має невірний Youtube формат';
};
