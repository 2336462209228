import { put, takeEvery } from 'redux-saga/effects';
import {
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  SET_JWT_TOKEN,
  loadProfile,
  UPDATE_PROFILE_SUCCESS,
} from './actions';
import { ROUTES } from '../../constants/routes';
import { removeReferralCode } from '../../localStorage';

export function* loginSuccess(history, api) {
  yield takeEvery(LOGIN_SUCCESS, function* (action) {
    const { state } = history.location;
    api.client.setToken(action.data.jwt);
    yield put(loadProfile());

    if (
      state &&
      state.from &&
      (state.from.pathname || '').includes('/certificates/use')
    ) {
      return yield history.push(state.from.pathname);
    }
    yield history.push(ROUTES.CAMPAIGNS);
  });
}

export function* setJWTFromLink(history, api) {
  yield takeEvery(SET_JWT_TOKEN, function* (action) {
    api.client.setToken(action.payload.token);

    yield put(loadProfile());
    yield history.push(ROUTES.CERTIFICATES);
  });
}

export function* signupSuccess() {
  yield takeEvery(SIGNUP_SUCCESS, function () {
    removeReferralCode();
  });
}

export function* logoutSuccess(history, api) {
  yield takeEvery(LOGOUT_SUCCESS, function () {
    api.client.removeToken();
    history.push(ROUTES.LOGIN);
    window.location.reload();
  });
}

export function* updateProfile() {
  yield takeEvery(UPDATE_PROFILE_SUCCESS, function* () {
    yield put(loadProfile());
  });
}

export function* profileFailure() {}
