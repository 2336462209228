import React from 'react';

export default function Icon({ color }) {
  return (
    <svg
      width="143"
      height="143"
      viewBox="0 0 143 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M136.528 0.0672171L0.0672782 136.528L1.56878e-05 97.8148C-0.00432383 95.5561 0.891786 93.3885 2.48873 91.7894L91.7916 2.48867C93.3885 0.891725 95.5561 -0.00438486 97.8148 -4.53474e-05L136.528 0.0672171Z"
        fill={color}
      />
      <path
        d="M136.527 0.0672607L143 6.53964H130.057L136.527 0.0672607Z"
        fill="#3D3F22"
      />
      <path
        d="M0.0673828 136.528L6.53977 130.057V143L0.0673828 136.528Z"
        fill="#3D3F22"
      />
      <path
        d="M3.30228 129.543C3.56266 129.543 3.82303 129.443 4.02264 129.246L129.539 4.02494C129.938 3.62787 129.938 2.98128 129.541 2.58205C129.144 2.18281 128.497 2.18281 128.098 2.57988L2.58192 127.801C2.18269 128.198 2.18269 128.845 2.57976 129.244C2.77937 129.441 3.04191 129.543 3.30228 129.543Z"
        fill="white"
      />
      <path
        d="M3.30256 96.5216C3.56293 96.5216 3.82547 96.4218 4.02508 96.2222L95.6474 4.31561C96.0445 3.91638 96.0445 3.27196 95.6452 2.87272C95.246 2.47566 94.6016 2.47566 94.2024 2.87489L2.5822 94.7815C2.18513 95.1807 2.18513 95.8251 2.58437 96.2243C2.78181 96.4218 3.04219 96.5216 3.30256 96.5216Z"
        fill="white"
      />
    </svg>
  );
}
