import React from 'react';
import classnames from 'classnames';
import { Form, Input, Button } from 'antd';
import { getFontColor } from '../../../utils';
import { USER_LANDING } from '../../../constants/texts';
import { ELEMENTS_IDS } from '../../../constants/labels';
import styles from './ContactForm.module.scss';

const validationRules = language => {
  return {
    name: [
      {
        required: true,
        message: USER_LANDING.CONTACT_FORM.ERROR_NAME[language],
      },
    ],
    email: [
      {
        required: true,
        message: USER_LANDING.CONTACT_FORM.ERROR_EMPTY_EMAIL[language],
      },
      {
        type: 'email',
        message: USER_LANDING.CONTACT_FORM.ERROR_WRONG_EMAIL[language],
      },
    ],
    phone: [
      {
        required: true,
        min: 10,
        max: 13,
        message: USER_LANDING.CONTACT_FORM.ERROR_PHONE[language],
      },
    ],
  };
};

export default function ContactForm(props) {
  const [form] = Form.useForm();
  const { color, language, fontColor, background } = props;
  const { isFontWhite } = getFontColor(background);

  const onFinish = async values => {
    const { submitContactForm, campaignId } = props;

    await submitContactForm({ ...values, campaignId });
    form.resetFields();
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={() => ({})}
      size="large"
      className={classnames(styles.contactForm, {
        [styles.whiteForm]: isFontWhite,
      })}
      id={ELEMENTS_IDS.FEEDBACK}
    >
      <Form.Item
        type="text"
        name="name"
        rules={validationRules(language).name}
        validateTrigger="onBlur"
      >
        <Input
          style={{ '--primary-color': color }}
          className={styles.input}
          placeholder={USER_LANDING.CONTACT_FORM.NAME[language]}
        />
      </Form.Item>
      <Form.Item
        type="text"
        name="email"
        rules={validationRules(language).email}
        validateTrigger="onBlur"
      >
        <Input
          style={{ '--primary-color': color }}
          className={styles.input}
          placeholder={USER_LANDING.CONTACT_FORM.EMAIL[language]}
        />
      </Form.Item>
      <Form.Item
        name="phone"
        type="text"
        rules={validationRules(language).phone}
        validateTrigger="onBlur"
      >
        <Input
          type="tel"
          style={{ '--primary-color': color }}
          className={styles.input}
          placeholder={USER_LANDING.CONTACT_FORM.PHONE[language]}
        />
      </Form.Item>
      <Form.Item>
        <Button
          style={{ '--background': color, '--font': fontColor }}
          className={styles.button}
          htmlType="submit"
          type="primary"
        >
          {USER_LANDING.CONTACT_FORM.SUBMIT[language]}
        </Button>
      </Form.Item>
    </Form>
  );
}
