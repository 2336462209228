import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Table from './Table';

import {
  getCampaigns,
  changePage,
  deleteCampaign,
  changeStatus,
  selectCampaign,
  clearSelectedCampaign,
} from '../../../redux/campaign/actions';
import {
  campaigns,
  page,
  total,
  isFirstCampaignModalShown,
} from '../../../redux/campaign/selectors';
import { userId, isPaymentCreated } from '../../../redux/profile/selectors';

const selectors = createStructuredSelector({
  campaigns,
  page,
  total,
  userId,
  isPaymentCreated,
  isFirstCampaignModalShown,
});
const actions = {
  getCampaigns,
  changePage,
  deleteCampaign,
  changeStatus,
  selectCampaign,
  clearSelectedCampaign,
};

export default withRouter(connect(selectors, actions)(Table));
