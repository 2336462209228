import { all } from 'redux-saga/effects';
import {
  loginSuccess,
  signupSuccess,
  logoutSuccess,
  profileFailure,
  setJWTFromLink,
  updateProfile,
} from './profile/sagas';
import { listFailure, addSuccess } from './users/sagas';
import { paymentIntentionSuccess, paymentBuySuccess } from './landing/sagas';
import { notify } from './notify/sagas';
import { uploadSuccess, saveCampaignSuccess } from './campaign/sagas';
import { createCertificate, exportClients } from './certificate/sagas';
import { createByCampaignAdminSuccess } from './administration/sagas';
import { pushToRouteAndScroll } from './router/sagas';

export default function* rootSaga(history, api) {
  yield all([
    loginSuccess(history, api),
    setJWTFromLink(history, api),
    logoutSuccess(history, api),
    listFailure(history),
    pushToRouteAndScroll(history),
    addSuccess(),
    signupSuccess(history),
    profileFailure(history),
    paymentIntentionSuccess(history),
    paymentBuySuccess(history),
    notify(),
    uploadSuccess(),
    saveCampaignSuccess(history),
    exportClients(),
    createByCampaignAdminSuccess(),
    createCertificate(),
    updateProfile(),
  ]);
}
