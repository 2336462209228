import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { submitContactForm } from '../../redux/landing/actions';
import { campaignId } from '../../redux/router/selectors';

import ContactForm from './ContactForm';

const selector = createStructuredSelector({ campaignId });

const actions = { submitContactForm };

export { ContactForm };

export default connect(selector, actions)(ContactForm);
