import React from 'react';
import classnames from 'classnames';
import { CERTIFICATE_TYPES } from '../../../constants/labels';
import { USER_LANDING } from '../../../constants/texts';
import classes from './Select.module.scss';

class Select extends React.Component {
  select = React.createRef();
  state = { open: false };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  handleSelectClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleOutsideClick = event => {
    if (this.select && !this.select.current.contains(event.target)) {
      this.setState({ open: false });
    }
  };

  handleGiftClick = () => {
    this.props.onChange(CERTIFICATE_TYPES.GIFT);
    this.handleSelectClick();
  };

  handleSelfClick = () => {
    this.props.onChange(CERTIFICATE_TYPES.SELF);
    this.handleSelectClick();
  };

  getCurrentValue = () => {
    const { value, language } = this.props;

    if (value === CERTIFICATE_TYPES.SELF) {
      return USER_LANDING.CONTACT_FORM.RECIPIENT_OPTION1[language];
    }

    return USER_LANDING.CONTACT_FORM.RECIPIENT_OPTION2[language];
  };

  render() {
    const { open } = this.state;
    const {
      language,
      color,
      backgroundColor,
      primaryColor,
      value,
    } = this.props;

    return (
      <div
        ref={this.select}
        className={classes.select}
        style={{ backgroundColor, borderColor: color }}
        onClick={this.handleSelectClick}
      >
        <div className={classes.selected}>
          <span>{this.getCurrentValue()}</span>
          <span
            style={{ borderColor: color }}
            className={classnames(classes.arrow, {
              [classes.up]: open,
              [classes.down]: !open,
            })}
          />
        </div>
        {open && (
          <div
            className={classes.list}
            style={{ backgroundColor, borderColor: color }}
          >
            <span
              className={classnames(classes.item, {
                [classes.selected]: value === CERTIFICATE_TYPES.SELF,
              })}
              style={{ '--font-color': color, '--primary-color': primaryColor }}
              onClick={this.handleSelfClick}
            >
              {USER_LANDING.CONTACT_FORM.RECIPIENT_OPTION1[language]}
            </span>
            <span
              className={classnames(classes.item, {
                [classes.selected]: value === CERTIFICATE_TYPES.GIFT,
              })}
              style={{ '--font-color': color, '--primary-color': primaryColor }}
              onClick={this.handleGiftClick}
            >
              {USER_LANDING.CONTACT_FORM.RECIPIENT_OPTION2[language]}
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default Select;
