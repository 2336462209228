import React, { Component } from 'react';
import { Form, Field, reduxForm, initialize, change } from 'redux-form';
import { Modal, Button, Alert } from 'antd';
import { TextField, TextAreaField } from 'redux-form-antd';
import {
  required,
  number,
  price,
  minLength,
  maxPrice,
} from '../../../redux/validators';
import * as classes from './UseModal.module.scss';

const min = minLength(9);

class UseModal extends Component {
  getMaxRemainder = value => {
    const { validateRemainder } = this.props;

    return maxPrice((validateRemainder / 100).toFixed(2))(value);
  };

  handleUseFullAmountClick = () => {
    const { dispatch, remainder } = this.props;

    dispatch(change('useCertificate', 'amount', remainder));
  };

  handleSubmitClick = async () => {
    const { valid, onSubmitClick } = this.props;

    if (!valid) return;

    await onSubmitClick();
    const { requestError } = this.props;

    if (requestError) {
      return;
    }

    this.handleCloseClick();
  };

  handleCloseClick = () => {
    const { onCancelClick, dispatch } = this.props;

    dispatch(initialize('useCertificate', { amount: '', comment: '' }));
    onCancelClick();
  };

  getHint = () => {
    const { isCertificateInputShown, isReusable = true } = this.props;
    if (isReusable) {
      return (
        <div className={classes.warning}>
          <span className={classes.title}>
            Сертифікат багаторазового використання
          </span>
          <div>
            <div>
              1. Введіть суму списання (або{' '}
              <span
                onClick={this.handleUseFullAmountClick}
                className={classes.hint}
              >
                Використайте весь залишок
              </span>
              ).
            </div>
            {isCertificateInputShown && <div>2. Введіть код сертифіката.</div>}
          </div>
        </div>
      );
    }

    return (
      <div className={classes.warning}>
        <span>Сертифікат одноразового використання</span>
        <div>
          <div>
            1. Натисність{' '}
            <span
              onClick={this.handleUseFullAmountClick}
              className={classes.hint}
            >
              Використати весь залишок
            </span>
            .
          </div>
          {isCertificateInputShown && <div>2. Введіть код сертифіката.</div>}
        </div>
      </div>
    );
  };

  render() {
    const {
      isOpen,
      valid,
      isCertificateInputShown,
      isReusable = true,
    } = this.props;

    return (
      <Modal
        title="Використати сертифікат"
        centered
        visible={isOpen}
        okText="Підтвердити"
        cancelText="Відміна"
        onOk={this.handleSubmitClick}
        onCancel={this.handleCloseClick}
        okButtonProps={{ disabled: !valid }}
        destroyOnClose
      >
        <Alert message={this.getHint()} type="success" />
        <Form onSubmit={this.handleSubmitClick}>
          <div className={classes.row}>
            <Field
              name="amount"
              type="text"
              placeholder="Скільки списати?"
              label="Загальна сума"
              component={TextField}
              disabled={!isReusable}
              validate={[required, number, price, this.getMaxRemainder]}
            />
            <Button
              className={classes.button}
              onClick={this.handleUseFullAmountClick}
            >
              Використати весь залишок
            </Button>
          </div>
          {isCertificateInputShown && (
            <div className={classes.column}>
              <Field
                className={classes.input}
                label="Код сертифікату"
                placeholder="Введіть код сертифікату"
                name="code"
                type="text"
                component={TextField}
                validate={[required, min]}
              />
            </div>
          )}
          <div className={classes.column}>
            <Field
              label="Додатковий коментар"
              placeholder="Введіть коментар..."
              name="comment"
              type="text"
              component={TextAreaField}
            />
          </div>
        </Form>
      </Modal>
    );
  }
}

export default reduxForm({
  form: 'useCertificate',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(UseModal);
