import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Form, Button, Input, Select, Checkbox, Modal } from 'antd';
import HolidayGarland from '../HolidayGarland';
import { USER_LANDING } from '../../constants/texts';
import { CERTIFICATE_TYPES, ELEMENTS_IDS } from '../../constants/labels';
import { validationRules } from '../../helpers/verifying';
import garlandImage from '../../assets/images/garlandOptimised.png';
import Quantity from './Quantity';
import styles from './BuyForm.module.scss';

const { Option } = Select;
const { TextArea } = Input;
const buttonsProps = { style: { display: 'none' } };

export default class BuyForm extends Component {
  state = {
    quantity: 1,
    isWarningOpen: false,
    certificateType: CERTIFICATE_TYPES.SELF,
  };

  formRef = React.createRef();

  handleQuantityChange = value => {
    this.setState({
      quantity: value,
    });
  };

  getExtraField = () => {
    const { extraFormField, language } = this.props;

    if (
      !extraFormField ||
      isEmpty(extraFormField) ||
      !extraFormField.fieldLabel
    ) {
      return null;
    }

    const validationRule = [];

    if (extraFormField.isFieldRequired) {
      validationRule.push({
        required: true,
        message: USER_LANDING.CONTACT_FORM.ERROR_ADDITIONAL_FIELD[language],
      });
    }

    return (
      <Form.Item
        name="additionalInfo"
        rules={validationRule}
        validateTrigger="onBlur"
      >
        <Input
          className={styles.input}
          placeholder={extraFormField.fieldLabel}
        />
      </Form.Item>
    );
  };

  handleClose = () => {
    this.setState({ isWarningOpen: false });
  };

  onFinish = values => {
    const { getPaymentData, certificateId, isPaymentBlocked } = this.props;

    if (isPaymentBlocked) {
      return this.setState({ isWarningOpen: true });
    }

    getPaymentData({ ...values, certificateId });
  };

  getInitialValues = () => {
    const { quantity } = this.state;
    const { certificateTypeByDefault } = this.props;

    return {
      quantity,
      certificateType: certificateTypeByDefault,
      acceptTerms: false,
    };
  };

  render() {
    const { quantity, isWarningOpen } = this.state;
    const { price, color, language, fontColor } = this.props;

    const styleTag = `
        <style>
            body .ant-checkbox-checked .ant-checkbox-inner {
                background-color: ${color};
                border-color: ${color};
            }
            
            body .ant-checkbox-checked::after {
                border-width: 1px;
                border-color: ${color}
            }
        </style>
    `;

    return (
      <Form
        id={ELEMENTS_IDS.BUY_FORM}
        ref={this.formRef}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        size="large"
        className={styles.buyForm}
        initialValues={this.getInitialValues()}
      >
        <HolidayGarland src={garlandImage} width={106} />
        <div dangerouslySetInnerHTML={{ __html: styleTag }} />
        <Form.Item
          name="firstName"
          rules={validationRules(language).firstName}
          validateTrigger="onBlur"
        >
          <Input
            style={{ '--primary-color': color }}
            className={styles.input}
            placeholder={USER_LANDING.CONTACT_FORM.NAME[language]}
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={validationRules(language).lastName}
          validateTrigger="onBlur"
        >
          <Input
            style={{ '--primary-color': color }}
            className={styles.input}
            placeholder={USER_LANDING.CONTACT_FORM.LAST_NAME[language]}
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={validationRules(language).email}
          validateTrigger="onBlur"
        >
          <Input
            style={{ '--primary-color': color }}
            className={styles.input}
            placeholder={USER_LANDING.CONTACT_FORM.EMAIL[language]}
          />
        </Form.Item>
        {this.getExtraField()}
        <div className={styles.additionalWrapper}>
          <h3 className={styles.label}>
            {USER_LANDING.CONTACT_FORM.COUNT[language]}
          </h3>
          <Form.Item name="quantity" validateTrigger="onBlur" required>
            <Quantity
              min={1}
              value={quantity}
              price={price}
              color={color}
              language={language}
              onChange={this.handleQuantityChange}
            />
          </Form.Item>
        </div>
        <div className={styles.additionalWrapper}>
          <h3 className={styles.label}>
            {USER_LANDING.CONTACT_FORM.RECIPIENT_LABEL[language]}
          </h3>
          <Form.Item name="certificateType" validateTrigger="onBlur" required>
            <Select className={styles.selectInput} optionFilterProp="children">
              <Option value={CERTIFICATE_TYPES.SELF}>
                {USER_LANDING.CONTACT_FORM.RECIPIENT_OPTION1[language]}
              </Option>
              <Option value={CERTIFICATE_TYPES.GIFT}>
                {USER_LANDING.CONTACT_FORM.RECIPIENT_OPTION2[language]}
              </Option>
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          className={styles.additionalInfo}
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.certificateType !== currentValues.certificateType
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('certificateType') === CERTIFICATE_TYPES.GIFT ? (
              <div>
                <Form.Item
                  name="recipientEmail"
                  rules={validationRules(language).recipientEmail}
                  validateTrigger="onBlur"
                >
                  <Input
                    style={{ '--primary-color': color }}
                    className={styles.input}
                    placeholder={
                      USER_LANDING.CONTACT_FORM.RECIPIENT_EMAIL[language]
                    }
                  />
                </Form.Item>
                <Form.Item name="message">
                  <TextArea
                    style={{ '--primary-color': color }}
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    className={styles.input}
                    placeholder={USER_LANDING.CONTACT_FORM.MESSAGE[language]}
                  />
                </Form.Item>
              </div>
            ) : null
          }
        </Form.Item>

        <div className={styles.additionalWrapper}>
          <Form.Item
            name="acceptTerms"
            valuePropName="checked"
            rules={validationRules(language).acceptTerms}
            validateTrigger={['onSubmit', 'onBlur', 'onChange']}
          >
            <Checkbox className={styles.formField}>
              <span
                dangerouslySetInnerHTML={{
                  __html: USER_LANDING.CONTACT_FORM.TERMS[language],
                }}
              />
              .
            </Checkbox>
          </Form.Item>
        </div>

        <Form.Item className={styles.button}>
          <Button
            className={styles.buttonElement}
            htmlType="submit"
            type="primary"
            style={{ backgroundColor: color, color: fontColor }}
          >
            {USER_LANDING.CONTACT_FORM.CONTINUE[language]}
          </Button>
        </Form.Item>
        <Modal
          visible={isWarningOpen}
          okText="Зрозуміло"
          title="Попередження"
          onOk={this.handleClose}
          onCancel={this.handleClose}
          okButtonProps={buttonsProps}
          cancelButtonProps={buttonsProps}
          destroyOnClose
          centered
        >
          Продаж сертифікатів тимчасово призупинено
        </Modal>
      </Form>
    );
  }
}
