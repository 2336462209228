export const prefix = 'campaign';

export const GET_CAMPAIGNS_REQUEST = `${prefix}/GET_CAMPAIGNS_REQUEST`;
export const GET_CAMPAIGNS_SUCCESS = `${prefix}/GET_CAMPAIGNS_SUCCESS`;
export const GET_CAMPAIGNS_FAILURE = `${prefix}/GET_CAMPAIGNS_FAILURE`;
export const getCampaigns = (userId, page = 1) => ({
  types: [GET_CAMPAIGNS_REQUEST, GET_CAMPAIGNS_SUCCESS, GET_CAMPAIGNS_FAILURE],
  promise: api => api.campaign.get({ page, userId }),
});

export const CHANGE_PAGE = `${prefix}/CHANGE_PAGE`;
export const changePage = page => ({ type: CHANGE_PAGE, payload: { page } });

export const DELETE_CAMPAIGN_REQUEST = `${prefix}/DELETE_CAMPAIGN_REQUEST`;
export const DELETE_CAMPAIGN_SUCCESS = `${prefix}/DELETE_CAMPAIGN_SUCCESS`;
export const DELETE_CAMPAIGN_FAILURE = `${prefix}/DELETE_CAMPAIGN_FAILURE`;
export const deleteCampaign = id => ({
  types: [
    DELETE_CAMPAIGN_REQUEST,
    DELETE_CAMPAIGN_SUCCESS,
    DELETE_CAMPAIGN_FAILURE,
  ],
  promise: api => api.campaign.delete(id),
});

export const UPLOAD_FILE_REQUEST = `${prefix}/UPLOAD_FILE_REQUEST`;
export const UPLOAD_FILE_SUCCESS = `${prefix}/UPLOAD_FILE_SUCCESS`;
export const UPLOAD_FILE_FAILURE = `${prefix}/UPLOAD_FILE_FAILURE`;
export const uploadFile = ({ file, name, path, uid, field }) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('name', name);
  formData.append('path', path);
  formData.append('uid', uid);
  formData.append('field', field);

  return {
    types: [UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE],
    promise: api => api.campaign.uploadFile(formData),
  };
};

export const CREATE_CAMPAIGN_REQUEST = `${prefix}/CREATE_CAMPAIGN_REQUEST`;
export const CREATE_CAMPAIGN_SUCCESS = `${prefix}/CREATE_CAMPAIGN_SUCCESS`;
export const CREATE_CAMPAIGN_FAILURE = `${prefix}/CREATE_CAMPAIGN_FAILURE`;
export const create = data => ({
  types: [
    CREATE_CAMPAIGN_REQUEST,
    CREATE_CAMPAIGN_SUCCESS,
    CREATE_CAMPAIGN_FAILURE,
  ],
  promise: api => api.campaign.create(data),
});

export const UPDATE_CAMPAIGN_REQUEST = `${prefix}/UPDATE_CAMPAIGN_REQUEST`;
export const UPDATE_CAMPAIGN_SUCCESS = `${prefix}/UPDATE_CAMPAIGN_SUCCESS`;
export const UPDATE_CAMPAIGN_FAILURE = `${prefix}/UPDATE_CAMPAIGN_FAILURE`;
export const update = data => ({
  types: [
    UPDATE_CAMPAIGN_REQUEST,
    UPDATE_CAMPAIGN_SUCCESS,
    UPDATE_CAMPAIGN_FAILURE,
  ],
  promise: api => api.campaign.update(data),
});

export const DELETE_CAMPAIGN_LOGO_REQUEST = `${prefix}/DELETE_CAMPAIGN_LOGO_REQUEST`;
export const DELETE_CAMPAIGN_LOGO_SUCCESS = `${prefix}/DELETE_CAMPAIGN_LOGO_SUCCESS`;
export const DELETE_CAMPAIGN_LOGO_FAILURE = `${prefix}/DELETE_CAMPAIGN_LOGO_FAILURE`;
export const deleteFile = (path, field, id) => ({
  types: [
    DELETE_CAMPAIGN_LOGO_REQUEST,
    DELETE_CAMPAIGN_LOGO_SUCCESS,
    DELETE_CAMPAIGN_LOGO_FAILURE,
  ],
  promise: api => api.campaign.deleteFile({ path, field, id }),
});

export const CHANGE_CAMPAIGN_STATUS_REQUEST = `${prefix}/CHANGE_CAMPAIGN_STATUS_REQUEST`;
export const CHANGE_CAMPAIGN_STATUS_SUCCESS = `${prefix}/CHANGE_CAMPAIGN_STATUS_SUCCESS`;
export const CHANGE_CAMPAIGN_STATUS_FAILURE = `${prefix}/CHANGE_CAMPAIGN_STATUS_FAILURE`;
export const changeStatus = (id, status) => ({
  types: [
    CHANGE_CAMPAIGN_STATUS_REQUEST,
    CHANGE_CAMPAIGN_STATUS_SUCCESS,
    CHANGE_CAMPAIGN_STATUS_FAILURE,
  ],
  promise: api => api.campaign.status(id, status),
});

export const CHECK_SLUG_REQUEST = `${prefix}/CHECK_SLUG_REQUEST`;
export const CHECK_SLUG_SUCCESS = `${prefix}/CHECK_SLUG_SUCCESS`;
export const CHECK_SLUG_FAILURE = `${prefix}/CHECK_SLUG_FAILURE`;
export const checkSlug = (campaignId, slug) => ({
  types: [CHECK_SLUG_REQUEST, CHECK_SLUG_SUCCESS, CHECK_SLUG_FAILURE],
  promise: api => api.campaign.checkSlug({ campaignId, slug }),
});

export const RESET_SLUG_ERROR = `${prefix}/RESET_SLUG_ERROR`;
export const resetSlugError = () => ({ type: RESET_SLUG_ERROR });

export const SELECT_CAMPAIGN = `${prefix}/SELECT_CAMPAIGN`;
export const selectCampaign = id => ({ type: SELECT_CAMPAIGN, payload: id });

export const CLEAR_SELECTED_CAMPAIGN = `${prefix}/CLEAR_SELECTED_CAMPAIGN`;
export const clearSelectedCampaign = () => ({ type: CLEAR_SELECTED_CAMPAIGN });

export const SET_CAMPAIGN_PREVIEW = `${prefix}/SET_CAMPAIGN_PREVIEW`;
export const setPreview = preview => ({
  type: SET_CAMPAIGN_PREVIEW,
  payload: { preview },
});

export const GET_SHORT_CAMPAIGNS_REQUEST = `${prefix}/GET_SHORT_CAMPAIGNS_REQUEST`;
export const GET_SHORT_CAMPAIGNS_SUCCESS = `${prefix}/GET_SHORT_CAMPAIGNS_SUCCESS`;
export const GET_SHORT_CAMPAIGNS_FAILURE = `${prefix}/GET_SHORT_CAMPAIGNS_FAILURE`;
export const getShortCampaigns = userId => ({
  types: [
    GET_SHORT_CAMPAIGNS_REQUEST,
    GET_SHORT_CAMPAIGNS_SUCCESS,
    GET_SHORT_CAMPAIGNS_FAILURE,
  ],
  promise: api => api.campaign.getShort({ userId }),
});

export const UPDATE_CAMPAIGN_VIEWS_REQUEST = `${prefix}/UPDATE_CAMPAIGN_VIEWS_REQUEST`;
export const UPDATE_CAMPAIGN_VIEWS_SUCCESS = `${prefix}/UPDATE_CAMPAIGN_VIEWS_SUCCESS`;
export const UPDATE_CAMPAIGN_VIEWS_FAILURE = `${prefix}/UPDATE_CAMPAIGN_VIEWS_FAILURE`;
export const updateCampaignViews = campaignId => ({
  types: [
    UPDATE_CAMPAIGN_VIEWS_REQUEST,
    UPDATE_CAMPAIGN_VIEWS_SUCCESS,
    UPDATE_CAMPAIGN_VIEWS_FAILURE,
  ],
  promise: api => api.campaign.updateViews({ campaignId }),
});
