import React from 'react';

export const Arrow = ({ color }) => (
  <svg
    width="23"
    height="13"
    viewBox="0 0 23 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.31069 12.622L7.37169 11.561L2.87169 7.06101L22.4297 7.06101L22.4297 5.56101L2.87169 5.56101L7.37169 1.06101L6.31069 1.09459e-05L-0.000313357 6.31001L6.31069 12.62L6.31069 12.622Z"
      fill={color}
    />
  </svg>
);
