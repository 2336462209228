import { connect } from 'react-redux';
import CookiesBanner from './CookiesBanner';
import { acceptCookie } from '../../redux/common/actions';
import { isLandingPage } from '../../redux/router/selectors';
import { createStructuredSelector } from 'reselect';

const actions = { acceptCookie };
const selectors = createStructuredSelector({ isLandingPage });

export default connect(selectors, actions)(CookiesBanner);
