import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from '../../utils';
import { loading } from '../../redux/users/selectors';
import { createUser, updateUser } from '../../redux/users/actions';
import CreateUserModal from './CreateUserModal';

const selector = createStructuredSelector({
  loading,
});

const actions = { createUser, updateUser };

const reduxConnect = connect(selector, actions);

export { CreateUserModal };

export default withRouter(compose(reduxConnect)(CreateUserModal));
