import { takeEvery, put } from 'redux-saga/effects';
import {
  LIST_FAILURE,
  CREATE_SUCCESS,
  UPDATE_SUCCESS,
  DELETE_SUCCESS,
  listUsers,
} from './actions';

import { ROUTES } from '../../constants/routes';

export function* listFailure(history) {
  yield takeEvery(LIST_FAILURE, function* (action) {
    if (action.error && action.error.code === 'PERMISSION_DENIED') {
      yield history.push(ROUTES.HOME);
    }
  });
}

export function* addSuccess() {
  yield takeEvery(
    [CREATE_SUCCESS, UPDATE_SUCCESS, DELETE_SUCCESS],
    function* () {
      yield put(listUsers());
    },
  );
}
