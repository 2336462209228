import React from 'react';

import { Collapse } from 'antd';
import classes from './FaqPage.module.scss';
import mainHeroWave from '../../assets/images/mainHeroWave.svg';
import questionImage from '../../assets/images/questionImage.svg';
import data from './faqData';
import { PHONE_NUMBER } from '../../constants/texts';

const { Panel } = Collapse;

class FaqPage extends React.Component {
  render() {
    return (
      <div className={classes.faqMain}>
        <div className={classes.title}>
          <img className={classes.waveImage} src={mainHeroWave} alt="wave" />
          <p className={classes.mainText}>Часті питання</p>
          <p className={classes.secondaryText}>
            Ми завжди відповідаємо протягом 24 годин після отримання вашого
            листа.
          </p>
          <div className={classes.titleContacts}>
            Ви також можете нам зателефонувати:
            <a href={`tel: ${PHONE_NUMBER}`}> {PHONE_NUMBER}</a>
          </div>
          <div className={classes.faqContent}>
            <p className={classes.contentTitle}>
              Можливо, ми вже відповіли на Ваше запитання. Подивіться наші FAQ.
            </p>
            <img src={questionImage} alt="Question sign" />
            <Collapse
              defaultActiveKey={['1']}
              expandIconPosition="right"
              className={classes.collapseMain}
            >
              {data.map(data => (
                <Panel
                  header={data.question}
                  className={classes.collapseItem}
                  right
                  key={data.question}
                >
                  <p className={classes.collapseText}>{data.answer}</p>
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </div>
    );
  }
}
export default FaqPage;
