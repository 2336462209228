import { combineReducers } from 'redux';
import * as usersActions from '../users/actions';
import * as actions from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case usersActions.CREATE_BY_CAMPAIGN_ADMIN_REQUEST:
      case usersActions.UPDATE_BY_CAMPAIGN_ADMIN_REQUEST:
      case actions.GET_USERS_REQUEST:
      case actions.UPDATE_PERMISSION_REQUEST: {
        return true;
      }
      case usersActions.CREATE_BY_CAMPAIGN_ADMIN_SUCCESS:
      case usersActions.CREATE_BY_CAMPAIGN_ADMIN_FAILURE:
      case usersActions.UPDATE_BY_CAMPAIGN_ADMIN_SUCCESS:
      case usersActions.UPDATE_BY_CAMPAIGN_ADMIN_FAILURE:
      case actions.GET_USERS_SUCCESS:
      case actions.GET_USERS_FAILURE:
      case actions.UPDATE_PERMISSION_SUCCESS:
      case actions.UPDATE_PERMISSION_FAILURE: {
        return false;
      }
      default: {
        return state;
      }
    }
  },

  list(state = [], action) {
    switch (action.type) {
      case actions.GET_USERS_SUCCESS: {
        return action.data.list;
      }
      case actions.UPDATE_PERMISSION_SUCCESS:
      case usersActions.UPDATE_BY_CAMPAIGN_ADMIN_SUCCESS: {
        const { _id, ...rest } = action.data;

        return state.map(item =>
          item._id === _id ? { ...item, ...rest } : item,
        );
      }

      default: {
        return state;
      }
    }
  },

  page(state = [], action) {
    switch (action.type) {
      case actions.GET_USERS_SUCCESS: {
        return action.data.page;
      }
      case actions.CHANGE_PAGE: {
        return action.payload.page;
      }
      default: {
        return state;
      }
    }
  },
  total(state = [], action) {
    switch (action.type) {
      case actions.GET_USERS_SUCCESS: {
        return action.data.total;
      }
      default: {
        return state;
      }
    }
  },
});
