import React, { Component } from 'react';
import { chunk } from 'lodash';
import { ELEMENTS_IDS } from '../../../constants/labels';
import CertificateCard from '../CertificateCard';
import styles from './CertificateTiers.module.scss';

const SIZES = {
  DESCRIPTION: {
    height: 2.5,
    length: 33,
  },
  NAME: {
    height: 2.7,
    length: 15,
  },
};

class CertificateTiers extends Component {
  getSize = (array, { length, height }) => {
    return `${(Math.max.apply(this, array) / length) * height}vw`;
  };

  getElementsHeight = index => {
    const { tiers } = this.props;
    const names = [];
    const descriptions = [];
    const chunkPosition = Math.floor(index / 3);
    const row = chunk(tiers, 3);

    row[chunkPosition].forEach(({ description, name }) => {
      names.push(name.length);
      descriptions.push(description.length);
    });

    return {
      name: this.getSize(names, SIZES.NAME),
      description: this.getSize(descriptions, SIZES.DESCRIPTION),
    };
  };

  render() {
    const {
      tiers,
      isPreview,
      logo,
      primaryColor,
      language,
      backgroundColor,
    } = this.props;

    return (
      <div
        ref={this.listRef}
        className={styles.tiersLayout}
        id={ELEMENTS_IDS.CERTIFICATES}
      >
        {tiers.map((item, position) => (
          <CertificateCard
            cardClassName={styles.tier}
            language={language}
            key={item.id}
            orderNum={position}
            item={item}
            logo={logo}
            isDisabled={isPreview}
            primaryColor={primaryColor}
            backgroundColor={backgroundColor}
            height={this.getElementsHeight(position)}
          />
        ))}
      </div>
    );
  }
}

export default CertificateTiers;
