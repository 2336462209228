import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React from 'react';
import { SRLWrapper } from 'simple-react-lightbox';
import Slider from 'react-slick';
import { getFontColor } from '../../utils';
import Arrow from './Arrow';
import classes from './GalleryWidget.module.scss';

const getSlickSettings = backgroundColor => {
  const { isFontWhite } = getFontColor(backgroundColor);
  const dotsClass = isFontWhite ? 'whiteDots' : '';
  const arrowsClass = isFontWhite ? 'whiteArrows' : '';

  return {
    speed: 500,
    arrows: true,
    keyboard: true,
    draggable: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    nextArrow: <Arrow additionalClass={arrowsClass} />,
    prevArrow: <Arrow additionalClass={arrowsClass} />,
    appendDots: function renderDots(dots) {
      return (
        <div>
          <ul>
            {dots.map(item => (
              <span key={item} className={dotsClass}>
                {item}
              </span>
            ))}
          </ul>
        </div>
      );
    },
  };
};

const getSRLSettings = () => ({
  settings: {
    autoplaySpeed: 1500,
    transitionSpeed: 900,
  },
  caption: {
    captionColor: '#a6cfa5',
    captionFontFamily: 'Raleway, sans-serif',
    captionFontWeight: '300',
    captionTextTransform: 'uppercase',
  },
});

class GalleryWidget extends React.Component {
  render() {
    const {
      certificateData: { images, name },
      backgroundColor,
    } = this.props;

    return (
      <div className={classes.container}>
        <SRLWrapper options={getSRLSettings()}>
          <div className={classes.sliderContainer}>
            <Slider {...getSlickSettings(backgroundColor)}>
              {images.map(img => (
                <img
                  src={img}
                  key={name}
                  alt={name}
                  className={classes.image}
                />
              ))}
            </Slider>
          </div>
        </SRLWrapper>
      </div>
    );
  }
}
export default GalleryWidget;
