import React, { Component } from 'react';
import { Table, Button, Tag, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import styles from './AdminUsersPage.module.scss';
import CreateUserModal from '../../components/CreateUserModal';

class AdminUsersPage extends Component {
  state = {
    showCreateModal: false,
    showDeleteModal: false,
    userToEdit: {},
    userToDelete: {},
  };

  componentDidMount() {
    this.props.listUsers();
  }

  handleShowCreateModal = e => {
    this.setState({
      showCreateModal: true,
    });
  };

  handleHideCreateModal = e => {
    this.setState({
      showCreateModal: false,
      userToEdit: {},
    });
  };

  handleHideDeleteModal = e => {
    this.setState({
      showDeleteModal: false,
      userToDelete: {},
    });
  };

  handleEditUser = user => e => {
    this.setState({
      showCreateModal: true,
      userToEdit: { ...user },
    });
  };

  handleDeleteUser = user => e => {
    this.setState({
      showDeleteModal: true,
      userToDelete: { ...user },
    });
  };

  handleConfirmDeleteUser = e => {
    const { deleteUser } = this.props;
    const { userToDelete } = this.state;
    deleteUser(userToDelete.id);
    this.setState({
      showDeleteModal: false,
      userToDelete: {},
    });
  };

  getColumns = () => [
    {
      title: "Ім'я",
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Прізвище',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Компанія',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      render: role => (
        <Tag color={role === 'USER' ? 'geekblue' : 'volcano'}>{role}</Tag>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <Tag color={status === 'ACTIVE' ? 'green' : 'gold'}>{status}</Tag>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      render: (id, user) => [
        <Button
          key="edit"
          size="small"
          type="primary"
          onClick={this.handleEditUser(user)}
          icon={<EditOutlined />}
          className={styles.btn}
        >
          Редагувати
        </Button>,
        <Button
          key="delete"
          size="small"
          type="primary"
          onClick={this.handleDeleteUser(user)}
          icon={<DeleteOutlined />}
          className={styles.btn}
        >
          Видалити
        </Button>,
      ],
    },
  ];

  render() {
    const { loading, list } = this.props;
    const {
      showCreateModal,
      showDeleteModal,
      userToEdit,
      userToDelete,
    } = this.state;
    return (
      <div className={styles.table}>
        <Modal
          title="Confirm delete user"
          visible={showDeleteModal}
          onOk={this.handleConfirmDeleteUser}
          onCancel={this.handleHideDeleteModal}
        >
          <p>
            Ви впевнені, що хочете видалити користувача{' '}
            {`${userToDelete.firstName} ${userToDelete.lastName}`}.
          </p>
        </Modal>
        <CreateUserModal
          visible={showCreateModal}
          handleCancel={this.handleHideCreateModal}
          initialValues={userToEdit}
        />
        <Button
          size="large"
          type="primary"
          onClick={this.handleShowCreateModal}
          className={styles.addBtn}
        >
          Створити користувача
        </Button>
        <Table
          rowKey="id"
          size="large"
          loading={loading}
          dataSource={list}
          pagination={false}
          columns={this.getColumns()}
        />
      </div>
    );
  }
}

export default AdminUsersPage;
