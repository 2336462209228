import React from 'react';
import classnames from 'classnames';
import YoutubeBackground from 'react-youtube-background';
import { faSadTear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './BrandHeader.module.scss';
import Image from '../Image';
import { youtubeVideoRegExp, mobileRegex } from '../../redux/validators';

const ID_POSITION = 2;
const ID_LENGTH = 11;
const videoOptions = {
  playerVars: {
    autoplay: 1,
    controls: 0,
    rel: 0,
    showinfo: 0,
  },
};

const campaignsWithCustomHeader = [
  '5f523735785d4a79f4631265',
  '5f04539ec9657a11a0eef7de',
  '5f523735785d4a79f4631215',
  '5f523731785d4a79f4631215',
];

function isMobileOrTabletUserAgent() {
  const toCheck = navigator.userAgent || navigator.vendor || window.opera;

  return mobileRegex.test(toCheck);
}

function getCoverParams(imageUrl, videoUrl) {
  if (!imageUrl && !videoUrl) {
    return { url: null, isVideo: false };
  }

  const match = (videoUrl || '').match(youtubeVideoRegExp);
  const isCorrectVideo = match && match[ID_POSITION].length === ID_LENGTH;
  const isMobileOrTable = isMobileOrTabletUserAgent();

  if (isMobileOrTable || !isCorrectVideo) {
    return {
      url: imageUrl,
      isVideo: false,
    };
  }

  if (isCorrectVideo) {
    return {
      url: match[ID_POSITION],
      isVideo: true,
    };
  }

  return { url: null, isVideo: false };
}

function getContent(url, isVideo, campaignId) {
  if (isVideo) {
    return (
      <YoutubeBackground
        className={styles.videoWrapper}
        videoId={url}
        opts={videoOptions}
        aspectRatio="4:3"
      />
    );
  }

  return (
    <Image
      wrapperClassName={classnames(styles.imageWrapper, {
        [styles.topStretchingImageWrapper]: campaignsWithCustomHeader.includes(
          campaignId,
        ),
      })}
      className={styles.image}
      src={url}
      alt="Cover for campaign"
    />
  );
}

export default function BrandHeader({
  campaignData: { brandCover = '', brandVideo = '', primaryColor, id },
  campaignId,
}) {
  const { url, isVideo } = getCoverParams(brandCover, brandVideo);

  return (
    <section
      className={classnames(styles.header, {
        [styles.topStretchingHeader]: campaignsWithCustomHeader.includes(
          campaignId,
        ),
      })}
    >
      {id || campaignId === 'new' ? (
        <div
          style={{ background: url ? 'inherit' : primaryColor }}
          className={classnames(styles.header, {
            [styles.topStretchingHeader]: campaignsWithCustomHeader.includes(
              campaignId,
            ),
          })}
        >
          {url && getContent(url, isVideo, campaignId)}
        </div>
      ) : (
        <FontAwesomeIcon className={styles.icon} icon={faSadTear} />
      )}
    </section>
  );
}
