import { connect } from 'socket.io-client';

import { SET_PAYMENT_COMPLETE } from '../profile/actions';

const handlers = {
  SET_PAYMENT_COMPLETE,
};

class Ws {
  constructor(dispatch) {
    this.io = connect('/');
    this.dispatch = dispatch;

    Object.entries(handlers).forEach(([name, type]) =>
      this.io.on(name, data => this.dispatch({ type, data })),
    );
  }
}

export default function wsMiddleware() {
  return ({ dispatch }) => {
    new Ws(dispatch);
    return next => action => next(action);
  };
}
