import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { campaignData } from '../../redux/landing/selectors';
import { goToPageAndScroll } from '../../redux/router/actions';
import {
  campaignId,
  isRootLanding,
  isBuyLanding,
  isPaymentLanding,
  isSuccessLanding,
  isBalanceLanding,
} from '../../redux/router/selectors';
import NewBrandHeader from './NewBrandHeader';

const mapStateToProps = createStructuredSelector({
  campaignData,
  campaignId,
  isRootLanding,
  isBuyLanding,
  isPaymentLanding,
  isSuccessLanding,
  isBalanceLanding,
});
const mapDispatchToProps = {
  goToPageAndScroll,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewBrandHeader);
