import React, { Component } from 'react';
import { change } from 'redux-form';
import { CloudUploadOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { Upload, Empty, Modal } from 'antd';
import * as classes from './FileUpload.module.scss';
import Image from '../../Image';
import { CREATE_CAMPAIGN_DETAILS } from '../../../redux/forms/names';

const { Dragger } = Upload;

const uploadHints = {
  logoImage: 'Please, upload one image to be used as a logo',
};

export default class FileUpload extends Component {
  state = {
    fileList: [],
    isOpen: false,
  };

  handleModalClick = () => {
    if (!this.props.input.value) {
      return true;
    }

    this.setState({ isOpen: !this.state.isOpen });
  };

  handleChange = ({ file }) => {
    const { meta, input, name } = this.props;

    this.setState({ fileList: [file] });

    if (input.value) {
      meta.dispatch(change(CREATE_CAMPAIGN_DETAILS, name, null));
    }
  };

  handleRemove = async () => {
    const { meta, input } = this.props;

    if (input.value) {
      meta.dispatch(change(CREATE_CAMPAIGN_DETAILS, input.name, null));
    }
  };

  customRequest = async e => {
    const {
      file,
      file: { uid, name: fileName },
    } = e;
    const { deleteFile, uploadFile, input } = this.props;

    if (input.value) {
      deleteFile(input.value);
    }

    await uploadFile({
      file,
      uid,
      field: input.name,
      path: `campaigns/logos`,
      name: fileName,
    });

    return {
      abort() {},
    };
  };

  render() {
    const { name, input, background } = this.props;
    const { fileList, isOpen } = this.state;

    return (
      <div className={classes.wrapper}>
        <Dragger
          className={classes.dragger}
          onChange={e => this.handleChange(e, name)}
          customRequest={e => this.customRequest(e, name)}
          fileList={fileList}
          listType="picture-card"
          showUploadList={false}
          multiple={false}
          name={name}
          accept="image/png, image/jpeg, image/jpg"
        >
          {input.value ? (
            <div className={classes.tip}>
              <CheckSquareOutlined />
              <p>Файл завантажено</p>
            </div>
          ) : (
            <div className={classes.tip}>
              <CloudUploadOutlined />
              <p>Перетягніть файл або виберіть</p>
            </div>
          )}
          <p className="ant-upload-hint">{uploadHints[name]}</p>
        </Dragger>
        {input.value ? (
          <Image
            wrapperClassName={classes.image}
            background={background}
            src={input.value}
            onClick={this.handleModalClick}
            onRemoveClick={this.handleRemove}
            alt={`Campaign ${input.name}`}
          />
        ) : (
          <Empty description={<span>Зображення відсутнє</span>} />
        )}

        <Modal
          title="Картинка"
          onCancel={this.handleModalClick}
          visible={isOpen}
          footer={null}
        >
          <Image
            background={background}
            wrapperClassName={classes.modal}
            src={input.value}
            alt={`Campaign ${input.name}`}
          />
        </Modal>
      </div>
    );
  }
}
