import { combineReducers } from 'redux';
import * as actions from './actions';
import { USE_CERTIFICATE_REQUEST, USE_CERTIFICATE_SUCCESS } from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case actions.GET_CERTIFICATES_REQUEST:
      case actions.USE_CERTIFICATE_REQUEST:
      case actions.DEACTIVATE_CERTIFICATE_REQUEST:
      case actions.EXPORT_CLIENTS_REQUEST:
      case actions.RESEND_CERTIFICATE_REQUEST:
      case actions.DASHBOARD_CREATE_CERTIFICATES_REQUEST:
      case actions.GET_BALANCE_REQUEST: {
        return true;
      }
      case actions.GET_CERTIFICATES_FAILURE:
      case actions.GET_CERTIFICATES_SUCCESS:
      case actions.USE_CERTIFICATE_FAILURE:
      case actions.USE_CERTIFICATE_SUCCESS:
      case actions.DEACTIVATE_CERTIFICATE_FAILURE:
      case actions.DEACTIVATE_CERTIFICATE_SUCCESS:
      case actions.EXPORT_CLIENTS_SUCCESS:
      case actions.EXPORT_CLIENTS_FAILURE:
      case actions.GET_BALANCE_SUCCESS:
      case actions.GET_BALANCE_FAILURE:
      case actions.RESEND_CERTIFICATE_SUCCESS:
      case actions.DASHBOARD_CREATE_CERTIFICATES_SUCCESS:
      case actions.DASHBOARD_CREATE_CERTIFICATES_FAILURE:
      case actions.RESEND_CERTIFICATE_FAILURE: {
        return false;
      }
      default: {
        return state;
      }
    }
  },
  error(state = null, action) {
    switch (action.type) {
      case actions.USE_CERTIFICATE_FAILURE: {
        return action.error.description;
      }
      case USE_CERTIFICATE_SUCCESS:
      case USE_CERTIFICATE_REQUEST:
        return null;

      default:
        return null;
    }
  },

  page(state = 1, action) {
    switch (action.type) {
      case actions.CHANGE_PAGE: {
        return action.payload.page;
      }
      default: {
        return state;
      }
    }
  },

  total(state = 0, action) {
    switch (action.type) {
      case actions.GET_CERTIFICATES_SUCCESS: {
        return action.data.total;
      }
      default: {
        return state;
      }
    }
  },

  search(state = '', action) {
    switch (action.type) {
      case actions.CHANGE_SEARCH: {
        return action.payload.search;
      }
      default: {
        return state;
      }
    }
  },

  list(state = [], action) {
    switch (action.type) {
      case actions.GET_CERTIFICATES_SUCCESS: {
        return action.data.list;
      }
      case actions.USE_CERTIFICATE_SUCCESS:
      case actions.DEACTIVATE_CERTIFICATE_SUCCESS: {
        const { id, status, remainder, transactions } = action.data;

        return state.map(certificate =>
          certificate.id === id
            ? {
                ...certificate,
                status: status,
                remainder: Number.isInteger(remainder)
                  ? remainder
                  : certificate.remainder,
                transactions: transactions || certificate.transactions,
              }
            : certificate,
        );
      }

      case actions.RESEND_CERTIFICATE_SUCCESS: {
        const { certificate } = action.data;

        return state.map(c =>
          c.id === certificate.id ? { ...c, ...certificate } : c,
        );
      }

      default: {
        return state;
      }
    }
  },

  balance(state = { amount: 0, code: null }, action) {
    switch (action.type) {
      case actions.GET_BALANCE_SUCCESS: {
        const { amount, code } = action.data;

        return {
          amount,
          code,
        };
      }
      default:
        return state;
    }
  },
  selectedCertificateId(state = null, action) {
    switch (action.type) {
      case actions.SET_SELECTED_CERTIFICATE_ID: {
        return action.payload;
      }
      case actions.CLEAR_SELECTED_CERTIFICATE_ID: {
        return null;
      }
      default: {
        return state;
      }
    }
  },
});
