import { change, clearFields, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import PageSettings from './PageSettings';
import {
  checkSlug,
  resetSlugError,
  setPreview,
} from '../../../redux/campaign/actions';
import {
  certificateTiersValues,
  selectedCampaignUrl,
  campaignInitialValues,
  selectedCampaignPreviewUrl,
  isCreateCertificateTierDisabled,
  isCreateCampaignDisabled,
  campaignToSave,
  error,
  currentCampaign,
  pageSettingsValues,
} from '../../../redux/campaign/selectors';
import { PAGE_SETTINGS } from '../../../redux/forms/names';

const form = reduxForm({
  form: PAGE_SETTINGS,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(PageSettings);

const initialValues = createSelector(campaignInitialValues, campaign => {
  if (campaign) {
    const extraFormField = campaign.extraFormField || {};
    return {
      facebook: campaign.facebook,
      instagram: campaign.instagram,
      telegram: campaign.telegram,
      email: campaign.email,
      slug: campaign.slug,
      phone: campaign.phone,
      address: campaign.address,
      about: campaign.about,
      brandUrl: campaign.brandUrl,
      brandName: campaign.brandName,
      tagManagerHead: campaign.tagManagerHead,
      tagManagerBody: campaign.tagManagerBody,
      googleMerchantCenter: campaign.googleMerchantCenter,
      fieldLabel: extraFormField.fieldLabel || '',
      isFieldRequired: Boolean(extraFormField.isFieldRequired),
      seoTitle: campaign.seoTitle,
      seoDescription: campaign.seoDescription,
      newDesign: campaign.newDesign || false,
    };
  }
});

const actions = {
  checkSlug,
  clearFields,
  resetSlugError,
  setPreview,
  changeField: change,
};
const selector = createStructuredSelector({
  initialValues,
  slugError: error,
  createValues: certificateTiersValues,
  url: selectedCampaignUrl,
  previewUrl: selectedCampaignPreviewUrl,
  disabled: isCreateCertificateTierDisabled,
  isPreviewDisabled: isCreateCampaignDisabled,
  previewCampaign: campaignToSave,
  currentCampaign,
  pageSettingsValues,
});

export default connect(selector, actions)(form);
