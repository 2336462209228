import React from 'react';
import styles from './LiqpayAccountCreatePage.module.scss';
import { CopyOutlined } from '@ant-design/icons';
import { Table as AntTable } from 'antd';
import { getCampaignUrl, copyToClipboard } from '../../utils';
import classnames from 'classnames';

const LiqpayTable = ({ campaigns }) => {
  return (
    <div className={styles.table}>
      <AntTable
        rowKey="uid"
        className={classnames({
          [styles.withData]: Boolean(campaigns.length),
        })}
        columns={[
          {
            title: '№',
            dataIndex: 'index',
            key: 'index',
            render: function showNum(text, record, key) {
              return <span>#{key + 1}</span>;
            },
          },
          {
            title: 'Назва кампанії',
            dataIndex: 'name',
            key: 'name',
            render: function showName(text) {
              return <span>{text}</span>;
            },
          },
          {
            title: 'Адреса',
            dataIndex: 'url',
            key: 'url',
            render: function showLink(text, record) {
              return <span>{getCampaignUrl(record.identifier)}</span>;
            },
          },
          {
            title: null,
            key: 'action',
            render: function showActions(text, record) {
              const url = getCampaignUrl(record.identifier);

              return (
                <span onClick={() => copyToClipboard(url)}>
                  <CopyOutlined className={styles.copy} />
                </span>
              );
            },
          },
        ]}
        size="small"
        dataSource={campaigns}
        pagination={false}
      />
    </div>
  );
};
export default LiqpayTable;
