import React from 'react';
import classnames from 'classnames';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { getFontColor } from '../../utils';
import { ROUTES } from '../../constants/routes';
import { USER_LANDING } from '../../constants/texts';
import { FacebookIcon, InstagramIcon, TelegramIcon } from './SocialIcons';
import styles from './LandingFooter.module.scss';

const { Footer } = Layout;

const LandingFooter = ({
  campaignId,
  campaignData: {
    footerLogo,
    heading,
    primaryColor,
    instagram,
    facebook,
    telegram,
    address,
    email,
    phone,
    language,
    brandUrl,
    brandName,
  },
}) => {
  const { isFontWhite } = getFontColor(primaryColor);
  return (
    <Footer className={styles.footer} style={{ backgroundColor: primaryColor }}>
      <div className={styles.info}>
        {footerLogo && (
          <Link
            className={styles.logo}
            to={ROUTES.USER_LANDING.replace(':id', campaignId)}
          >
            <img src={footerLogo} alt={heading} />
          </Link>
        )}

        <div className={styles.address}>
          {address && (
            <div
              className={classnames(styles.disabled, styles.restriction, {
                [styles.white]: isFontWhite,
                [styles.dark]: !isFontWhite,
              })}
            >
              {address}
            </div>
          )}
          {phone && (
            <div
              className={classnames({
                [styles.white]: isFontWhite,
                [styles.dark]: !isFontWhite,
              })}
            >
              <a href={`tel:${phone}`}>{phone}</a>
            </div>
          )}
          {email && (
            <div
              className={classnames({
                [styles.white]: isFontWhite,
                [styles.dark]: !isFontWhite,
              })}
            >
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          )}
          <div className={styles.payments}>
            <FontAwesomeIcon
              className={classnames(styles.paymentIcon, styles.disabled, {
                [styles.lightGrey]: isFontWhite,
                [styles.darkGrey]: !isFontWhite,
              })}
              icon={faCcVisa}
            />
            <FontAwesomeIcon
              className={classnames(styles.paymentIcon, styles.disabled, {
                [styles.lightGrey]: isFontWhite,
                [styles.darkGrey]: !isFontWhite,
              })}
              icon={faCcMastercard}
            />
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.terms}>
          <div className={styles.termsContent}>
            {brandName && brandUrl && (
              <a
                className={classnames(styles.socialIcon, {
                  [styles.white]: isFontWhite,
                  [styles.dark]: !isFontWhite,
                })}
                href={brandUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {brandName}
              </a>
            )}
            <Link
              className={classnames(styles.socialIcon, {
                [styles.white]: isFontWhite,
                [styles.dark]: !isFontWhite,
              })}
              to={ROUTES.TERMS}
            >
              {USER_LANDING.FOOTER_POLICY[language]}
            </Link>
            <Link
              className={classnames(styles.socialIcon, {
                [styles.white]: isFontWhite,
                [styles.dark]: !isFontWhite,
              })}
              to={ROUTES.AGREEMENT}
            >
              {USER_LANDING.FOOTER_OFFER[language]}
            </Link>
          </div>
        </div>
        <div className={styles.social}>
          {telegram && (
            <a href={telegram} target="_blank" rel="noopener noreferrer">
              <TelegramIcon
                className={classnames(styles.socialIcon, {
                  [styles.lightGrey]: isFontWhite,
                  [styles.darkGrey]: !isFontWhite,
                })}
              />
            </a>
          )}
          {facebook && (
            <a href={facebook} target="_blank" rel="noopener noreferrer">
              <FacebookIcon
                className={classnames(styles.socialIcon, {
                  [styles.lightGrey]: isFontWhite,
                  [styles.darkGrey]: !isFontWhite,
                })}
              />
            </a>
          )}
          {instagram && (
            <a href={instagram} target="_blank" rel="noopener noreferrer">
              <InstagramIcon
                className={classnames(styles.socialIcon, {
                  [styles.lightGrey]: isFontWhite,
                  [styles.darkGrey]: !isFontWhite,
                })}
              />
            </a>
          )}
        </div>
      </div>
    </Footer>
  );
};

export default LandingFooter;
