const data = [
  {
    question: 'Що таке Upfront?',
    answer:
      'Upfront - це сервіс, який представлено у форматі онлайн-конструктору, що дозволяє самостійно формувати, адмініструвати та продавати електронні сертифікати під власним брендом.\n' +
      'Сервіс Upfront створений для компаній та підприємців, який допомагає просто і швидко налаштувати онлайн продажі сертифікатів. \n' +
      'Сервіс створений для організації нового каналу продажу, збільшення прибутку та залучення нових клієнтів, а також економії часу та витрат на створення та адміністрування електронних сертифікатів для Вашого бізнесу.\n',
  },
  {
    question: 'Що таке електронний сертифікат?',
    answer:
      'Електронний сертифікат – це електронний документ, що підтверджує платіж у вигляді передоплати на заплановані до придбання та отримання товари (роботи, послуги) в зазначених закладах чи магазинах на суму, що еквівалентна номіналу сертифіката. \n',
  },
  {
    question:
      'Яка різниця між сервісом Upfront та сучасними маркетплейсами для продажу онлайн сертифікатів?',
    answer:
      'Сервіс Upfront надає можливість кожному окремо бізнесу запустити White Label сайт для продажу електронних сертифікатів та ваучерів своїх послуг. Створену сторінку сайту можливо використовувати для реклами в Instagram, Facebook або для email-розсилок.\n' +
      'Кінцевий споживач, відвідуючи створену за допомогою сервісу Upfront сторінку сайту з електронними сертифікатам, має можливість вибору та придбання сертифікатів на послуги тільки Вашої компанії, проте як на маркетплейсі з продажу онлайн сертифікатів, вже доступний вибір з поміж подібних пропозицій та конкурентів Вашого бізнесу. \n',
  },
  {
    question: 'Яке програмне забезпечення потрібно для роботи з сервісом?',
    answer:
      'Upfront - це онлайн сервіс, тому ніякого спеціального програмного забезпечення не потрібно, лише браузер та доступ до мережі Інтернет.',
  },
  {
    question: 'Що необхідно, щоб розпочати роботу з сервісом Upfront?',
    answer:
      '1. Зареєструватися на платформі \n' +
      '2. Створити кампанію, сертифікати - вказати вартість, термін дії, підібрати дизайн і підключити платіжну систему\n' +
      '3. Запустити промо-кампанію\n',
  },
  // {
  //   question: 'Як створити кампанію, сертифікати?\n',
  //   answer:
  //     'Інструкція - створення кампанії, сертифікатів (активная ссылка на инструкцию, (подготовить инструкцию!!!)\n',
  // },
  // {
  //   question:
  //     'Кампанія і сертифікати створено, як розпочати роботу з сертифікатами?\n',
  //   answer:
  //     '1. Необхідно підключити платіжну систему, створити профіль liqpay\n' +
  //     'Інструкція - підключення платіжної системи (активная ссылка на инструкцию, (отредактировать и перевести на укр. уже подготовленную инструкцию!!!)\n' +
  //     '2. Запустити промо-кампанію\n',
  // },
  // {
  //   question: 'Як підключити платіжну систему?\n',
  //   answer:
  //     'Інструкція - підключення платіжної системи (активная ссылка на инструкцию, (отредактировать и перевести на укр. уже подготовленную инструкцию!!!)\n',
  // },
  {
    question: 'Чи можливо підключити вже існуючий профіль liqpay?',
    answer:
      "Так, можливо, але обов'язково необхідно створити окремий мерчант в рамках існуючого аккаунта",
  },
  {
    question: 'Як клієнт отримує придбаний електронний сертифікат?',
    answer:
      'Якщо клієнт придбав сертифікат для себе, на вказану при купівлі сертифікату електронну пошту, буде відправлено сертифікат з QR-кодом та кодом у літеро-цифровому форматі, які будуть необхідні для використання сертифікату.\n' +
      'Якщо клієнт придбав сертифікат у подарунок, на вказану при купівлі сертифікату електронну пошту та з персоналізованним повідомленням для отримувача від даруючого, буде відправлено сертифікат з QR-кодом та кодом у літеро-цифровому форматі, які будуть необхідні для використання сертифікату. А на електронну адресу даруючого сертифікат, буде відправлено повідомлення про успішно виконане замовлення.\n',
  },
  // {
  //   question: 'Як управляти сертифікатами?',
  //   answer:
  //     'Управління та адміністрування сертифікатами відбувається через особистий кабінет сервісу Upfront\n' +
  //     'Інструкція - управління сертифікатами (активная ссылка на инструкцию, (отредактировать и перевести на укр. уже подготовленную инструкцию!!!)\n',
  // },
  {
    question:
      'В якому домені буде сформовано посилання на сторінку сайту з електронними сертифікатами? ',
    answer: 'В домені upfront',
  },
  {
    question:
      'Чи можлива заміна домену upfront на домен компанії, в посиланні на сторінку сайту з електронними сертифікатами? ',
    answer: 'Так, можлива',
  },
];
export default data;
