import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { campaignData } from '../../redux/landing/selectors';

import CertificateCard from './CertificateCard';

const selector = createStructuredSelector({ campaignData });

const actions = {};

export { CertificateCard };

export default connect(selector, actions)(CertificateCard);
