import { createSelector } from 'reselect';
import { isValid } from 'redux-form';
import { prefix } from './actions';
import { pathname } from '../router/selectors';
import { user } from '../profile/selectors';
import { ROUTES } from '../../constants/routes';
import { getFullUrl } from '../../utils';
import { CREATE_CAMPAIGN_DETAILS } from '../forms/names';
import { CERTIFICATE_TYPES, LANGUAGE_VALUES } from '../../constants/labels';
import { sortCertificates } from '../../helpers/sorting';
import { getFormValues, validateForm } from '../forms/utils';
import {
  populateCampaignWithFormValues,
  prepareCampaignForTableRow,
} from './utils';

const root = state => state[prefix];

const excludedFields = ['certificateTiers'];

export const loading = createSelector(root, state => state.loading);

export const page = createSelector(root, state => state.page);

export const total = createSelector(root, state => state.total);

export const error = createSelector(root, state => state.error);

export const isFirstCampaignModalShown = createSelector(
  root,
  state => state.isFirstCampaignModalShown,
);

export const getSelectedCampaignId = createSelector(
  root,
  state => state.selectedCampaignId,
);

export const campaignsList = createSelector(root, state => state.list);

export const shortCampaigns = createSelector(root, state => state.shortList);

export const isCampaignsExists = createSelector(campaignsList, list =>
  Boolean(list.length),
);

export const campaigns = createSelector(campaignsList, list => {
  return list.map(prepareCampaignForTableRow);
});

export const formCampaigns = createSelector(shortCampaigns, list => {
  return list.map(({ id, name }) => ({ value: id, label: name }));
});

export const currentCampaign = createSelector(
  pathname,
  campaigns,
  getSelectedCampaignId,
  (pathname, campaigns, selectedCampaignId) => {
    if (selectedCampaignId) {
      const campaign = campaigns.find(
        campaign => campaign.id === selectedCampaignId,
      );

      return populateCampaignWithFormValues(
        campaign,
        { id: null, slug: null },
        { _id: null },
      );
    }

    if (pathname.includes('edit-campaign')) {
      const id = pathname.split('/').pop();
      const campaign = campaigns.find(campaign => campaign.id === id);

      if (campaign) {
        return populateCampaignWithFormValues(campaign);
      }
    }

    return null;
  },
);

export const campaignInitialValues = createSelector(
  currentCampaign,
  pathname,
  (campaign, pathname) => {
    if (campaign) {
      const extraFormField = campaign.extraFormField || {};

      return {
        ...campaign,
        translation: campaign.translation || {},
        fieldLabel: extraFormField.fieldLabel || '',
        isFieldRequired: Boolean(extraFormField.isFieldRequired),
        backgroundColor: campaign.backgroundColor || '#F6F9FE',
      };
    }

    if (!pathname.includes('edit-campaign')) {
      return {
        translation: {},
        primaryColor: '#48B6BB',
        backgroundColor: '#F6F9FE',
        language: LANGUAGE_VALUES.UK,
        certificateTypeByDefault: CERTIFICATE_TYPES.SELF,
      };
    }

    return null;
  },
);

export const certificateTiersValues = createSelector(
  state => state.form.createCertificateTiers,
  form => {
    if (!form || !form.values) {
      return { certificateTiers: [] };
    }

    return form.values;
  },
);

export const pageSettingsValues = createSelector(
  state => state.form.pageSettings,
  getFormValues,
);

export const isCertificateTiersMissing = createSelector(
  certificateTiersValues,
  pageSettingsValues,
  (tiers, pageSettings) => {
    const { certificateTiers } = tiers;
    const { phone, email, about } = pageSettings;
    const isTiersMissing = certificateTiers
      ? certificateTiers.length === 0
      : true;

    return isTiersMissing || !phone || !email || !about;
  },
);

export const isCreateCampaignDisabled = createSelector(
  isValid(CREATE_CAMPAIGN_DETAILS),
  isCertificateTiersMissing,
  (isDetailsValid, isCertificateDisabled) => {
    return !isDetailsValid || isCertificateDisabled;
  },
);

export const selectedCampaignUrl = createSelector(currentCampaign, campaign => {
  if (!campaign) {
    return null;
  }

  return getFullUrl(ROUTES.USER_LANDING.replace(':id', campaign.id));
});

export const submitName = createSelector(currentCampaign, campaign =>
  campaign ? 'Зберегти зміни' : 'Створити кампанію',
);

export const selectedCampaignPreviewUrl = createSelector(
  currentCampaign,
  campaign => {
    const identifier = campaign ? campaign.id : 'new';

    return ROUTES.USER_LANDING_PREVIEW.replace(':id', identifier);
  },
);

export const campaignToSave = createSelector(
  state => state.form.createCertificateTiers,
  state => state.form.createCampaign,
  state => state.form.pageSettings,
  user,
  currentCampaign,
  (certificateForm, createForm, pageSettingsForm, user, campaign) => {
    if (
      !certificateForm ||
      !createForm ||
      !pageSettingsForm ||
      !certificateForm.values ||
      !pageSettingsForm.values
    ) {
      return null;
    }

    const certificateTiersValues =
      certificateForm.values && certificateForm.values.certificateTiers
        ? certificateForm.values
        : {};

    const { certificateTiers = [] } = certificateTiersValues;
    const {
      instagram,
      facebook,
      telegram,
      address,
      phone,
      email,
      about,
      slug,
      brandUrl,
      brandName,
      tagManagerBody,
      tagManagerHead,
      googleMerchantCenter,
      isFieldRequired = false,
      fieldLabel = '',
      seoTitle,
      seoDescription,
      newDesign,
    } = pageSettingsForm.values;
    const values = {
      ...createForm.values,
      instagram,
      facebook,
      telegram,
      address,
      phone,
      email,
      about,
      brandUrl,
      brandName,
      seoTitle,
      seoDescription,
      newDesign,
      extraFormField: {
        isFieldRequired,
        fieldLabel,
      },
      slug,
      tagManagerBody: (tagManagerBody || '').replace('\n', ''),
      tagManagerHead: (tagManagerHead || '').replace('\n', ''),
      googleMerchantCenter: (googleMerchantCenter || '').replace('\n', ''),
      userId: user.id,
      certificateTiers: certificateTiers.map(certificate => ({
        ...certificate,
        price: certificate.price * 100,
        value: certificate.value * 100,
      })),
    };

    if (campaign) {
      values.id = campaign.id;
    }

    return values;
  },
);

export const isCreateCertificateTierDisabled = createSelector(
  state => state.form.createCertificateTiers,
  validateForm(excludedFields),
);

export const footerCustomData = createSelector(campaignToSave, campaign => {
  if (!campaign) {
    return {};
  }

  const { footerLogo, primaryColor } = campaign;

  return {
    logo: footerLogo,
    color: primaryColor,
  };
});

export const certificatesFilteredByOrder = createSelector(
  state => state.form.createCertificateTiers,
  certificateForm => {
    const { values } = certificateForm || {};

    if (!values || (values && !values.certificateTiers)) {
      return [];
    }

    return sortCertificates(values.certificateTiers, 'order');
  },
);
