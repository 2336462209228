import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case actions.GET_CAMPAIGNS_REQUEST:
      case actions.DELETE_CAMPAIGN_REQUEST:
      case actions.UPLOAD_FILE_REQUEST:
      case actions.CREATE_CAMPAIGN_REQUEST:
      case actions.CHANGE_CAMPAIGN_STATUS_REQUEST:
      case actions.CHECK_SLUG_REQUEST:
      case actions.GET_SHORT_CAMPAIGNS_REQUEST: {
        return true;
      }
      case actions.GET_CAMPAIGNS_FAILURE:
      case actions.GET_CAMPAIGNS_SUCCESS:
      case actions.DELETE_CAMPAIGN_FAILURE:
      case actions.DELETE_CAMPAIGN_SUCCESS:
      case actions.UPLOAD_FILE_FAILURE:
      case actions.UPLOAD_FILE_SUCCESS:
      case actions.CREATE_CAMPAIGN_FAILURE:
      case actions.CREATE_CAMPAIGN_SUCCESS:
      case actions.CHANGE_CAMPAIGN_STATUS_FAILURE:
      case actions.CHANGE_CAMPAIGN_STATUS_SUCCESS:
      case actions.CHECK_SLUG_SUCCESS:
      case actions.CHECK_SLUG_FAILURE:
      case actions.GET_SHORT_CAMPAIGNS_SUCCESS:
      case actions.GET_SHORT_CAMPAIGNS_FAILURE: {
        return false;
      }
      default: {
        return state;
      }
    }
  },

  selectedCampaignId(state = null, action) {
    switch (action.type) {
      case actions.SELECT_CAMPAIGN: {
        return action.payload;
      }
      case actions.CLEAR_SELECTED_CAMPAIGN: {
        return null;
      }
      default: {
        return state;
      }
    }
  },

  page(state = 1, action) {
    switch (action.type) {
      case actions.CHANGE_PAGE: {
        return action.payload.page;
      }
      default: {
        return state;
      }
    }
  },

  total(state = 0, action) {
    switch (action.type) {
      case actions.GET_CAMPAIGNS_SUCCESS: {
        return action.data.total;
      }
      default: {
        return state;
      }
    }
  },

  list(state = [], action) {
    switch (action.type) {
      case actions.GET_CAMPAIGNS_SUCCESS: {
        return action.data.list;
      }
      case actions.CHANGE_CAMPAIGN_STATUS_SUCCESS: {
        const { id, status } = action.data;

        return state.map(item => (item.id === id ? { ...item, status } : item));
      }
      default: {
        return state;
      }
    }
  },

  shortList(state = [], action) {
    switch (action.type) {
      case actions.GET_SHORT_CAMPAIGNS_SUCCESS: {
        return action.data.list;
      }
      default: {
        return state;
      }
    }
  },

  isFirstCampaignModalShown(state = false, action) {
    switch (action.type) {
      case actions.CREATE_CAMPAIGN_SUCCESS: {
        return action.data.isFirst;
      }
      default:
        return state;
    }
  },

  error(state = null, action) {
    switch (action.type) {
      case actions.RESET_SLUG_ERROR: {
        return null;
      }

      case actions.CHECK_SLUG_SUCCESS: {
        return action.data.error;
      }

      default:
        return state;
    }
  },
});
