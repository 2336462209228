import React, { Component } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import classes from './LiqpayAccountCreatePage.module.scss';
import { ROUTES } from '../../constants/routes';
import { faFireAlt, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LiqpayTable from './LiqpayTable';
import { INSTRUCTIONS } from '../../constants/instructions';

function createLiqpayScript() {
  const script = document.createElement('script');
  script.src = 'https://static.liqpay.ua/libjs/loader.liqpay.js';
  script.async = true;
  document.body.appendChild(script);
}

function deleteLiqpayScript() {
  const liqpayScript = document.getElementById('liqpayCheckoutLib');
  liqpayScript && liqpayScript.remove();
}

export default class PaymentSettingsPage extends Component {
  componentDidMount() {
    const {
      liqpayAccountCreate,
      getCampaigns,
      isPaymentCreated,
      userId,
    } = this.props;

    if (isPaymentCreated) {
      return;
    }

    getCampaigns(userId, 0);
    liqpayAccountCreate();
  }

  componentDidUpdate(prevProps, prevState) {
    const { liqpayAccountCreateData, isPaymentCreated } = this.props;
    if (isPaymentCreated) {
      return;
    }

    if (
      liqpayAccountCreateData &&
      liqpayAccountCreateData !== prevProps.liqpayAccountCreateData
    ) {
      const { data, signature } = liqpayAccountCreateData;
      window.LiqPayCallback = function (app) {
        app.init({
          widget: 'pageregist',
          data,
          signature,
          language: 'uk',
          embedTo: '#liqpay',
          mode: 'embed', // embed || popup
        });
      };
      createLiqpayScript();
    }
  }

  componentWillUnmount() {
    deleteLiqpayScript();
  }

  render() {
    const { isPaymentCreated, campaigns } = this.props;
    return (
      <div
        className={classnames(classes.container, {
          [classes.success]: isPaymentCreated,
        })}
      >
        {isPaymentCreated ? (
          <div className={classnames(classes.column, classes.indent)}>
            <FontAwesomeIcon className={classes.icon} icon={faFireAlt} />
            <h3>Вітаємо! Ваш Liqpay профіль створено</h3>
          </div>
        ) : (
          <div className={classes.column}>
            <FontAwesomeIcon className={classes.icon} icon={faMoneyCheckAlt} />
            <h3>Створіть Liqpay профіль для проведення платежів</h3>

            <div className={classes.wrapper}>
              <span className={classes.subTitle}>Мої кампанії</span>
              <LiqpayTable campaigns={campaigns} />
              <a
                className={classes.paymentLink}
                href={INSTRUCTIONS.PAYMENTS.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Переглянути інструкцію з підключення платежів
              </a>
            </div>
          </div>
        )}
        {isPaymentCreated && (
          <div className={classes.button}>
            <p className={classes.publishInfo}>
              Тепер ви можете опублікувати кампанію
            </p>
            <Link to={ROUTES.CAMPAIGNS}>
              <Button>Перейти до редагування кампаній</Button>
            </Link>
          </div>
        )}

        <div
          id="liqpay"
          className={classnames({ [classes.liqpayHidden]: isPaymentCreated })}
        />
      </div>
    );
  }
}
