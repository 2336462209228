import { connect } from 'react-redux';
import CertificatesPage from './CertificatesPage';

import { loading } from '../../redux/certificate/selectors';
import { createStructuredSelector } from 'reselect';

const selector = createStructuredSelector({
  loading,
});

export default connect(selector, null)(CertificatesPage);
