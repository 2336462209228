import React from 'react';
import { Modal, Upload, Button, message } from 'antd';
import classes from './AddImageModal.module.scss';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { FieldArray } from 'redux-form';
import { IMAGE_UPLOAD_ACCEPT } from '../../../../constants/imagesValidation';

class AddImageModal extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    files: [],
  };

  handlePreview = src => () => {
    this.setState({
      previewImage: src,
      previewVisible: true,
    });
  };

  handleChange = info => {
    const files = info.fileList.map(file => {
      if (file.response) {
        return {
          ...file,
          url: file.response.url,
        };
      }

      return file;
    });

    this.setState({ files });
  };

  handleCancel = () => {
    this.setState({
      previewVisible: false,
    });
  };

  customRequest = async e => {
    const LIMIT = 5242880; //5MB
    const PLAIN_IMAGE_EXTENSIONS = ['.jpg', '.jpeg', '.png'];
    const {
      certificateTiersValues: { images },
      uploadFile,
    } = this.props;

    const index = images ? images.length : 0;
    const {
      file,
      file: { uid, name: fileName, size },
    } = e;

    if (!file) {
      return;
    }
    const { name } = file; //eslint-fix
    const extension = name.split('.').pop();
    if (size > LIMIT) {
      return message.error('Image is too big. Maximum file size 5Mb.');
    }
    if (!PLAIN_IMAGE_EXTENSIONS.includes('.' + extension)) {
      return message.error(
        'Image has wrong extension. Please upload .jpg, .jpeg or .png',
      );
    }

    await uploadFile({
      file,
      uid,
      field: `images-${index}`,
      path: `certificates/images`,
      name: fileName,
    });

    return {
      abort() {},
    };
  };

  renderGallery = ({ fields }) => {
    const images = fields.getAll() || [];
    const maxFiles = 8;
    const { files } = this.state;
    const isUploadShown = images.length < maxFiles;
    return (
      <>
        <ul className={classes.imageList}>
          {images.map((img, index) => {
            return (
              <li key={img}>
                <img
                  src={img}
                  className={classes.image}
                  alt={`Certificate logo ${index}`}
                />
                <div className={classes.imgOptions}>
                  <EyeOutlined onClick={this.handlePreview(img)} />
                  <DeleteOutlined onClick={() => fields.remove(index)} />
                </div>
              </li>
            );
          })}
        </ul>
        <Upload
          customRequest={this.customRequest}
          fileList={files}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          showUploadList={false}
          accept={IMAGE_UPLOAD_ACCEPT}
          multiple
        >
          {isUploadShown && (
            <div>
              <Button>Завантажити</Button>
            </div>
          )}
        </Upload>
      </>
    );
  };

  render() {
    const buttonsProps = { style: { display: 'none' } };
    const {
      visible,
      toggleModal,
      certificateTiersValues: { images },
    } = this.props;
    const { previewImage, previewVisible } = this.state;

    return (
      <>
        <Modal
          centered
          visible={visible}
          cancelButtonProps={buttonsProps}
          title="Редагування фото"
          onCancel={toggleModal}
          onOk={toggleModal}
          okText="Готово"
          destroyOnClose
          width={images && images.length ? '750px' : '400px'}
        >
          <div className={classes.column}>
            <FieldArray component={this.renderGallery} name="images" />
          </div>
        </Modal>
        <Modal
          okButtonProps={buttonsProps}
          cancelButtonProps={buttonsProps}
          visible={previewVisible}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
        >
          <img
            alt="example"
            className={classes.previewImage}
            src={previewImage}
          />
        </Modal>
      </>
    );
  }
}
export default AddImageModal;
