import React from 'react';
import { Button } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import upIcon from '../../../assets/images/arrow-up.svg';
import downIcon from '../../../assets/images/arrow-down.svg';
import { MAX_PRICE_VALUE } from '../../../constants/texts';
import { formatPrice } from '../../../helpers/stringFormatters';
import classes from './Quantity.module.scss';

class Quantity extends React.Component {
  handleAddClick = () => {
    const { value, max, price, onChange } = this.props;
    const number = value + 1;

    if (number * price > MAX_PRICE_VALUE) {
      return onChange(value);
    }

    if (number >= max) {
      return onChange(max);
    }

    onChange(number);
  };

  handleMinusClick = () => {
    const { value, price, onChange, min } = this.props;
    const number = value - 1;

    if (number * price > MAX_PRICE_VALUE) {
      return onChange(value);
    }

    if (number <= min) {
      return onChange(min);
    }

    onChange(number);
  };

  render() {
    const { value, color, price, language } = this.props;

    return (
      <div>
        <div className={classes.wrapper}>
          <div className={classes.arrows}>
            <span className={classes.up} onClick={this.handleAddClick}>
              <img src={upIcon} alt="Add" />
            </span>
            <span className={classes.down} onClick={this.handleMinusClick}>
              <img src={downIcon} alt="Minus" />
            </span>
          </div>
          <span className={classes.number}>{value}</span>
          <h3 className={classes.price}>
            {formatPrice(value * price, language)}
          </h3>
        </div>

        <div className={classes.quantity}>
          <div>
            <Button
              type="primary"
              style={{ backgroundColor: color, borderColor: color }}
              shape="circle"
              icon={<MinusCircleOutlined />}
              onClick={this.handleMinusClick}
              size="large"
            />
          </div>
          <div className={classes.number}>{value}</div>
          <div>
            <Button
              type="primary"
              style={{ backgroundColor: color, borderColor: color }}
              shape="circle"
              icon={<PlusCircleOutlined />}
              onClick={this.handleAddClick}
              size="large"
            />
          </div>
          <div>
            <h3 className={classes.price}>
              {formatPrice(value * price, language)}
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

export default Quantity;
