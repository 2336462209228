import Base from './base';

export default class Campaign extends Base {
  get({ page, userId }) {
    return this.apiClient.get('campaigns', { page, userId });
  }
  getShort({ userId }) {
    return this.apiClient.get('campaigns/short', { userId });
  }
  getById(id) {
    return this.apiClient.get(`campaigns/${id}`);
  }
  delete(id) {
    return this.apiClient.delete(`campaigns/${id}`);
  }
  uploadFile(data) {
    return this.apiClient.post('campaigns/upload', data);
  }
  create(data) {
    return this.apiClient.post('campaigns', data);
  }
  update(data) {
    return this.apiClient.put('campaigns', data);
  }
  deleteFile(data) {
    return this.apiClient.put('campaigns/delete-file', data);
  }
  status(id, status) {
    return this.apiClient.put('campaigns/status', { id, status });
  }
  checkSlug({ campaignId, slug }) {
    return this.apiClient.get('campaigns/check-slug', { slug, campaignId });
  }
  updateViews({ campaignId }) {
    return this.apiClient.post('campaigns/add-view', { campaignId });
  }
}
