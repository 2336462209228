import { CHAT_REQUIRED, ACCEPT_COOKIE } from '../common/actions';
import { SET_CAMPAIGN_PREVIEW } from '../campaign/actions';
import { SET_REFERRAL } from '../profile/actions';
import {
  setPreview,
  setCookieTerms,
  setReferralCode,
} from '../../localStorage';

export default function () {
  return () => next => action => {
    switch (action.type) {
      case CHAT_REQUIRED: {
        if (window.jivo_api) {
          window.jivo_api.open();
        }

        break;
      }

      case ACCEPT_COOKIE: {
        setCookieTerms(true);
        break;
      }

      case SET_CAMPAIGN_PREVIEW: {
        setPreview(action.payload.preview);
        break;
      }

      case SET_REFERRAL: {
        setReferralCode(action.payload.code);
        break;
      }

      default:
    }

    next(action);
  };
}
