import React from 'react';
import styles from './CertificateCheckoutDetails.module.scss';

const CertificateCheckoutDetails = props => {
  return (
    <div className={styles.CertificateCheckoutDetails}>
      {React.cloneElement(props.children, {
        cardClassName: styles.tier,
        wrapperClassName: styles.container,
        infoClassName: styles.info,
        descriptionClassName: styles.description,
      })}
    </div>
  );
};

export default CertificateCheckoutDetails;
