import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ELEMENTS_IDS } from '../constants/labels';
import { scrollToElementId } from '../helpers/common';

class ScrollTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const buyForm = document.getElementById(ELEMENTS_IDS.BUY_FORM);
      const paymentForm = document.getElementById(ELEMENTS_IDS.PAYMENT_FORM);

      switch (true) {
        case Boolean(buyForm): {
          return scrollToElementId(ELEMENTS_IDS.BUY_FORM, 350);
        }

        case Boolean(paymentForm): {
          return scrollToElementId(ELEMENTS_IDS.PAYMENT_FORM, 100);
        }

        default: {
          window.scrollTo(0, 0);
        }
      }
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default withRouter(ScrollTop);
