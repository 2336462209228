import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import SimpleReactLightbox from 'simple-react-lightbox';

import 'antd/dist/antd.css';
import 'rc-color-picker/assets/index.css';

import api from './api';
import configureStore, { history } from './redux/store';

import Routes from './routes';
import ScrollTop from './components/ScrollTop';

const store = configureStore(api, history);

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ScrollTop>
            <SimpleReactLightbox>
              <Routes />
            </SimpleReactLightbox>
          </ScrollTop>
        </ConnectedRouter>
      </Provider>
    );
  }
}
