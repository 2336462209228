import React from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import * as classes from './CertificateTiers.module.scss';

const Checkbox = ({ input, label, disabled }) => {
  return (
    <AntCheckbox
      className={classes.field}
      checked={Boolean(input.value)}
      disabled={disabled}
      onChange={input.onChange}
      name={input.name}
      id={input.name}
    >
      {label}
    </AntCheckbox>
  );
};

export default Checkbox;
