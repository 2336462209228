import { message } from 'antd';
import Color from 'color';
import { ROUTES } from './constants/routes';
import { mobileRegex, youtubeVideoRegExp } from './redux/validators';

export function compose(...funcs) {
  if (funcs.length === 1) {
    return funcs[0];
  }

  return funcs.reduce((a, b) => (...args) => a(b(...args)));
}

export function copyToClipboard(text) {
  navigator.clipboard.writeText(text);
  message.success('Скопійовано');
}

export function getFullUrl(route) {
  return `${window.location.origin}${route}`;
}

export function getFontColor(color) {
  const isDarkBackground = Color(color).darken(0.3).isDark();
  const fontColor = isDarkBackground ? '#FFF' : '#000';

  return {
    fontColor,
    iconColor: `rgba(${Color(fontColor).rgb().array().join()}, 0.5)`,
    isFontWhite: isDarkBackground,
  };
}

export function getCampaignUrl(id) {
  return `${window.location.origin}/campaign/${id}`;
}

export function generateCsv(list, fileName) {
  const text = list.map(rows => rows.join(',')).join('\n');
  const blob = new Blob(['', text], { type: 'text/csv' });

  return downloadFileInBrowser(blob, fileName);
}

export function downloadFileInBrowser(blob, name) {
  if (!global.document) {
    return;
  }

  const a = global.document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.style.display = 'none';
  const body = document.querySelector('body');

  if (body) {
    body.appendChild(a);
  }

  a.click();

  if (body) {
    body.removeChild(a);
  }

  window.URL.revokeObjectURL(url);
}
export function isMobileOrTabletUserAgent() {
  const toCheck = navigator.userAgent || navigator.vendor || window.opera;

  return mobileRegex.test(toCheck);
}
export function getCoverParams(imageUrl, videoUrl, ID_POSITION, ID_LENGTH) {
  if (!imageUrl && !videoUrl) {
    return { url: null, isVideo: false };
  }

  const match = (videoUrl || '').match(youtubeVideoRegExp);
  const isCorrectVideo = match && match[ID_POSITION].length === ID_LENGTH;
  const isMobileOrTable = isMobileOrTabletUserAgent();

  if (isMobileOrTable || !isCorrectVideo) {
    return {
      url: imageUrl,
      isVideo: false,
    };
  }

  if (isCorrectVideo) {
    return {
      url: match[ID_POSITION],
      isVideo: true,
    };
  }

  return { url: null, isVideo: false };
}
export function getRoute(props) {
  const { isDisabled = false, campaignData, item: { id } = {} } = props;

  if (isDisabled) {
    return;
  }

  return ROUTES.BUY_CERTIFICATE.replace(':id', campaignData.identifier).replace(
    ':certificateId',
    id,
  );
}
