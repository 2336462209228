export const prefix = 'landing';

export const LANDING_DATA_REQUEST = `${prefix}/LANDING_DATA_REQUEST`;
export const LANDING_DATA_SUCCESS = `${prefix}/LANDING_DATA_SUCCESS`;
export const LANDING_DATA_FAILURE = `${prefix}/LANDING_DATA_FAILURE`;

export const getLandingData = id => ({
  types: [LANDING_DATA_REQUEST, LANDING_DATA_SUCCESS, LANDING_DATA_FAILURE],
  promise: api => api.landing.getData(id),
});

export const getPreviewData = id => ({
  types: [LANDING_DATA_REQUEST, LANDING_DATA_SUCCESS, LANDING_DATA_FAILURE],
  promise: api => api.campaign.getById(id),
});

export const PAYMENT_DATA_REQUEST = `${prefix}/PAYMENT_DATA_REQUEST`;
export const PAYMENT_DATA_SUCCESS = `${prefix}/PAYMENT_DATA_SUCCESS`;
export const PAYMENT_DATA_FAILURE = `${prefix}/PAYMENT_DATA_FAILURE`;
export const getPaymentData = data => ({
  types: [PAYMENT_DATA_REQUEST, PAYMENT_DATA_SUCCESS, PAYMENT_DATA_FAILURE],
  promise: api => api.landing.getPaymentData(data),
});

export const SUCCESS_PAYMENT_DATA_REQUEST = `${prefix}/SUCCESS_PAYMENT_DATA_REQUEST`;
export const SUCCESS_PAYMENT_DATA_SUCCESS = `${prefix}/SUCCESS_PAYMENT_DATA_SUCCESS`;
export const SUCCESS_PAYMENT_DATA_FAILURE = `${prefix}/SUCCESS_PAYMENT_DATA_FAILURE`;
export const successPaymentData = data => ({
  types: [
    SUCCESS_PAYMENT_DATA_REQUEST,
    SUCCESS_PAYMENT_DATA_SUCCESS,
    SUCCESS_PAYMENT_DATA_FAILURE,
  ],
  promise: api => api.landing.successPaymentData(data),
});

export const SHOW_SUCCESS_PAYMENT_SCREEN = `${prefix}/SHOW_SUCCESS_PAYMENT_SCREEN`;
export const showSuccessPaymentScreen = payload => ({
  type: SHOW_SUCCESS_PAYMENT_SCREEN,
  payload,
});

export const CONTACT_FORM_SUBMIT_REQUEST = `${prefix}/CONTACT_FORM_SUBMIT_REQUEST`;
export const CONTACT_FORM_SUBMIT_SUCCESS = `${prefix}/CONTACT_FORM_SUBMIT_SUCCESS`;
export const CONTACT_FORM_SUBMIT_FAILURE = `${prefix}/CONTACT_FORM_SUBMIT_FAILURE`;
export const submitContactForm = data => ({
  types: [
    CONTACT_FORM_SUBMIT_REQUEST,
    CONTACT_FORM_SUBMIT_SUCCESS,
    CONTACT_FORM_SUBMIT_FAILURE,
  ],
  promise: api => api.landing.submitContactForm(data),
});

export const SAVE_PAYMENT_DATA_REQUEST = `${prefix}/SAVE_PAYMENT_DATA_REQUEST`;
export const SAVE_PAYMENT_DATA_SUCCESS = `${prefix}/SAVE_PAYMENT_DATA_SUCCESS`;
export const SAVE_PAYMENT_DATA_FAILURE = `${prefix}/SAVE_PAYMENT_DATA_FAILURE`;
export const paymentDataSave = data => ({
  types: [
    SAVE_PAYMENT_DATA_REQUEST,
    SAVE_PAYMENT_DATA_SUCCESS,
    SAVE_PAYMENT_DATA_FAILURE,
  ],
  promise: api => api.landing.paymentDataSave(data),
});
