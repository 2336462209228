import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  certificateData,
  campaignData,
  paymentIntentionData,
} from '../../redux/landing/selectors';
import {
  successPaymentData,
  paymentDataSave,
  showSuccessPaymentScreen,
  getLandingData,
} from '../../redux/landing/actions';

import PaymentCertificatePage from './PaymentCertificatePage';

const selector = createStructuredSelector({
  campaignData,
  certificateData,
  paymentIntentionData,
});

const actions = {
  showSuccessPaymentScreen,
  successPaymentData,
  paymentDataSave,
  getLandingData,
};

export { PaymentCertificatePage };

export default connect(selector, actions)(PaymentCertificatePage);
