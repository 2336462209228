import React, { Component } from 'react';
import { Form, Button, Input } from 'antd';

import styles from './ResetPasswordForm.module.scss';

const validationRules = {
  password: [
    {
      required: true,
      min: 8,
      message: 'Будь ласка введіть пароль мінімум 8 символів',
    },
  ],
  confirmPassword: [
    {
      required: true,
      message: 'Будь ласка підтвердіть пароль',
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject('Паролі не співпадають');
      },
    }),
  ],
};

export default class ResetPasswordForm extends Component {
  onFinish = values => {
    const { onSubmit } = this.props;
    onSubmit(values);
  };

  onFinishFailed = () => {};

  render() {
    return (
      <Form
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        className={styles.form}
        size="large"
      >
        <Form.Item
          name="password"
          type="password"
          label="Введіть новий пароль"
          placeholder="new password"
          className={styles.input}
          rules={validationRules.password}
          validateTrigger="onBlur"
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          type="password"
          label="Підтвердіть новий пароль"
          placeholder="confirm password"
          className={styles.input}
          rules={validationRules.confirmPassword}
          validateTrigger="onBlur"
        >
          <Input.Password />
        </Form.Item>
        <Form.Item className={styles.button}>
          <Button htmlType="submit" type="primary">
            Встановити новий пароль
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
