import { put, takeEvery, select } from 'redux-saga/effects';
import { generateCsv } from '../../utils';
import { userId as getUserId } from '../profile/selectors';
import {
  DASHBOARD_CREATE_CERTIFICATES_SUCCESS,
  DEACTIVATE_CERTIFICATE_SUCCESS,
  getCertificates,
} from './actions';
import { search as getSearch, page as getPage } from './selectors';

export function* exportClients() {
  yield takeEvery(DEACTIVATE_CERTIFICATE_SUCCESS, function* (action) {
    const { data } = action;
    const fileName = `clients.csv`;

    yield generateCsv(data.list, fileName);
  });
}

export function* createCertificate() {
  yield takeEvery(DASHBOARD_CREATE_CERTIFICATES_SUCCESS, function* () {
    const search = yield select(getSearch);
    const page = yield select(getPage);
    const userId = yield select(getUserId);

    yield put(getCertificates(userId, page, search));
  });
}
