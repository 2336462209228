import React, { Component } from 'react';
import { Form, Button, Row, Input } from 'antd';
import { Link } from 'react-router-dom';

import styles from './LoginForm.module.scss';
import { ROUTES } from '../../constants/routes';

export default class LoginForm extends Component {
  onFinish = values => {
    const { onSubmit } = this.props;
    onSubmit(values);
  };

  onFinishFailed = () => {};

  render() {
    return (
      <Form
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        className={styles.form}
      >
        <h1 className={styles.title}>Вхід</h1>
        <Form.Item
          name="email"
          type="text"
          label="Електронна пошта"
          placeholder="email"
          className={styles.input}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          type="password"
          label="Пароль"
          placeholder="password"
          className={styles.input}
        >
          <Input.Password />
        </Form.Item>
        <Row className="auth-controls">
          <Link to={ROUTES.PASSWORD_RECOVERY}>Забули пароль?</Link>
        </Row>
        <Form.Item className={styles.button}>
          <Button htmlType="submit" type="primary">
            Увійти
          </Button>
          <Button>
            <Link to={ROUTES.SIGNUP}>Зареєструватися</Link>
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
