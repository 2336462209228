import React from 'react';
import moment from 'moment';
import { touch } from 'redux-form';
import locale from 'antd/es/date-picker/locale/uk_UA';
import { DatePicker as Picker, Form } from 'antd';
import * as classes from './DatePicker.module.scss';
import { CREATE_CERTIFICATE_TIERS } from '../../../redux/forms/names';
import 'moment/locale/uk';

const DatePicker = ({ input, placeholder, meta, className = '' }) => {
  const handleChange = (data, value) => {
    const { onChange, name } = input;
    const { dispatch } = meta;

    onChange(value);
    dispatch(touch(CREATE_CERTIFICATE_TIERS, name));
  };

  const isError = meta.touched && meta.error;

  return (
    <Form.Item
      hasFeedback
      validateStatus={isError ? 'error' : null}
      help={isError ? meta.error : null}
      className={classes.wrapper}
    >
      <Picker
        locale={locale}
        style={{
          minWidth: 160,
        }}
        className={className}
        placeholder={placeholder}
        value={input.value ? moment(input.value) : null}
        onChange={handleChange}
      />
    </Form.Item>
  );
};

export default DatePicker;
