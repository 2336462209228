import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import PasswordRecoveryForm from '../../components/PasswordRecoveryForm';
import classes from '../BillingSettings/BillingSettings.module.scss';

export default class PasswordRecoveryPage extends Component {
  static propTypes = {
    isRecoveryRequested: PropTypes.bool.isRequired,
  };

  render() {
    if (this.props.isRecoveryRequested) {
      return (
        <div className={classnames(classes.column, classes.minusIndent)}>
          <FontAwesomeIcon className={classes.icon} icon={faCheckCircle} />
          <h3 className={classes.title}>Відновлення паролю</h3>
          <p className={classes.text}>
            Якщо користувач з такою електронною поштою існує, <br />
            ви отримаєте листа з інструкціями для відновлення доступу.
          </p>
        </div>
      );
    }

    return <PasswordRecoveryForm />;
  }
}
