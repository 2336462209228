import {
  change,
  reduxForm,
  untouch,
  registerField,
  arrayRemove,
} from 'redux-form';
import { connect } from 'react-redux';
import { CREATE_CERTIFICATE_TIERS } from '../../../../redux/forms/names';
import AddImageModal from './AddImageModal';
import { certificateTiersValues } from '../../../../redux/campaign/selectors';
import { createStructuredSelector } from 'reselect';
import { uploadFile } from '../../../../redux/campaign/actions';

const form = reduxForm({
  form: CREATE_CERTIFICATE_TIERS,
})(AddImageModal);

const actions = {
  changeField: change,
  untouchField: untouch,
  registerField,
  uploadFile,
  arrayRemove,
};
const selectors = createStructuredSelector({ certificateTiersValues });

export default connect(selectors, actions)(form);
