import React from 'react';
import classes from './PageSettings.module.scss';

const Toggle = ({ input, label }) => {
  return (
    <div className={classes.toggle}>
      {label}
      <label className={classes.switch}>
        <input
          type="checkbox"
          onChange={input.onChange}
          checked={input.value}
        />
        <span className={classes.slider} />
      </label>
    </div>
  );
};

export default Toggle;
