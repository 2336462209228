import React, { Component } from 'react';
import classnames from 'classnames';
import { Table as AntTable, Pagination, Tooltip, Button, Empty } from 'antd';
import {
  DeleteOutlined,
  ReloadOutlined,
  EditOutlined,
} from '@ant-design/icons';

import * as classes from './UsersTable.module.scss';
import UserModal from '../UserModal';

class UsersTable extends Component {
  componentDidMount() {
    const { userId, getList, getCampaigns } = this.props;

    if (userId) {
      getList(userId, 1);
      getCampaigns(userId, 0);
    }
  }

  handlePageClick = page => {
    const { userId, changePage, getList } = this.props;

    changePage(page);
    getList(userId, page);
  };

  handleStatusChange = permission => () => {
    const { updateStatus } = this.props;

    updateStatus(permission._id, permission.isActive);
  };

  handleEditClick = user => () => {
    const { handleModalClick } = this.props;

    handleModalClick(user);
  };

  render() {
    const {
      users,
      page,
      total,
      handleModalClick,
      selectedUser,
      isModalOpen,
    } = this.props;

    return (
      <div className={classes.container}>
        <AntTable
          rowKey="uid"
          className={classnames({
            [classes.withData]: Boolean(users.length),
          })}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Поки немає данних"
              />
            ),
          }}
          columns={[
            {
              title: 'Прізвище',
              dataIndex: 'lastName',
              key: 'lastName',
            },
            {
              title: "Ім'я",
              dataIndex: 'firstName',
              key: 'firstName',
            },
            {
              title: 'Електронна пошта',
              dataIndex: 'email',
              key: 'email',
            },
            {
              title: 'Компанія',
              dataIndex: 'company',
              key: 'company',
            },
            {
              title: 'Роль',
              dataIndex: 'formattedPermission',
              key: 'formattedPermission',
            },
            {
              title: 'Статус',
              dataIndex: 'status',
              key: 'status',
            },
            {
              title: 'Дата додавання',
              dataIndex: 'createdAt',
              key: 'createdAt',
            },
            {
              title: null,
              key: 'action',
              render: (text, record) => (
                <div className={classes.buttons}>
                  <Tooltip
                    placement="topRight"
                    title={`Редагувати профіль ${record.firstName}`}
                  >
                    <Button
                      className={classes.secondary}
                      onClick={this.handleEditClick(record)}
                    >
                      <EditOutlined />
                    </Button>
                  </Tooltip>
                  {record.isActive ? (
                    <Tooltip
                      placement="topRight"
                      title={`Деактивувати ${record.firstName}`}
                    >
                      <Button
                        className={classes.remove}
                        onClick={this.handleStatusChange(record)}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      placement="topRight"
                      title={`Відновити доступ для ${record.firstName}`}
                    >
                      <Button
                        className={classes.secondary}
                        onClick={this.handleStatusChange(record)}
                      >
                        <ReloadOutlined />
                      </Button>
                    </Tooltip>
                  )}
                </div>
              ),
            },
          ]}
          size="small"
          dataSource={users}
          pagination={false}
        />
        <UserModal
          visible={isModalOpen}
          handleCancel={handleModalClick}
          initialValues={selectedUser}
        />
        <Pagination
          className={classes.pagination}
          onChange={this.handlePageClick}
          pageSize={10}
          defaultCurrent={1}
          total={total}
          current={page}
        />
      </div>
    );
  }
}

export default UsersTable;
