import config from '../config';
import ApiClient from './client';

import Auth from './auth';
import Users from './users';
import Landing from './landing';
import Campaign from './campaign';
import Certificates from './certificate';
import Administration from './administration';

const apiClient = new ApiClient(config.apiPrefix);

export default {
  auth: new Auth(apiClient),
  users: new Users(apiClient),
  landing: new Landing(apiClient),
  campaign: new Campaign(apiClient),
  certificate: new Certificates(apiClient),
  administration: new Administration(apiClient),
  client: apiClient,
};
