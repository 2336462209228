import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {
  formatDateWithDots,
  priceWithDecimal,
} from '../../../helpers/stringFormatters';
import { getFontColor, getRoute } from '../../../utils';
import { USER_LANDING } from '../../../constants/texts';
import { EXPIRE_VALUES } from '../../../constants/labels';
import DiscountImage from '../DiscountImage';
import DefaultCardImage from '../DefaultCardImage';
import styles from './CertificateCard.module.scss';

class CertificateCard extends React.Component {
  state = { hovered: false };

  handleHover = () => {
    this.setState({ hovered: !this.state.hovered });
  };

  getPriceLabel = price => {
    return `${priceWithDecimal(price)} ₴`;
  };

  formatOrder(num) {
    return num >= 10 ? num : `0${num}`;
  }

  getBoxShadow = color => {
    return `-1px -1px 0 ${color}, 1px -1px 0 ${color}, -1px 1px 0 ${color}, 1px 1px 0 ${color}`;
  };

  getOldPrice = () => {
    const {
      item: { price, value },
    } = this.props;

    if (Number(price) < Number(value)) {
      return (
        <span className={styles.discount}>{this.getPriceLabel(value)}</span>
      );
    }

    return null;
  };

  getImage = () => {
    const {
      item: { images },
      primaryColor,
    } = this.props;

    if (!images || images.length < 1) {
      return <DefaultCardImage primaryColor={primaryColor} />;
    }

    return <img src={images[0]} alt="Certificate" />;
  };

  getValidPeriod() {
    const {
      item: { createdAt, expireDate, commencementDate },

      language,
    } = this.props;

    if (expireDate === EXPIRE_VALUES.INFINITY) {
      const dateToUse = commencementDate || createdAt;

      return USER_LANDING.INFINITY_EXPIRATION[language].replace(
        'START_DATE',
        formatDateWithDots(dateToUse),
      );
    }

    const monthsNumber = Number(expireDate.replace(/^\D+/g, ''));
    const dateToUse = commencementDate || createdAt;
    const expirationDate = new Date(dateToUse);

    expirationDate.setMonth(expirationDate.getMonth() + monthsNumber);

    return USER_LANDING.RANGE_EXPIRATION[language]
      .replace('START_DATE', formatDateWithDots(commencementDate))
      .replace('MONTHS', formatDateWithDots(expirationDate));
  }

  getDiscountImage = () => {
    const {
      item: { price, value },
      primaryColor,
      language,
    } = this.props;

    if (Number(price) >= Number(value)) {
      return null;
    }

    const priceDiscount = Math.floor(100 - (Number(price) * 100) / value);
    const { isFontWhite } = getFontColor(primaryColor);

    return (
      <div className={styles.discountWrapper}>
        <span
          className={classnames(styles.discountPrice, {
            [styles.discountWhite]: isFontWhite,
          })}
        >{`${USER_LANDING.DISCOUNT_NAME[language]} ${priceDiscount}%`}</span>
        <div className={styles.discountImg}>
          <DiscountImage color={primaryColor} />
        </div>
      </div>
    );
  };

  render() {
    const {
      item: { price, description, name } = {},
      cardClassName = '',
      wrapperClassName = '',
      primaryColor,
      isPreview = false,
      language,
      orderNum,
      backgroundColor,
      height,
    } = this.props;
    const { hovered } = this.state;
    const primaryColorFont = getFontColor(primaryColor);
    const backgroundColorFont = getFontColor(backgroundColor);

    return (
      <div
        className={classnames(styles.wrapper, wrapperClassName, {
          [styles.whiteFont]: backgroundColorFont.isFontWhite,
        })}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleHover}
      >
        <Link
          to={getRoute(this.props)}
          className={classnames(styles.card, cardClassName, {
            [styles.preview]: isPreview,
          })}
        >
          <p
            className={styles.orderNum}
            style={{
              color: hovered ? backgroundColor : primaryColor,
              textShadow: this.getBoxShadow(primaryColor),
            }}
          >
            {this.formatOrder(orderNum + 1)}
          </p>
          <div style={{ minHeight: height.name }} className={styles.cardHeader}>
            <span>{name}</span>
            <div>
              <span className={styles.cardPrice}>
                {this.getOldPrice()}
                <span>{this.getPriceLabel(price)}</span>
              </span>
            </div>
          </div>
          <div className={styles.imgWrapper}>
            <div
              style={{ backgroundColor: primaryColor, opacity: 0.8 }}
              className={classnames(styles.invisible, {
                [styles.imageHover]: hovered,
              })}
            />
            <div
              className={classnames(styles.invisible, {
                [styles.plus]: hovered,
                [styles.whiteFontButton]: primaryColorFont.isFontWhite,
              })}
            >
              +
            </div>
            {this.getDiscountImage()}
            <div className={styles.cardImage}>{this.getImage()}</div>
          </div>

          <span className={styles.validPeriod}>{this.getValidPeriod()}</span>
          <p
            className={styles.description}
            style={{ minHeight: height.description }}
          >
            {description}
          </p>
          <span
            className={classnames(styles.orderButton, {
              [styles.whiteFontButton]: backgroundColorFont.isFontWhite,
            })}
            style={{
              borderColor: backgroundColorFont.fontColor,
              backgroundColor: hovered && primaryColor,
              color: hovered && primaryColorFont.fontColor,
            }}
          >
            {USER_LANDING.CERTIFICATE_BUTTON[language]}
          </span>
        </Link>
      </div>
    );
  }
}

export default CertificateCard;
