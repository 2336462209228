import React, { Component } from 'react';
import classnames from 'classnames';
import ContactForm from '../../components/ContactForm';
import CertificateTiers from '../../components/Landing/CertificateTiers';
import InfoBlock from '../../components/Landing/InfoBlock';
import { USER_LANDING } from '../../constants/texts';
import styles from './UserLandingPage.module.scss';
import { getFontColor } from '../../utils';
import HolidayGarland from '../../components/HolidayGarland';
import garlandImage from '../../assets/images/branchOptimised.png';
import garlandImage2 from '../../assets/images/garlandOptimised.png';
import CampaignViewTracker from '../../components/CampaignViewTracker';

export default class UserLandingPage extends Component {
  render() {
    const {
      certificateTiers,
      campaignData: {
        description,
        heading,
        primaryColor,
        brandLogo,
        language,
        fontColor,
        identifier,
        backgroundColor,
        additionalText,
        translation,
        id,
      },
      isPreview,
    } = this.props;

    const { isFontWhite } = getFontColor(backgroundColor);

    return (
      <div className={styles.landingPage} style={{ backgroundColor }}>
        <CampaignViewTracker campaignId={id} />
        <InfoBlock
          campaignId={identifier}
          logo={brandLogo}
          heading={heading}
          description={description}
          backgroundColor={backgroundColor}
          isIconAdded={false}
          translation={translation}
        />
        <section className={styles.certificatesBlock}>
          <HolidayGarland src={garlandImage} width={20} top="-3vw" />
          <h2
            className={classnames(styles.title, {
              [styles.white]: isFontWhite,
            })}
          >
            {USER_LANDING.CHOOSE_CERTIFICATE[language]}
          </h2>
          <CertificateTiers
            logo={brandLogo}
            isPreview={isPreview}
            tiers={certificateTiers}
            primaryColor={primaryColor}
            buttonFontColor={fontColor}
            language={language}
            backgroundColor={backgroundColor}
          />
        </section>
        <section
          className={classnames(styles.contactBlock, {
            [styles.withAdditionalText]: !!additionalText,
          })}
        >
          <HolidayGarland src={garlandImage2} width={106} top="-3vw" />
          <h4 className={styles.title}>
            {USER_LANDING.CONTACT_FORM.HEADER[language]}
          </h4>
          <p>{USER_LANDING.CONTACT_FORM.DESCRIPTION[language]}</p>
          <ContactForm
            fontColor={fontColor}
            language={language}
            color={primaryColor}
          />
        </section>
        {!!additionalText && (
          <section className={styles.additionalBlock}>
            <p
              className={classnames(styles.additionalText, {
                [styles.white]: isFontWhite,
              })}
            >
              {additionalText}
            </p>
          </section>
        )}
      </div>
    );
  }
}
