import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Dropdown } from 'antd';
import cn from 'classnames';
import {
  UserOutlined,
  LogoutOutlined,
  DollarCircleOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { ROUTES } from '../../constants/routes';
import { LINK_LABELS } from '../../constants/labels';
import classnames from './MainHeader.module.scss';

const { Header } = Layout;

export default class MainHeader extends Component {
  renderFreeMenu = () => [
    <Menu.Item key={ROUTES.LOGIN}>
      <Link to={ROUTES.LOGIN}>Вхід</Link>
    </Menu.Item>,
    <Menu.Item key={ROUTES.SIGNUP}>
      <Link to={ROUTES.SIGNUP}>Реєстрація</Link>
    </Menu.Item>,
  ];

  renderLoggedMenu = () => {
    const { isAdmin } = this.props;

    const links = [
      <Menu.Item key={ROUTES.CAMPAIGNS}>
        <Link to={ROUTES.CAMPAIGNS}>{LINK_LABELS.CAMPAIGNS}</Link>
      </Menu.Item>,
      <Menu.Item key={ROUTES.CERTIFICATES}>
        <Link to={ROUTES.CERTIFICATES}>{LINK_LABELS.CERTIFICATES}</Link>
      </Menu.Item>,
      <Menu.Item key={ROUTES.HELP}>
        <Link to={ROUTES.HELP}>{LINK_LABELS.HELP}</Link>
      </Menu.Item>,
    ];

    if (isAdmin) {
      links.push(
        <Menu.Item key={ROUTES.DASHBOARD}>
          <Link to={ROUTES.ADMIN}>{LINK_LABELS.DASHBOARD}</Link>
        </Menu.Item>,
      );
    }

    return links;
  };

  renderProfiledMenu = () => (
    <Menu>
      <Menu.Item key={ROUTES.PROFILE}>
        <Link to={ROUTES.PROFILE}>
          <SettingOutlined className={classnames.menuIcon} />
          {LINK_LABELS.PROFILE_SETTINGS}
        </Link>
      </Menu.Item>
      <Menu.Item key={ROUTES.BILLING_SETTINGS}>
        <Link to={ROUTES.BILLING_SETTINGS}>
          <DollarCircleOutlined className={classnames.menuIcon} />
          {LINK_LABELS.BILLING_SETTINGS}
        </Link>
      </Menu.Item>
      <Menu.Item key={ROUTES.USER_ADMINISTRATION_SETTINGS}>
        <Link to={ROUTES.USER_ADMINISTRATION_SETTINGS}>
          <UsergroupAddOutlined className={classnames.menuIcon} />
          {LINK_LABELS.USER_ADMINISTRATION_SETTINGS}
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={ROUTES.CERTIFICATES} onClick={this.props.logout}>
        <LogoutOutlined className={classnames.menuIcon} />
        Вихід
      </Menu.Item>
    </Menu>
  );

  render() {
    const { pathname, user, isHidden } = this.props;

    if (isHidden) {
      return null;
    }

    return (
      <Header className={classnames.header}>
        <Link to={ROUTES.HOME}>
          <div className={classnames.logo} />
        </Link>
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[pathname]}
          className={cn(classnames.menu, { [classnames.right]: !user })}
        >
          {user ? this.renderLoggedMenu(user.role) : this.renderFreeMenu()}
        </Menu>
        {user && (
          <Dropdown overlay={this.renderProfiledMenu(user.role)}>
            <UserOutlined className={classnames.icon} />
          </Dropdown>
        )}
      </Header>
    );
  }
}
