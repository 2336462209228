import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getLandingData } from '../../redux/landing/actions';
import { certificateTiers, campaignData } from '../../redux/landing/selectors';

import UserLandingPage from './UserLandingPage';
import { isPreviewPage } from '../../redux/router/selectors';

const selector = createStructuredSelector({
  certificateTiers,
  campaignData,
  isPreview: isPreviewPage,
});

const actions = { getLandingData };

export { UserLandingPage };

export default connect(selector, actions)(UserLandingPage);
