import React, { Component } from 'react';
import { Form, FieldArray } from 'redux-form';
import * as classes from './CertificateTiers.module.scss';
import { required } from '../../../redux/validators';
import { CREATE_CERTIFICATE_TIERS } from '../../../redux/forms/names';
import CertificateForm from './Form';

class CertificateTiers extends Component {
  handleClearForm = () => {
    const { changeField, untouchField } = this.props;
    const keys = [
      'expireDate',
      'commencementDate',
      'price',
      'value',
      'name',
      'description',
      'color',
      'isDateSameAsBuyDate',
      'isReusable',
      'images',
    ];

    keys.forEach(field => {
      const isColor = field === 'color';

      changeField(CREATE_CERTIFICATE_TIERS, field, isColor ? '#48B6BB' : null);
      untouchField(CREATE_CERTIFICATE_TIERS, field);
    });
  };

  render() {
    return (
      <Form onSubmit={() => ({})} className={classes.container}>
        <div className={classes.block}>
          <span className={classes.title}>Налаштування</span>
          <div className={classes.row}>
            <FieldArray
              name="certificateTiers"
              validate={[required]}
              component={CertificateForm}
              onResetForm={this.handleClearForm}
              {...this.props}
            />
          </div>
        </div>
      </Form>
    );
  }
}

export default CertificateTiers;
