import React from 'react';
import { Form, Button, Input, Modal, Select } from 'antd';

import styles from './UserModal.module.scss';

export const PERMISSIONS = {
  CAMPAIGNS_ADMIN: {
    label: 'Meнеджер кампаній',
    value: 'CAMPAIGNS_ADMIN',
  },
  CERTIFICATES_ADMIN: {
    label: 'Meнеджер сертифікатів',
    value: 'CERTIFICATES_ADMIN',
  },
};

class UserModal extends React.Component {
  state = { selectedPermission: this.props.initialValues.permission };

  componentDidUpdate(prevProps) {
    if (
      prevProps.initialValues.permission !== this.props.initialValues.permission
    ) {
      this.setState({
        selectedPermission: this.props.initialValues.permission,
      });
    }
  }

  handleFinish = values => {
    const { selectedPermission } = this.state;
    const {
      ownerId,
      initialValues,
      handleUpdate,
      handleCreate,
      handleCancel,
    } = this.props;

    if (initialValues.userId) {
      handleUpdate({
        ...values,
        permission: selectedPermission,
        _id: initialValues._id,
        userId: initialValues.userId,
      });
    } else {
      handleCreate({
        ...values,
        permission: selectedPermission,
        ownerId,
      });
    }

    handleCancel();
  };

  selectPermission = value => {
    this.setState({ selectedPermission: value });
  };

  handleFinishFailed = () => {};

  render() {
    const {
      campaigns,
      loading,
      visible,
      handleCancel,
      initialValues,
    } = this.props;

    return (
      <Modal
        visible={visible}
        title={initialValues._id ? 'Редагувати права' : 'Додати користувача'}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <Form
          onFinish={this.handleFinish}
          onFinishFailed={this.handleFinishFailed}
          initialValues={initialValues}
          size="large"
        >
          {!initialValues._id && (
            <>
              <Form.Item
                type="text"
                name="firstName"
                label="Ім'я"
                className={styles.input}
                rules={[{ required: true, message: "Введіть ім'я" }]}
              >
                <Input placeholder="Введіть ім'я" />
              </Form.Item>
              <Form.Item
                type="text"
                name="lastName"
                label="Прізвище"
                className={styles.input}
                rules={[{ required: true, message: 'Введіть прізвище' }]}
              >
                <Input placeholder="Введіть прізвище" />
              </Form.Item>
              <Form.Item
                name="email"
                type="text"
                label="Електронна адреса"
                className={styles.input}
                rules={[
                  { required: true, message: 'Введіть електронну адресу' },
                  { type: 'email', message: 'Хибна електронна адреса' },
                ]}
              >
                <Input placeholder="Введіть електронну адресу" />
              </Form.Item>
              <Form.Item
                name="company"
                type="text"
                label="Компанія"
                className={styles.input}
                rules={[{ required: true, message: 'Введіть компанію' }]}
              >
                <Input placeholder="Введіть компанію" />
              </Form.Item>
              <Form.Item
                name="phone"
                type="text"
                label="Телефон"
                className={styles.input}
                rules={[{ required: true, message: 'Введіть телефон' }]}
              >
                <Input placeholder="Введіть телефон 099..." />
              </Form.Item>
            </>
          )}
          <Form.Item
            name="permission"
            type="text"
            label="Роль"
            className={styles.input}
            rules={[{ required: true, message: 'Виберіть роль' }]}
          >
            <Select
              placeholder="Виберіть роль"
              onSelect={this.selectPermission}
            >
              {Object.keys(PERMISSIONS).map(value => (
                <Select.Option key={value} value={value}>
                  {PERMISSIONS[value].label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="campaignIds"
            type="text"
            label="Кампанії"
            className={styles.input}
            rules={[{ required: true, message: 'Виберіть кампанію' }]}
          >
            <Select
              placeholder="Якими кампаніями можна керувати?"
              allowClear
              mode="multiple"
            >
              {campaigns.map(({ value, label }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className={styles.button}>
            <Button disabled={loading} htmlType="submit" type="primary">
              {initialValues.userId ? 'Зберегти зміни' : 'Створити користувача'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default UserModal;
