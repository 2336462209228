import React, { Component } from 'react';
// import styles from './AdminReferral.module.scss';

class AdminReferralPage extends Component {
  render() {
    return <div>Admin referral links page</div>;
  }
}

export default AdminReferralPage;
