import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  campaignData,
  certificateData,
  paidCertificate,
  certificatesCount,
} from '../../redux/landing/selectors';

import SuccessCertificatePage from './SuccessCertificatePage';

const selector = createStructuredSelector({
  campaignData,
  certificateData,
  paidCertificate,
  certificatesCount,
});

const actions = {};

export { SuccessCertificatePage };

export default connect(selector, actions)(SuccessCertificatePage);
