import React, { Component } from 'react';
import { Spin } from 'antd';
import { Route, Switch } from 'react-router-dom';
import UserLandingPage from '../../pages/UserLandingPage';
import BuyCertificatePage from '../../pages/BuyCerticatacePage';
import PaymentCertificatePage from '../../pages/PaymentCertificatePage';
import SuccessCertificatePage from '../../pages/SuccessCertificatePage';
import { ROUTES } from '../../constants/routes';
import BrandHeader from '../BrandHeader';
import BrandInfo from '../BrandInfo';
import NewBrandInfo from '../NewLanding/NewBrandInfo';
import NotFoundPage from '../../pages/NotFoundPage';
import BalancePage from '../../pages/BalancePage';
import NewLanding from '../../pages/NewLanding';
import NewBrandHeader from '../NewBrandHeader';
import NewBuyCertificatePage from '../../pages/NewCertificateBuyPage';
import NewPaymentCertificatePage from '../../pages/NewPaymentCertificatePage';
import NewSuccessCertificatePage from '../../pages/NewSuccessCertificatePage';
import NewBalancePage from '../../pages/NewBalancePage';
import styles from './LandingContainer.module.scss';

const spinStyle = {
  width: '100vw',
  height: '100vh',
  maxHeight: 'initial',
  zIndex: 2,
};

export default class LandingContainer extends Component {
  componentDidMount() {
    const {
      getLandingData,
      getPreviewData,
      isPreview,
      campaignId,
    } = this.props;

    if (campaignId && campaignId === 'new') {
      return;
    }

    if (isPreview) {
      return getPreviewData(campaignId);
    }

    getLandingData(campaignId);
  }

  render() {
    const {
      campaignData: {
        newDesign,
        id,
        primaryColor,
        backgroundColor,
        about,
        language,
      },
      campaignId,
      loading,
    } = this.props;

    if (!newDesign) {
      return (
        <div
          className={styles.landingContainer}
          style={{ backgroundColor: primaryColor }}
        >
          <Spin spinning={loading} size="large" style={spinStyle}>
            <div className={styles.container}>
              {!loading && <BrandHeader campaignId={campaignId} />}
              {(id || campaignId === 'new') && (
                <Switch>
                  <Route
                    path={ROUTES.USER_LANDING}
                    component={UserLandingPage}
                    exact
                  />
                  <Route
                    path={ROUTES.USER_LANDING_PREVIEW}
                    component={UserLandingPage}
                    exact
                  />
                  <Route
                    path={ROUTES.BUY_CERTIFICATE}
                    component={BuyCertificatePage}
                    exact
                  />
                  <Route
                    path={ROUTES.PAYMENT_CERTIFICATE}
                    component={PaymentCertificatePage}
                    exact
                  />
                  <Route
                    path={ROUTES.SUCCESS_CERTIFICATE}
                    component={SuccessCertificatePage}
                    exact
                  />
                  <Route
                    path={ROUTES.CHECK_BALANCE}
                    component={BalancePage}
                    exact
                  />
                </Switch>
              )}
              {(id || campaignId === 'new') && (
                <BrandInfo
                  backgroundColor={backgroundColor}
                  about={about}
                  language={language}
                />
              )}
              {!loading && !(id || campaignId === 'new') && <NotFoundPage />}
            </div>
          </Spin>
        </div>
      );
    } else {
      return (
        <div
          className={styles.newLandingContainer}
          style={{ backgroundColor: primaryColor }}
        >
          <Spin spinning={loading} size="large" style={spinStyle}>
            <div className={styles.newContainer}>
              {!loading && <NewBrandHeader campaignId={campaignId} />}
              {(id || campaignId === 'new') && (
                <Switch>
                  <Route
                    path={ROUTES.USER_LANDING}
                    component={NewLanding}
                    exact
                  />
                  <Route
                    path={ROUTES.USER_LANDING_PREVIEW}
                    component={NewLanding}
                    exact
                  />
                  <Route
                    path={ROUTES.BUY_CERTIFICATE}
                    component={NewBuyCertificatePage}
                    exact
                  />
                  <Route
                    path={ROUTES.PAYMENT_CERTIFICATE}
                    component={NewPaymentCertificatePage}
                    exact
                  />
                  <Route
                    path={ROUTES.SUCCESS_CERTIFICATE}
                    component={NewSuccessCertificatePage}
                    exact
                  />
                  <Route
                    path={ROUTES.CHECK_BALANCE}
                    component={NewBalancePage}
                    exact
                  />
                </Switch>
              )}
              {(id || campaignId === 'new') && (
                <NewBrandInfo
                  backgroundColor={backgroundColor}
                  about={about}
                  language={language}
                />
              )}
              {!loading && !(id || campaignId === 'new') && <NotFoundPage />}
            </div>
          </Spin>
        </div>
      );
    }
  }
}
