import { change, untouch, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import CertificateTiers from './CertificateTiers';
import {
  certificateTiersValues,
  campaignInitialValues,
  isCreateCertificateTierDisabled,
  certificatesFilteredByOrder,
} from '../../../redux/campaign/selectors';
import { CREATE_CERTIFICATE_TIERS } from '../../../redux/forms/names';

const form = reduxForm({
  form: CREATE_CERTIFICATE_TIERS,
})(CertificateTiers);

const initialValues = createSelector(campaignInitialValues, campaign => {
  if (campaign) {
    return {
      color: '#48B6BB',
      isReusable: true,
      certificateTiers: campaign.certificateTiers,
    };
  }
});

const actions = {
  changeField: change,
  untouchField: untouch,
};
const selector = createStructuredSelector({
  initialValues,
  createValues: certificateTiersValues,
  disabled: isCreateCertificateTierDisabled,
  filteredCertificates: certificatesFilteredByOrder,
});

export default connect(selector, actions)(form);
