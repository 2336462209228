import { USER_LANDING } from '../constants/texts';

export const isEmpty = target => {
  /* eslint-disable */
  for (const key in target) {
    /* eslint-enable */
    return false;
  }

  return true;
};

export const omit = (propsToOmit, target) => {
  return Object.keys(target).reduce((acc, key) => {
    if (!propsToOmit.includes(key)) {
      acc[key] = target[key];
    }
    return acc;
  }, {});
};

export const validationRules = language => {
  return {
    firstName: [
      {
        required: true,
        message: USER_LANDING.CONTACT_FORM.ERROR_NAME[language],
      },
    ],
    lastName: [
      {
        required: true,
        message: USER_LANDING.CONTACT_FORM.ERROR_LAST_NAME[language],
      },
    ],
    email: [
      {
        required: true,
        message: USER_LANDING.CONTACT_FORM.ERROR_EMPTY_EMAIL[language],
      },
      {
        type: 'email',
        message: USER_LANDING.CONTACT_FORM.ERROR_WRONG_EMAIL[language],
      },
    ],
    phone: [
      {
        required: true,
        message: USER_LANDING.CONTACT_FORM.ERROR_ADDITIONAL_FIELD[language],
      },
    ],
    recipientEmail: [
      {
        required: true,
        message:
          USER_LANDING.CONTACT_FORM.ERROR_EMPTY_RECIPIENT_EMAIL[language],
      },
      {
        type: 'email',
        message:
          USER_LANDING.CONTACT_FORM.ERROR_WRONG_RECIPIENT_EMAIL[language],
      },
    ],
    quantity: [
      {
        type: 'number',
        min: 1,
        message: USER_LANDING.CONTACT_FORM.ERROR_COUNT[language],
      },
    ],
    acceptTerms: [
      {
        required: true,
        type: 'boolean',
      },
      () => ({
        validator(rule, checked) {
          if (!checked)
            return Promise.reject(
              USER_LANDING.CONTACT_FORM.TERMS_ERROR[language],
            );
          return Promise.resolve();
        },
      }),
    ],
  };
};
