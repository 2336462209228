import React from 'react';
import classes from './NewBalancePage.module.scss';
import classnames from 'classnames';
import { USER_LANDING } from '../../constants/newLandingTexts';
import { priceWithDecimal } from '../../helpers/stringFormatters';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { getFontColor } from '../../utils';

export default class NewBalancePage extends React.Component {
  componentDidMount() {
    const {
      match: {
        params: { certificateId },
      },
      getBalance,
    } = this.props;

    if (certificateId) {
      getBalance(certificateId);
    }
  }

  render() {
    const {
      campaignData: {
        name,
        language,
        backgroundColor,
        primaryColor,
        identifier,
      },
      balance: { amount, code },
    } = this.props;
    const { isFontWhite } = getFontColor(backgroundColor);
    const primaryFont = getFontColor(primaryColor);

    return (
      <div
        className={classnames(classes.balancePage, {
          [classes.whiteFonts]: isFontWhite,
        })}
        style={{ backgroundColor }}
      >
        <h3 className={classes.mainTitle}>{name}</h3>
        <div className={classes.info}>
          <div className={classes.infoBlock}>
            <p className={classes.infoText}>
              {USER_LANDING.CERTIFICATE_BALANCE[language]}:
            </p>
            <div className={classes.infoTable}>{code}</div>
          </div>
          <div className={classes.infoBlock}>
            <p className={classes.infoText}>
              {USER_LANDING.MONEY_BALANCE[language]}:
            </p>
            <div className={classes.infoTable}>{priceWithDecimal(amount)}₴</div>
          </div>
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.button}
            htmlType="submit"
            type="primary"
            style={{
              backgroundColor: primaryColor,
              color: primaryFont.isFontWhite ? 'white' : 'black',
            }}
          >
            <Link to={ROUTES.USER_LANDING.replace(':id', identifier)}>
              {USER_LANDING.PAYMENT.BUY_ANOTHER[language]}
            </Link>
          </Button>
        </div>
      </div>
    );
  }
}
