import { connect } from 'react-redux';
import NewBalancePage from './NewBalancePage';
import { createStructuredSelector } from 'reselect';
import { getBalance } from '../../redux/certificate/actions';
import { balance, loading } from '../../redux/certificate/selectors';
import { campaignData } from '../../redux/landing/selectors';

const actions = {
  getBalance,
};

const selectors = createStructuredSelector({
  balance,
  loading,
  campaignData,
});

export default connect(selectors, actions)(NewBalancePage);
