import React, { Component } from 'react';

import SignupForm from '../../components/SignupForm';

export default class LoginPage extends Component {
  render() {
    const { match } = this.props;

    return <SignupForm referral={match.params.referral} />;
  }
}
