import React from 'react';
import classnames from 'classnames';

const Arrow = ({ className, style, onClick, additionalClass }) => (
  <div
    className={classnames(className, additionalClass)}
    style={{ ...style }}
    onClick={onClick}
  />
);

export default Arrow;
