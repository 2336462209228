import React from 'react';
import classes from './CookiesBanner.module.scss';
import { ROUTES } from '../../constants/routes';

class CookiesBanner extends React.Component {
  state = {
    closed: false,
  };
  handleBannerClose = () => {
    const { acceptCookie } = this.props;
    acceptCookie();
    this.setState({ closed: true });
  };

  render() {
    const { isLandingPage } = this.props;
    const { closed } = this.state;
    if (closed) {
      return null;
    }
    if (isLandingPage) return null;

    return (
      <div className={classes.banner}>
        <p className={classes.bannerText}>
          Використовуючи веб-сайт
          <a
            className={classes.link}
            href="https://upfront.com.ua"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://upfront.com.ua
          </a>
          , Ви погоджуєтеся із
          <a
            className={classes.link}
            href={ROUTES.TERMS}
            target="_blank"
            rel="noopener noreferrer"
          >
            Політикою конфіденційності Upfront
          </a>{' '}
          та на використання встановлених нами і третіми сторонами файлів
          cookie, а також, на застосування інших технологій відстеження дій на
          сайті для забезпечення коректної його роботи. Зокрема, ми можемо
          збирати сукупні дані про використання сайту з метою поліпшення
          взаємодії з Вами в Інтернеті і управління маркетинговими і сервісними
          зверненнями до Вас. Ви можете відключити деякі файли cookie, що
          використовуються нами, проте це може вплинути на можливість і
          коректність використання нашого сайту.
        </p>
        <button
          className={classes.bannerButton}
          onClick={this.handleBannerClose}
        >
          Ok
        </button>
      </div>
    );
  }
}
export default CookiesBanner;
