import React from 'react';
import styles from './NotFoundPage.module.scss';

export default function NotFoundPage() {
  return (
    <div className={styles.container}>
      <h3> Помилка 404</h3>
      <p>Сторінку не знайдено</p>
      <p>
        Неправильно набрано адресу або такої сторінки на сайті більше не існує
      </p>
    </div>
  );
}
