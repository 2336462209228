import { createSelector } from 'reselect';
import { prefix } from './actions';
import { certificateId, isPreviewPage } from '../router/selectors';
import { sortCertificates } from '../../helpers/sorting';
import { USER_LANDING } from '../../constants/texts';
import { getPreview } from '../../localStorage';
import { getFontColor } from '../../utils';

const root = state => state[prefix];

export const loading = createSelector(root, state => state.loading);

export const landingData = createSelector(root, state => state.landingData);

export const paymentIntentionData = createSelector(
  root,
  state => state.paymentIntentionData,
);

export const previewCampaign = () => {
  const data = getPreview();

  if (!data) {
    return {};
  }

  return data;
};

export const isPaymentBlocked = createSelector(
  landingData,
  data => data.isPaymentBlocked,
);

export const campaignData = createSelector(
  landingData,
  isPreviewPage,
  previewCampaign,
  (landingData, isPreview, preview, pathname) => {
    if (isPreview) {
      return preview;
    }

    const { campaign } = landingData;

    if (!campaign) {
      return {};
    }

    const {
      translation,
      backgroundColor,
      slug,
      id,
      primaryColor,
      language,
      instagram,
      facebook,
      telegram,
    } = campaign;
    const { fontColor, isFontWhite } = getFontColor(primaryColor);
    const identifier = slug || id;
    const translationList = Object.keys(translation || {}).reduce(
      (all, key) => {
        if (!translation[key]) {
          return all;
        }

        return [...all, { language: key, identifier: translation[key] }];
      },
      [
        {
          language,
          identifier,
        },
      ],
    );

    translationList.sort((a, b) =>
      a.language.toLowerCase().localeCompare(b.language.toLowerCase()),
    );

    return {
      ...campaign,
      fontColor,
      isFontWhite,
      identifier,
      backgroundColor: backgroundColor || '#F6F9FE',
      isSocialShown: Boolean(telegram || facebook || instagram),
      translation: translationList.length > 1 ? translationList : [],
    };
  },
);

export const certificateTiers = state => {
  const landing = landingData(state);
  const isPreview = isPreviewPage(state);
  const preview = previewCampaign();

  const certificateTiers = isPreview
    ? preview.certificateTiers
    : landing.certificateTiers;
  const orderedTiers = sortCertificates(
    certificateTiers.filter(tier => Number.isFinite(tier.order)),
    'order',
  );
  const unorderedTiers = sortCertificates(
    certificateTiers.filter(tier => !Number.isFinite(tier.order)),
    'value',
  );

  return [...orderedTiers, ...unorderedTiers];
};

export const certificateData = createSelector(
  certificateTiers,
  certificateId,
  (certificateTiers, certificateId) => {
    return certificateTiers.find(item => item.id === certificateId) || {};
  },
);

export const paidCertificate = createSelector(root, state => state.certificate);

export const certificatesCount = createSelector(
  paymentIntentionData,
  paymentIntentionData => paymentIntentionData.quantity,
);

export const purchaseMessage = createSelector(
  landingData,
  ({ campaign }) =>
    campaign.purchaseMessage || USER_LANDING.PAYMENT.HEADER[campaign.language],
);

export const hasCertificateImages = createSelector(
  certificateData,
  certificate => certificate.images && !!certificate.images.length,
);
